import { useEffect } from 'react';
import fotoDummy from "../src/images/misc/foto-nota.jpg";

export const useConstructor = (callBack = () => {}) => {
    useEffect(()=>{
        callBack();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
}

export function host() {
    //return "https://intranet.isaf.gob.mx";
    return (window.location.origin === "https://isaf.gob.mx"
        || window.location.origin === "https://www.isaf.gob.mx"
        || window.location.origin === 'https://app-website-prod-cus-finalstaging.azurewebsites.net'
    ) ? "https://intranet.isaf.gob.mx" : "https://localhost:44365";
}

export function hostSigas() {
    //return "https://sigas.isaf.gob.mx";
    return (window.location.origin === "https://isaf.gob.mx"
        || window.location.origin === "https://www.isaf.gob.mx"
        || window.location.origin === "https://app-website-prod-cus-finalstaging.azurewebsites.net")
        ? "https://sigas.isaf.gob.mx" : "https://localhost:44340";
}

export function visualizarPdf(id) {
    return window.open(`${host()}/Publico/Documento/${id}`, "_blank");
}



export function smallScreen() {
    if (window.screen.width < 500)
        return true;
    else
        return false;
}

export function tipoAuditoriaButtonClassBuilder(tipoAuditoria) {
    if (tipoAuditoria === "*********") return "btn-dummy";
    if (tipoAuditoria === 'Financiera') return "btn-financiera";
    if (tipoAuditoria === 'Presupuestal') return "btn-presupuestal";
    if (tipoAuditoria === 'de Desempeño') return "btn-desempeno";
    if (tipoAuditoria === 'Legal') return "btn-legal";
    if (tipoAuditoria === 'de la Información Financiera Trimestral') return "btn-ift";
    if (tipoAuditoria === 'Técnica a la Obra Pública') return "btn-obras";
    if (tipoAuditoria === 'a la Información Financiera Trimestral y Cuenta Pública') return "btn-ift";
    if (tipoAuditoria === 'Informe de Auditoría') return "btn-legal";
    if (tipoAuditoria === 'Solicitada') return "btn-presupuestal";
    if (tipoAuditoria === 'Especial') return "btn-legal";
    if (tipoAuditoria === 'Informe de Auditoría') return "btn-legal";
    if (tipoAuditoria === 'al Informe de Cuenta Pública') return "btn-ift";
    if (tipoAuditoria === 'Deuda Pública a Informe de Cuenta Pública') return "btn-ift";
    if (tipoAuditoria === 'Financiera - Recurso Federal') return "btn-financiera";
    if (tipoAuditoria === 'Deuda Pública') return "btn-ift";
    if (tipoAuditoria === 'Denuncia') return "btn-ift";

    if (tipoAuditoria !== null && tipoAuditoria !== undefined)
        console.error("No se encontró tipo de auditoria para: " + tipoAuditoria);
}

export function getEjerciciosDummy(){
    return [
        { "id": 18, "año": 2022 },
        { "id": 17, "año": 2021 },
        { "id": 16, "año": 2020 },
        { "id": 6, "año": 2019 },
        { "id": 3, "año": 2018 },
        { "id": 2, "año": 2017 },
        { "id": 1, "año": 2016 }];
}

export function getTipoDenunciasDummy(){
    return [
        { "id": 1, "tipo": "Denuncia" },
        { "id": 2, "tipo": "Seguimiento" },
        { "id": 3, "tipo": "Participacion" },
    ];
}

export function getTiposAuditoriaDummy() {
    return [

        { "id": 1, "nombre": "Legal" },
        { "id": 2, "nombre": "Financiera" },
        { "id": 3, "nombre": "Presupuestal" },
        { "id": 4, "nombre": "de Desempeño" },
        { "id": 5, "nombre": "Técnica a la Obra Pública" },
        { "id": 6, "nombre": "Forense" },
        { "id": 7, "nombre": "de la Información Financiera Trimestral" },
        { "id": 8, "nombre": "al Informe de Cuenta Pública" },
        { "id": 9, "nombre": "Integral" },
        { "id": 10, "nombre": "Especial" },
        { "id": 11, "nombre": "a la Información Financiera Trimestral y Cuenta Pública" },
        { "id": 12, "nombre": "Deuda Pública" },
        { "id": 13, "nombre": "Solicitada" },
        { "id": 14, "nombre": "Tiempo Real" },
        { "id": 15, "nombre": "Financiera - Recurso Federal" },
        { "id": 16, "nombre": "Denuncia" },
        { "id": 17, "nombre": "Desempeño - Programa Presupuestario" },
        { "id": 18, "nombre": "Deuda Pública a Informe de Cuenta Pública" }]

}

export function getAmbitoDummy() {
    return [
        { "id": 1, "nombre": "Estatal" },
        { "id": 2, "nombre": "Municipal" }];
}
export function getNotaDummy() {
    return( 
        {
            "id": 1,
            "titulo": "Sed ut perspiciatis unde omnis iste natus error sit voluptatem",
            "contenido": "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?",
            "foto": fotoDummy,
            "created": "lunes, 07 de febrero de 2022"

        }
        )
        
}

export function getInformesDummy(){
    return [
        {
          id: 1,
          tipoAuditoria: "*********",
            auditoria: "•••••••••••••••",
        },
        {
          id: 2,
            tipoAuditoria: "*********",
            auditoria: "•••••••••••••••",
        },
        {
          id: 3,
            tipoAuditoria: "*********",
            auditoria: "•••••••••••••••",
        },
        {
          id: 4,
            tipoAuditoria: "*********",
            auditoria: "•••••••••••••••",
        },
      
      ];
}



