import React, { useEffect, useState } from "react";
import { useConstructor, host } from '../Utils';
import axios from "axios";
const QRCode = require('@qrcode/react')


export function Carnet(props) {
  useConstructor(() => props.setTitle(
    ""
  ));
   const [empleado, setEmpleado] = useState({});
   var url = new URL(window.location.href);
   var ci = url.searchParams.get("ci");

    console.log("Empleados", empleado);

    useEffect(() => {
        axios.get(host() + '/Publico/Empleado/' + ci).then(x => {
            setEmpleado(x.data);
            console.log(x.data);
        }).catch(x => { console.log("ERROR: ", x) });
    }, [ci]);

  
    if (empleado) {
        return (
            <div className="carnet-wrapper">
                <p style={{ textAlign: "center", fontSize: "18px" }}>Este Instituto Superior de Auditoria y Fiscalización hace constar que el presente servidor público forma parte del mismo y a la fecha se encuentra en servicio activo</p>
                <div className="carnet">
                    <div className="carnet-image">
                        
                            <div className="carnet-imagen-cont">
                                <img alt="foto del empleado" className="carnet-pic" src={"https://intranet.isaf.gob.mx/Publico/FotoEmpleado/" + ci} />
                                <div className="carnet-imagen-pie">
                                    <div className="carnet-number">{empleado.NumeroISAF}</div>
                                </div>
                            </div>
                       
                    </div>
                    <div className="carnet-data">
                        
                            <div className="carnet-qrcode">
                            <QRCode size={128}
                                value={"https://isaf.gob.mx/carnet?ci=" + ci} />
                            </div>
                            <div className="carnet-label">Instituto Superior de Auditoría y Fiscalización</div>
                            <div className="carnet-nombre">{empleado.NombreCompleto}</div>
                            <div className="carnet-puesto">{empleado.Puesto}</div>
                            <div className="carnet-puesto">{empleado.DepartamentoName}</div>
                    </div>
                </div>
            </div>

        );
    }
    else {
        return (
            <div style={{ textAlign: "center", fontSize: "18px" }}>Este Instituto Superior de Auditoria y Fiscalización hace constar que:<p> <b>La persona buscada no se encuentra registrada como empleado activo de este Instituto</b></p></div>

        );
    }

  
}
