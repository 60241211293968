import React, { useState, useRef } from "react";
import { useConstructor } from "../../Utils";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { isMobile } from "react-device-detect";

export function Denuncias(props) {
  useConstructor(() => props.setTitle("Denuncias"));

  const fileInput = useRef(null);


  const [formDenuncia, setValuesDenuncia] = useState({
    Denunciante: "",
    Organizacion: "",
    Ambito: "Estatal",
    AreaDeInteres: "Accesibilidad",
    Direccion: "",
    Email: "",
    Telefono: "",
    Dependencia: "",
    Motivo: "",
    Tipo: "",
    Evidencia: "",
    ArchivoEvidencia: fileInput,
  });
  const [formConsulta, setValuesConsulta] = useState({
    Email: "",
    FolioInput: "",
    Folio: "",
    Fecha: "",
    Status: "",
    Motivo: "",
    Seguimiento: [],
  });

  const [tipoDenuncias] = useState([
    { Id: 1, Name: "Denuncia" },
    { Id: 2, Name: "Seguimiento" },
    // { Id: 3, Name: "Participacion" },
  ]);

  function fileNames() {
    const { current } = fileInput;
    console.log("current", current.files);
    if (current !== null) {
      if (current && current.files.length > 0) {
        let messages = [];
        for (let file of current.files) {
          messages = messages.concat(<p key={file.name}>{file.name}</p>);
        }
        return messages;
      }
    }
    return null;
  }

  const MySwal = withReactContent(Swal);

  const verifyRequired = (e) => {
    if (
      e.Denunciante !== "" &&
      e.Email !== "" &&
      e.Dependencia !== "" &&
      e.Motivo !== "" &&
      e.Evidencia !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getSource = () => {
    var source = "";
    if (document.location.href.includes("localhost")) {
      source = "https://localhost:44365";
    } else {
      source = "https://intranet.isaf.gob.mx";
    }
    return source;
  };

  function CleanUp() {
    setValuesDenuncia({
      Denunciante: "",
      Organizacion: "",
      Ambito: "Estatal",
      AreaDeInteres: "Accesibilidad",
      Direccion: "",
      Email: "",
      Telefono: "",
      Dependencia: "",
      Motivo: "",
      Tipo: "Denuncia",
      Evidencia: "",
      ArchivoEvidencia: fileInput,
    });

    document.getElementById("Form-Denuncia").reset();
  }

  const postDenuncia = (e) => {
    e.preventDefault();
    if (verifyRequired(formDenuncia)) {
      document.getElementById("SubmitButton").disabled = true;

      var formData = new FormData();
      var imagefile = document.querySelector("#Evidencia");
      formData.append("file", imagefile.files[0]);

      const denuncia = {
        Denunciante: formDenuncia.Denunciante,
        Ambito: "",
        Organizacion: "",
        AreaDeInteres: "",
        Direccion: "",
        Email: formDenuncia.Email,
        Telefono: formDenuncia.Telefono,
        Dependencia: formDenuncia.Dependencia,
        Motivo: formDenuncia.Motivo,
        ArchivoEvidencia: imagefile.files[0],
        Tipo: formDenuncia.Tipo,
        Evidencia: formDenuncia.Evidencia,
      };
      const json = JSON.stringify(denuncia);

      const data = new FormData();
      data.append("denuncia", json);

      data.append("file", imagefile.files[0]);

      console.log("data", data);
      axios({
        method: "post",
        url: getSource() + "/Publico/Denuncia",
        data: data,
      }).then((res) => {
        MySwal.fire(
          <p>
            {res.data.Tipo} registrada con éxito, El número de Folio de su{" "}
            {res.data.Tipo} es: {res.data.Folio}.
          </p>
        );
        CleanUp();

        document.getElementById("SubmitButton").disabled = false;
      });
    } else {
      MySwal.fire(
        <p>
          Por favor, llene los campos <i>requeridos.</i>
        </p>
      );
    }
  };
  const Consulta = (e) => {
    e.preventDefault();

    axios
      .post(getSource() + "/Publico/ConsultarDenuncia", {
        _Email: formConsulta.Email,
        _Folio: formConsulta.FolioInput,
      })
      .then((res) => {
        setValuesConsulta({
          ...formConsulta,
          Folio: res.data.Folio,
          Motivo: res.data.Motivo,
          Fecha: res.data.Fecha,
          Tipo: res.data.Tipo,
          Status: res.data.StatusDenuncia,
          Seguimiento: res.data.Seguimiento,
          Evidencia: res.data.Evidencia,
        });
      })
      .catch((e) => {
        setValuesConsulta({
          ...formConsulta,
          Folio: "",
        });

        Swal.fire("No existe Información de los datos recibidos.");
      });
  };

  const updateField = (e) => {
    setValuesDenuncia({
      ...formDenuncia,
      [e.target.name]: e.target.value,
    });
  };

  const updateConsulta = (e) => {
    setValuesConsulta({
      ...formConsulta,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="container">
      <h1>Formato de Denuncia</h1>

      <p>
        Con fundamento en el Artículo 18 Fracción XIII de la Ley de
        Fiscalización Superior para el Estado de Sonora, ponemos a su
        disposición el siguiente formato para dar seguimiento a denuncias.
      </p>
      <p>
        Es necesario que el escrito de la denuncia sea acompañada por los
        elementos de prueba que se relacionen directamente con los hechos
        denunciados, tal como lo marcan los Artículos
        <b
          title="Las denuncias que se presenten deberán estar fundadas con documentos
                        y evidencias suficientes mediante los cuales se presuma fehacientemente el manejo, aplicación o
                        custodia irregular de recursos públicos o de su desvío, en los supuestos establecidos en esta Ley.
                        El escrito de denuncia deberá contar, como mínimo, con los siguientes elementos:
                        I.- El ejercicio en que se presentan los presuntos hechos irregulares; y
                        II.- Descripción de los presuntos hechos irregulares.
                        Al escrito de denuncia deberán acompañarse los elementos de prueba que se relacionen
                        directamente con los hechos denunciados. El Instituto deberá proteger en todo momento la
                        identidad del denunciante. "
        >
          {" "}
          56
        </b>{" "}
        y
        <b
          title="Las denuncias deberán referirse a presuntos daños o perjuicios a la
                        Hacienda Pública Estatal o Municipal o al patrimonio de los entes públicos, en algunos de los
                        siguientes supuestos para su procedencia:
                        I.- Desvío de recursos hacia fines distintos a los autorizados;
                        II.- Irregularidades en la captación o en el manejo y utilización de los recursos públicos;
                        III.- Actos presuntamente irregulares en la contratación y ejecución de obras, contratación y
                        prestación de servicios públicos, adquisición de bienes, y otorgamiento de permisos, licencias y
                        concesiones entre otros;
                        IV.- La comisión recurrente de irregularidades en el ejercicio de los recursos públicos; y
                        V.- Inconsistencia en la información financiera o programática de cualquier entidad
                        fiscalizada que oculte o pueda originar daños o perjuicios a su patrimonio.
                        El Instituto informará al denunciante la resolución que tome sobre la procedencia de iniciar
                        la revisión correspondiente."
        >
          {" "}
          57
        </b>{" "}
        de la Ley de Fiscalización Superior para el Estado de Sonora.
      </p>

      <div
        className="flex-container"
        style={{
          justifyContent: "space-between",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <div
          style={{
            maxWidth: window.innerWidth - 50,
            overflow: "hidden",
            flexGrow: 2,
            padding: 20,
            backgroundColor: "#8323471b",
          }}
        >
          <h2 className="text-left">Nueva Denuncia</h2>
          <Form id="Form-Denuncia" className="mt-20" onSubmit={postDenuncia}>
            <FormGroup className="form-element">
              <Label>
                Seleccione: {" "}
                <i className="bi bi-asterisk text-primary"></i>
              </Label>
              <div className="radio-group">
                {tipoDenuncias.map((tipoDenuncia) => {
                  return (
                    <div key={tipoDenuncia.Id}>
                      <Input
                        type="checkbox"
                        id={`checkbox-items-${tipoDenuncia.Name}`}
                        name="Tipo"
                        value={tipoDenuncia.Name}
                        onChange={updateField}
                      />
                      &nbsp;
                      <Label
                        htmlFor={formDenuncia.Tipo}
                      >
                        {tipoDenuncia.Name}
                      </Label>
                    </div>
                  );
                })}
              </div>
            </FormGroup>
            <FormGroup className="mt-20">
              <Label>
                Nombre de quien emite la Denuncia{" "}
                <i className="bi bi-asterisk text-primary"></i>
              </Label>
              <Input
                className="textbox"
                value={formDenuncia.Denunciante}
                name="Denunciante"
                onChange={updateField}
              />
            </FormGroup>
            <FormGroup className="mt-20">
              <Label>
                Correo Electrónico{" "}
                <i className="bi bi-asterisk text-primary"></i>
              </Label>
              <Input
                className="textbox"
                value={formDenuncia.Email}
                name="Email"
                onChange={updateField}
              />
            </FormGroup>
            <FormGroup className="mt-20">
              <Label>Teléfono</Label>
              <Input
                className="textbox"
                value={formDenuncia.Telefono}
                name="Telefono"
                onChange={updateField}
              />
            </FormGroup>
            <FormGroup className="mt-20">
              <Label>
              Dependencia o servidor público {"a quien refiere su Denuncia"}{" "}
                <i className="bi bi-asterisk text-primary"></i>
              </Label>
              <Input
                className="textbox"
                value={formDenuncia.Dependencia}
                name="Dependencia"
                onChange={updateField}
              />
            </FormGroup>
            <FormGroup className="mt-20">
              <Label>
                Escriba su {"Denuncia"}{" "}
                <i className="bi bi-asterisk text-primary"></i>
              </Label>
              <Input
                rows="7"
                type="textarea"
                value={formDenuncia.Motivo}
                name="Motivo"
                onChange={updateField}
              />
            </FormGroup>

            <FormGroup className="mt-20">
              <span className="input-control text">
                <Label>
                  Agregar archivo de evidencia{" "}
                  <i className="bi bi-asterisk text-primary"></i>
                </Label>
                <br />
                <Input
                  id="Evidencia"
                  name="Evidencia"
                  type="file"
                  ref={fileInput}
                  onChange={updateField}
                />
                <Label htmlFor="Evidencia" className="custom-file-upload">
                  <span
                    tabIndex="0"
                    role="button"
                    aria-controls="filename"
                  ></span>
                </Label>
                <span className="file-name"> {fileNames}</span>
                <br />
              </span>
            </FormGroup>
            <div></div>
            <Button id="SubmitButton" className=" btn btn-primary mt-20">
              
              Enviar
            </Button>
          </Form>
        </div>

        {/*===================== CONSULTA ===================*/}

        <div
          style={{
            flexGrow: 1,
            padding: 20,
            backgroundColor: "#d8c2633b",
            marginLeft: isMobile ? 0 : 25,
            marginTop: isMobile ? 25 : 0,
          }}
        >
          <div>
            <h2>
              Consultar el estado <br /> de una Denuncia
            </h2>
          </div>
          <Form onSubmit={Consulta}>
            <FormGroup className="form-element center mt-20">
              <Label>
                Correo Eléctrónico{" "}
                <i className="bi bi-asterisk text-primary"></i>
              </Label>
              <Input
                className="textbox"
                value={formConsulta.Email}
                name="Email"
                onChange={updateConsulta}
              />
            </FormGroup>

            <FormGroup className="form-element center  mt-20">
              <Label>
                Folio <i className="bi bi-asterisk text-primary"></i>
              </Label>
              <Input
                className="textbox"
                value={formConsulta.FolioInput}
                name="FolioInput"
                onChange={updateConsulta}
              />
            </FormGroup>
            <FormGroup className="center mt-20">
              <Button className="btn btn-primary"> Consultar </Button>
            </FormGroup>
            <div
              className="result"
              style={{ display: formConsulta.Folio === "" ? "none" : "block" }}
            >
              <div className="">
                <div>
                  {" "}
                  Folio: <i>{formConsulta.Folio}</i>
                </div>
                <div>
                  {" "}
                  Fecha de alta: <i>{formConsulta.Fecha}</i>
                </div>
                <div>
                  Status: <i>{formConsulta.Status}</i>
                </div>
              </div>
              <div id="cont">
                <hr />
                <h3 className="title"> Denuncia </h3>
                <div style={{ overflowWrap: "break-word" }}>
                  {" "}
                  {formConsulta.Motivo}
                </div>
                <br />
                <br />
                <div
                  style={{
                    display:
                      formConsulta.Seguimiento.length === 0 ? "none" : "block",
                  }}
                >
                  <div className="title">Respuestas: </div>
                  <div>
                    <ul className="alist-group">
                      {formConsulta.Seguimiento.map((e) => {
                        return (
                          <li className="list-item" key={e.Id}>
                            <span className="title">{e.Created} - </span>{" "}
                            <span>{e.Respuesta}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
