import React, { useEffect, useState } from 'react';
import { useConstructor } from '../../Utils';
import logooci from "../../images/LogoOICISAF.png"
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Alert, TabPane, TabContent, Container, Nav, NavItem, NavLink, } from 'reactstrap';
import classnames from "classnames";

import Documentos from "../../components/Documentos";


export function OrganoDeControlInterno(props) {


    const [activeTab, setActiveTab] = useState("1");

    useConstructor(() => props.setTitle("¿Qué es el Órgano Interno de Control del ISAF?"));



    useEffect(() => {

    }, [])




    return (
        <div>

            <div className="flex-container">
                <div className="">
                    <img alt="logo oci" src={logooci} style={{ width: 350, marginBottom: 35, float: 'right' }} /> 
                    En atención a las nuevas disposiciones emanadas 
                    de los Sistemas Nacional y Estatal Anticorrupción,
                     el Congreso del Estado de Sonora aprobó el artículo 67 QUATER de la 
                     Constitución Local para crear los Órganos Internos de Control de los 
                     Organismos Constitucionalmente Autónomos.
                    <br /> <br />
                    Actualmente el Instituto Superior de Auditoría y Fiscalización 
                    cuenta con Órgano Interno de Control, con atribuciones de auditoría interna,
                     investigación y substanciación de actos u omisiones que pudieran 
                     constituir responsabilidades administrativas de los servidores públicos 
                     del Instituto.
                    <br /> <br />
                    Es así como la vigilancia, control y evaluación del desarrollo 
                    administrativo y financiero del Instituto, está a cargo de su Órgano Interno 
                    de Control dotado de autonomía técnica, de gestión y presupuestal en 
                    aras de garantizar los principios de independencia, transparencia, 
                    imparcialidad, integridad, rendición de cuentas, profesionalismo y 
                    objetividad en el desarrollo de nuestras funciones.
                    <br /> <br />
                    <b>¿Qué se pretende lograr al corto plazo?</b>
                    <br />
                    Otorgar valor al ISAF, a través de la Instauración de una 
                    cultura de la prevención al Interior de la organización a
                     fin de detectar y corregir actos u omisiones que pudieran 
                     poner en riesgo la gestión o bien, que pudieran constituir 
                     responsabilidades administrativas de los servidores 
                     públicos en el ejercicio de sus funciones y el desarrollo 
                     de los procesos.
                </div>

            </div>

            <hr />
            <div>
                <Container>
                    <div>
                        <Nav id="" className={'flex-between'} style={{ alignItems: "flex-end" }}>
                            <NavItem>
                                <NavLink style={{ width: 160 }}
                                    className={classnames({
                                        activeOCI: activeTab === "1",
                                        pointer: true,
                                    })}
                                    onClick={() => {
                                        setActiveTab("1");
                                    }}
                                >
                                    Atención a Quejas y Denuncias
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink style={{ width: 160 }}
                                    className={classnames({ activeOCI: activeTab === "2" })}
                                    onClick={() => {
                                        setActiveTab("2");
                                    }}
                                >
                                    Declaración Patrimonial
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink style={{ width: 160 }}
                                    className={classnames({
                                        activeOCI: activeTab === "3",
                                        pointer: true,
                                    })}
                                    onClick={() => {
                                        setActiveTab("3");
                                    }}
                                >
                                    Manuales
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink style={{ width: 160 }}
                                    className={classnames({
                                        activeOCI: activeTab === "4",
                                        pointer: true,
                                    })}
                                    onClick={() => {
                                        setActiveTab("4");
                                    }}
                                >
                                    Marco Normativo OCI-ISAF
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink style={{ width: 160 }}
                                    className={classnames({
                                        activeOCI: activeTab === "5",
                                        pointer: true,
                                    })}
                                    onClick={() => {
                                        setActiveTab("5");
                                    }}
                                >
                                    Programas y Acciones
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink style={{ width: 160 }}
                                    className={classnames({
                                        activeOCI: activeTab === "6",
                                        pointer: true,
                                    })}
                                    onClick={() => {
                                        setActiveTab("6");
                                    }}
                                >
                                    Sistema de C.I. Institucional
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink style={{ width: 160 }}
                                    className={classnames({
                                        activeOCI: activeTab === "7",
                                        pointer: true,
                                    })}
                                    onClick={() => {
                                        setActiveTab("7");
                                    }}
                                >
                                    Código de Ética
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent
                            id="tab-content"
                            activeTab={activeTab}
                            style={{ backgroundColor: "#eaeaea", padding: 15 }}
                        >
                            <TabPane tabId="1">
                                <div>

                                Con fundamento en los artículos 67 Quater fracción II de la Constitución Política del 
                                Estado de Sonora, 96, 97 ,98 de la Ley de Responsabilidades y Sanciones para el 
                                Estado de Sonora, 95 fracción II de la Ley de Fiscalización Superior para el 
                                Estado de Sonora, 42 fracción XI del Reglamento Interior del Instituto Superior de 
                                Auditoria y Fiscalización se les informa a los entes fiscalizados y ciudadanía 
                                que por este medio podrán presentar quejas y denuncias en contra de actuaciones 
                                indebidas por parte de servidores públicos del Instituto Superior de Auditoria y 
                                Fiscalización del Estado de Sonora, sugerencias de mejoras y reconocimiento por 
                                buen servicio o desempeño, las 24 horas del día, los 365 días del año. <br /><br />

                                    Descarga el formato de denuncia y envíalo a:
                                    <b> buzon.oci@isaf.gob.mx</b><br /><br />

                                    <button onClick={() => window.open('https://intranet.isaf.gob.mx/Publico/Documento/5409')} className={'btn btn-primary'}>Descargar Formato</button><br /><br />

                                    <h3>Otras opciones para presentar una denuncia ciudadana</h3>
                                    <b>Presencial o por escrito:</b> 
                                    En el Órgano de Control Interno del ISAF, Blvd. Paseo Rio Sonora Sur 189 Col. 
                                    Proyecto Rio Sonora Hermosillo, Sonora, México, C.P. 83270.
                                    <br />
                                    <b>Atención Telefónica:</b> (662) 2366504 al 08 ext. 185 y 164.
                                    <br />
                                    <b>Vía correspondencia:</b> Enviando escrito libre al Órgano Interno de Control del ISAF, 
                                    con domicilio en Blvd. Paseo Rio Sonora Sur 189 Col. Proyecto Rio Sonora Hermosillo, 
                                    Sonora, México, C.P. 83270


                                </div>
                            </TabPane>
                            <TabPane tabId="2">
                                <div>

                                    {/*<a href="http://declaraciones.isaf.gob.mx/"><img width={250} src="https://intranet.isaf.gob.mx/Publico/Documento/5465" alt="declaranet"/></a>*/}
                                    {/*<br/><br/>*/}
                                    <h3>Mayo, mes de actualización de tu Declaración Anual de Situación Patrimonial</h3>
                                    <p>Atención servidores públicos del Instituto Superior de Auditoría y Fiscalización, del 01 de mayo al 31 de mayo debes actualizar tu declaración patrimonial.</p>
                                    <p>Deberá actualizar la información de los movimientos que tuvo su patrimonio durante el periodo a declarar, el cual comprenderá del 01 de enero al 31 de diciembre del año inmediato anterior.</p>
                                    <div className="text-center"><b>DATOS PARA ACTUALIZAR SU DECLARACIÓN DE SITUACIÓN PATRIMONIAL</b></div>

                                    <div>
                                        <ol>
                                            <li>Ingresar al portal:<a href="http://declaraciones.isaf.gob.mx/"> http://declaraciones.isaf.gob.mx/</a></li>
                                            <li>Contar con contraseña para acceder al Sistema Declaranet Sonora.</li>
                                            <li>Si no cuentas con ella, comunícate al OIC ext. 164.</li>
                                            <li>Captura tu RFC registrado en el Sistema.</li>
                                            <li>Tener a la mano los datos sobre: Escrituras y facturas de bienes inmuebles, muebles, vehículos, comprobantes de ingresos, valores, gravámenes y adeudos.</li>
                                            <li>Comprobantes de ingresos, valores, gravámenes y adeudos.</li>
                                            <li>Si presentaste tu Declaración de Situación Patrimonial Inicial entre enero y abril del ejercicio fiscal vigente, <strong>NO ESTAS OBLIGADO A PRESENTAR LA DECLARACIÓN DE MODIFICACIÓN EN MAYO</strong>.</li>
                                            
                                        </ol>
                                    </div>

                                    <div className="container">
                                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                            
                                            <a href="https://intranet.isaf.gob.mx/Publico/Documento/5469">
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                <i className="x2 bi bi-filetype-pdf no-decor red"></i> <span> Instructivo para el llenado y presentación del formato de declaraciones de situación patrimonial y de intereses</span></div>
                                            </a>
                                            <a href="https://intranet.isaf.gob.mx/Publico/Documento/4728">
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                <i className="x2 bi bi-filetype-pdf no-decor red"></i> <span> Modificación de Declaración Patrimonial</span></div>
                                            </a>
                                            <a href="https://intranet.isaf.gob.mx/Publico/Documento/5464">
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                <i className="x2 bi bi-filetype-pdf no-decor red"></i> <span> Lineamientos para la Presentación de la Declaración de Situación Patrimonial durante el mes de mayo de 2024</span></div>
                                            </a>
                                            <a href="https://intranet.isaf.gob.mx/Publico/Documento/5467">
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                   <i className="x2 bi bi-filetype-pdf no-decor red"></i> <span> Tríptico informativo para la presentación de la Declaración Patrimonial</span></div>
                                            </a>
                                            
                                        </div>
                                    </div>

                                </div>
                            </TabPane>
                            <TabPane tabId="3">
                                <div className={'flex-evenly'}>

                                    {/* <a style={{ display: 'flex', alignItems: 'center' }} href="https://intranet.isaf.gob.mx/Publico/Documento/3921">
                                        <i className="x2 bi bi-filetype-pdf" style={{ textDecoration: 'none', color: 'red' }}></i> Manual de Organización
                                    </a>

                                    <a style={{ display: 'flex', alignItems: 'center' }} href="https://intranet.isaf.gob.mx/Publico/Documento/3922">
                                        <i className="x2 bi bi-filetype-pdf" style={{ textDecoration: 'none', color: 'red' }}></i>   Manual de Procedimientos
                                    </a> */}
                                    ⚠ En construcción ⚠
                                </div>
                            </TabPane>

                            <TabPane tabId="4">
                                <div>
                                    <div className="flex-container">
                                        <Documentos number="FA-0163"></Documentos>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tabId="5">
                                <div>
                                    <div className="flex-container">
                                        <Documentos number="FA-0240"></Documentos>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tabId="6">
                                <div>
                                    <div className={'flex-evenly'}>

                                        <a style={{ display: 'flex', alignItems: 'center', width: 400 }} href="https://intranet.isaf.gob.mx/Publico/Documento/4653">
                                            <i className="x2 bi bi-filetype-pdf" style={{ textDecoration: 'none', color: 'red' }}></i> Marco Integrado de Control Interno y Manual Administrativo
                                        </a>
                                        <a style={{ display: 'flex', alignItems: 'center', width: 400 }} href="https://intranet.isaf.gob.mx/Publico/Documento/4655">
                                            <i className="x2 bi bi-filetype-pdf" style={{ textDecoration: 'none', color: 'red' }}></i>   Díptico Control Interno
                                        </a>
                                        
                                        <a style={{ display: 'flex', alignItems: 'center', width: 400 }} href="https://intranet.isaf.gob.mx/Publico/Documento/4657">
                                            <i className="x2 bi bi-filetype-pdf" style={{ textDecoration: 'none', color: 'red' }}></i>   Acta de Instalación de Control Interno 2020
                                        </a>
                                        
                                        <a style={{ display: 'flex', alignItems: 'center', width: 400 }} href="https://intranet.isaf.gob.mx/Publico/Documento/4658">
                                            <i className="x2 bi bi-filetype-pdf" style={{ textDecoration: 'none', color: 'red' }}></i>   Informe Anual de Actividades 2020
                                        </a>
                                        <a style={{ display: 'flex', alignItems: 'center', width: 400 }} href="https://intranet.isaf.gob.mx/Publico/Documento/6001">
                                            <i className="x2 bi bi-filetype-pdf" style={{ textDecoration: 'none', color: 'red' }}></i>   Informe Anual de Actividades 2021
                                        </a>
                                        <a style={{ display: 'flex', alignItems: 'center', width: 400 }} href="https://intranet.isaf.gob.mx/Publico/Documento/6002">
                                            <i className="x2 bi bi-filetype-pdf" style={{ textDecoration: 'none', color: 'red' }}></i>   Informe Anual de Actividades 2022
                                        </a>
                                        <a style={{ display: 'flex', alignItems: 'center', width: 400 }} href="https://intranet.isaf.gob.mx/Publico/Documento/4656">
                                            <i className="x2 bi bi-filetype-pdf" style={{ textDecoration: 'none', color: 'red' }}></i>   Lineamientos para la Operación y Funcionamiento del Comité de Control Interno Institucional
                                        </a>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tabId="7">
                                <div className={"center"}>
                                    <iframe
                                        width={window.innerWidth<550?'300':'550'}
                                        height={window.innerWidth<550?'200':'315'}
                                        src="https://www.youtube.com/embed/RXePKGk4iP8"
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen>
                                    </iframe>
                                    <a style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        href="https://intranet.isaf.gob.mx/Publico/Documento/4654">
                                        <i className="x2 bi bi-filetype-pdf" style={{ textDecoration: 'none', color: 'red' }}></i>   Código de Ética
                                    </a>
                                </div>
                            </TabPane>
                        </TabContent>
                    </div>
                </Container>
            </div>
            <div className="flex-container" style={{ justifyContent: 'space-evenly' }}>
                <div style={{ maxWidth: 500, margin: '20px' }}>
                    <h2 className="center">Misión</h2>
                    <hr />
                    <p className="text-justify">
                        Promover la eficacia, eficiencia, integridad y mejora continua del ISAF para 
                        salvaguarda de recursos y combatir actos de corrupción.
                    </p>
                </div>
                <div style={{ maxWidth: 500, margin: '20px' }}>
                    <h2 className="center">Visión</h2>
                    <hr />
                    <p className="text-justify">
                    Ser un Órgano Interno de Control innovador con enfoque preventivo que agregue 
                    valor en el ejercicio de las funciones de los servidores públicos en el ISAF, 
                    así como en sus procesos.
                    </p>
                </div>

            </div>
            <div>
                <h2 className="center">Valores</h2>
                <hr />
                <div className="center">
                    • Integridad                    • Objetividad                    • Imparcialidad                    • Profesionalismo
                    • Legalidad                    • Honradez                    • Lealtad                    • Eficiencia
                    • Equidad                    • Excelencia                    • Transparencia                    • Economía
                    • Competencia por merito     • Disciplina                    • Rendición de cuentas             • Independencia
                    • Confidencialidad             • Responsabilidad               • Confidencialidad
                </div>
                <hr />
            </div>
            <div>
                <h2 className="center">Organigrama</h2>
                <ListGroup>
                    <ListGroupItem>
                        <ListGroupItemHeading>Titular del Órgano Interno de Control </ListGroupItemHeading>
                        <ListGroupItemText>
                        Instaurar una cultura de la prevención al interior del Instituto, 
                        a través de la verificación del control y evaluación del desarrollo administrativo y financiero; 
                        prevenir, corregir, investigar y calificar actos u omisiones que pudieran constituir 
                        responsabilidades administrativas de servidores públicos del Instituto y de particulares 
                        vinculados con faltas administrativas graves.
                        </ListGroupItemText>
                    </ListGroupItem>
                    <ListGroupItem>
                        <ListGroupItemHeading> Dirección de la Unidad de Administración</ListGroupItemHeading>
                        <ListGroupItemText>
                            Optimizar la administración de los recursos humanos, materiales y financieros asignados 
                            al Órgano Interno de Control del Instituto, con base en el presupuesto autorizado 
                            para la ejecución de los programas a su cargo.
                        </ListGroupItemText>
                    </ListGroupItem>
                    <ListGroupItem>
                        <ListGroupItemHeading>Dirección de Control y Auditoría </ListGroupItemHeading>
                        <ListGroupItemText>
                        Planificar, coordinar y supervisar los trabajos de auditorías y control interno
                         para evaluar y promover que la eficiencia, eficacia, economía y transparencia 
                         del uso de los recursos del Instituto.
                        </ListGroupItemText>
                    </ListGroupItem>                    
                    <ListGroupItem>
                        <ListGroupItemHeading>Dirección de Investigación </ListGroupItemHeading>
                        <ListGroupItemText>
                        Investigar los hechos denunciados constitutivos de presuntas faltas administrativas 
                        cometidas por servidores públicos del Instituto o particulares, por conductas sancionables 
                        en términos de la normatividad aplicable.
                        </ListGroupItemText>
                    </ListGroupItem>
                    <ListGroupItem>
                        <ListGroupItemHeading>Subdirección de la Unidad Interna de Control  </ListGroupItemHeading>
                        <ListGroupItemText>
                        Impulsar la salvaguarda de los recursos públicos, prevención de actos indebidos de los 
                        servidores públicos, así como el logro de objetivos y metas del Instituto a través de la 
                        promoción y fortalecimiento del Sistema de Control Interno y la administración de riesgos.
                        </ListGroupItemText>
                    </ListGroupItem>
                    <ListGroupItem>
                        <ListGroupItemHeading> Subdirección de Sustanciación</ListGroupItemHeading>
                        <ListGroupItemText>
                        Sustanciar y resolver los informes de presunta responsabilidad administrativa 
                        sobre los actos u omisiones de los servidores públicos o particulares relacionados, 
                        en los términos de la Ley de Responsabilidades y Sanciones para el Estado de Sonora.
                        </ListGroupItemText>
                    </ListGroupItem>
                </ListGroup>

            </div>

            <div>
                <hr />
                <h2 className="center">Contacto</h2>
                <Alert color="primary">
                Blvd. Paseo Rio Sonora Sur 189 <br />
                Col. Proyecto Rio Sonora <br />
                Hermosillo, Sonora, México, C.P. 83270 <br />
                Teléfonos: (662) 2366504  al 08 ext. 185 y 164.
                </Alert>

            </div>
        </div>
    );

}
