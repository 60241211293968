import React, { useEffect } from 'react';
import { useConstructor } from '../../Utils';
import ImgMision from "../../images/iconos/institucion/mision.png";
import ImgVision from "../../images/iconos/institucion/vision.png";
import organigrama from './../../images/tabs/organigrama.png'

export function AcercaDe  (props) { 
    useConstructor(() => props.setTitle("Acerca de ISAF"));

    

    useEffect(() => {
        
    }, [])

    

   
    return (
        <div>
            <div>
                <div className='d-flex' style={{flexWrap:'wrap'}}>
                <div className="bg-3 col-12 col-sm-6" style={{ minHeight: 550, backgroundPosition: "center" }} >
                </div>
                <div className='col-12 col-sm-6'>

                <div className="text-justify" style={{ margin: '40px', fontSize:"1.2rem" }}>
                    El ISAF es el órgano autónomo encargado de revisar y fiscalizar los estados financieros,
                    cuentas públicas estatal y municipales, de fiscalizar los ingresos y egresos, así como el manejo, custodia y
                    aplicación de los recursos públicos, de igual manera la deuda pública contraída con los particulares de los
                    poderes del Estado de Sonora, es decir, vigila el origen y aplicación de los recursos públicos en el Estado.
                </div>
                <div className="home-card-container">
                    <div className="home-card">
                        <div style={{ display: "flex" }}>
                            <div className={"home-card-icon"}>
                                <img alt='' src={ImgMision} width="50px" />
                            </div>
                            <div className="home-card-triangle"></div>
                            <div className={"home-card-title"}>
                                <b className="font-title">Misión</b>
                            </div>
                        </div>
                        <p className={'home-card-text'}>
                            Fiscalizar y evaluar el uso y destino de los recursos públicos
                            con el fin de promover instituciones sólidas, eficaces e inclusivas
                            para mejorar la vida de los ciudadanos.
                        </p>
                    </div>
                    <div className="home-card">
                        <div style={{ display: "flex" }}>
                            <div className={"home-card-icon"}>
                                <img alt='' src={ImgVision} width="50px" />
                            </div>
                            <div className="home-card-triangle"></div>
                            <div className={"home-card-title"}>
                                <b className="font-title">Visión</b>
                            </div>
                        </div>
                        <p className={'home-card-text'}>
                            Ser un instituto de fiscalización independiente y profesional
                            reconocido por contribuir a la gobernanza en el estado de Sonora.

                        </p>
                    </div>

                </div>
                </div>
                </div>
                <div>
                    <h2>Estructura Orgánica</h2>
                    <p>Establece la manera en que se encuentra organizado el ISAF, las unidades administrativas y jerarquías que integran la institución, por medio de las cuales se revisan y fiscalizan los estados financieros y las cuentas públicas estatal y municipales.</p>
                </div>
                <div className='d-flex' style={{justifyContent:'center',overflow:'auto'}}>
                <img src={organigrama} style={{height:'700px'}}  alt="Image Alt"/>
                </div>


            </div>

      </div>
    );
  
}
