import React from 'react'
import pg1 from "../../../images/politicas/pg101.jpg"
import pg2 from "../../../images/politicas/pg102.jpg"
import pg3 from "../../../images/politicas/pg103.jpg"
import pg4 from "../../../images/politicas/pg104.jpg"
import pg5 from "../../../images/politicas/pg105.jpg"
import pg6 from "../../../images/politicas/pg106.jpg"
// import pg7 from "../../../images/politicas/pg107.jpg"
// import pg8 from "../../../images/politicas/pg108.jpg"
// import pg9 from "../../../images/politicas/pg109.jpg"
// import pg10 from "../../../images/politicas/pg110.jpg"
// import pg11 from "../../../images/politicas/pg111.jpg"
// import pg12 from "../../../images/politicas/pg112.jpg"
// import pg13 from "../../../images/politicas/pg113.jpg"
// import pg14 from "../../../images/politicas/pg114.jpg"
import { LazyLoadImage } from "react-lazy-load-image-component";
import { host } from '../../../Utils'

export const Politicas = () => {
  return (
    <>
     <div className='d-flex' style={{justifyContent:'end'}}>
      <a className='p-2 mb-3' style={{borderRadius:'10px', color:'white',backgroundColor:'#832347'}} href={host() + "/Publico/Documento/6407"}>Descargar Políticas para la Planeación Estratégica y Participativa</a>
    </div>
    <div>
        <LazyLoadImage src={pg1} style={{maxWidth:'100%'}} alt="Image Alt" />
        <LazyLoadImage src={pg2} style={{maxWidth:'100%'}} alt="Image Alt" />
        <LazyLoadImage src={pg3} style={{maxWidth:'100%'}} alt="Image Alt" />
        <LazyLoadImage src={pg4} style={{maxWidth:'100%'}} alt="Image Alt" />
        <LazyLoadImage src={pg5} style={{maxWidth:'100%'}} alt="Image Alt" />
        <LazyLoadImage src={pg6} style={{maxWidth:'100%'}} alt="Image Alt" />
        {/* <LazyLoadImage src={pg7} style={{maxWidth:'100%'}} alt="Image Alt" />
        <LazyLoadImage src={pg8} style={{maxWidth:'100%'}} alt="Image Alt" />
        <LazyLoadImage src={pg9} style={{maxWidth:'100%'}} alt="Image Alt" />
        <LazyLoadImage src={pg10} style={{maxWidth:'100%'}} alt="Image Alt" />
        <LazyLoadImage src={pg11} style={{maxWidth:'100%'}} alt="Image Alt" />
        <LazyLoadImage src={pg12} style={{maxWidth:'100%'}} alt="Image Alt" />
        <LazyLoadImage src={pg13} style={{maxWidth:'100%'}} alt="Image Alt" />
        <LazyLoadImage src={pg14} style={{maxWidth:'100%'}} alt="Image Alt" /> */}
    </div>
    </>
  )
}
