import React, {  useState, useEffect } from 'react';
import axios from 'axios';
import { host } from '../Utils';
import OwlCarousel from "react-owl-carousel";
import logo_Convocatoria from "../images/convocatoria/logo_4ta_convocatoria.png";
import botonConvocatoria from "../images/convocatoria/boton_convocatoria2023.png"
import fondoCarrusel1 from '../images/tabs/FondoCarrusel1.jpg'
import fondoCarrusel2 from '../images/tabs/FondoCarrusel2.jpg'
import fondoCarrusel3 from '../images/tabs/FondoCarrusel3.jpg'

export default function NotasHeader() {
    
    const [notas, setNotas] = useState([]);

    useEffect(()=>{        
        axios.get(host() + "/api/Notas/Destacadas/5")
            .then(async (res) => {
                    const mapeo = res.data;
                    if (mapeo.length > 0) {
                        const prom = await Promise.all(
                            mapeo.map(async (n) => {                        
                                //getData(n.imagen)
                               await axios.get(host() + '/api/File/Download/' + n.imagen)
                                    .then((x) => {
                                n.imagen = x.data.blockBlob.uri + x.data.sharedPolicy;                              
                            }).catch(x => console.log(x))                        
                                 n.titulo = n.titulo.toUpperCase();
                                n.posicionImagen = n.posicionImagen + '%'
                         return await n;
                        })
                       )

                    await fillNotas(prom)
                        }
                    
            }).catch(x => console.log(x))
        
    }, []);


    
    

    const fillNotas = (_notas) => {             
        setNotas(_notas);
    }
    if (notas.length > 0)
        return (
            <> 
            <OwlCarousel
                className="owl-theme"
                loop
                dots={false}
                nav={true}
                autoplay={true}
                autoplayHoverPause={true}
                lazyLoad={true}
                onChange={(x) => {  }}
              
                responsiveClass={true}
                responsive={{
                    0: {
                        items: 1,
                        nav: true,
                        loop: true,
                    },
                    768: {
                        items: 1,
                        nav: true,
                        loop: true,
                    },
                    1200: {
                        items: 1,
                        nav: true,
                        loop: true,
                    },
                    1400: {
                        items: 1,
                        nav: true,
                        loop: true,
                    },
                }}
            >

                {notas.map((nota) =>
                    <div className='d-flex flex-wrap fondoCarusel' style={{}}>
                    <div key={nota.id} className='col-12 col-sm-6'>
                        <div style={{ backgroundPositionY: nota.posicionImagen,marginLeft:'60px',marginRight:'60px', marginBottom:'40px',marginTop:'40px' ,backgroundImage: 'url("' + nota.imagen + '")' }}
                            className="home-carousel pointer shadowNotas"
                            onClick={() => { window.open('/difusion/nota?nota=' + nota.id, '_self') }}                        >
                            <div>
                                <div className='home-carousel-content'>
                                    {/* <div className="home-carousel-title">
                                        {nota.titulo}
                                    </div> */}
                                    
                                   
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div  className='d-flex col-12 col-sm-6 ' style={{justifyContent:'center', color:'white',alignItems:'center', flexDirection:'column', padding:'4rem'}}>
                        <h2 style={{textAlign:'end', fontWeight:'bolder'}}>{nota.titulo}</h2>

                                            <a href={"/difusion/nota?nota=" + nota.id} className="btn btn-link no-decor"
                                                style={{ color: "white", cursor: 'pointer' }}>
                                                SABER MÁS  <i style={{ marginLeft:10 }} className="bi bi-arrow-right-circle pull-right"></i>
                                            </a>
                    </div>
                    </div>

                )}

                </OwlCarousel>
                {/*
                <a className="">
                    <img src={"../images/convocatoria/boton_convocatoria2023.png"} alt="Logo 4ta convocatoria" width={'20px'} />
                    4ta Participacion Ciudadana
                </a>
                    */}
            </>
        );
    else
        return (<div></div>);

}
