import React from 'react'
import './QueEsLaParticipacionCiudadana.css'
import { LazyLoadImage } from "react-lazy-load-image-component";
import pg1 from "../../../images/orientacionCiudadana/QueEsParticipacionCiudadana/ciudadana 2.png"
import pg2 from "../../../images/orientacionCiudadana/QueEsParticipacionCiudadana/ciudadana 1.png"
import pg3 from "../../../images/orientacionCiudadana/QueEsParticipacionCiudadana/ciudadana 3.png"

export const QueEsLaParticipacionCiudadana = () => {
  return (
    <>
    <div className='fondo1'>
    <div className="text-container" >
        <h1  style={{fontWeight:'bolder'}}>¿QUÉ ES LA PARTICIPACIÓN CIUDADANA?</h1>
    </div>
    </div>
    <div className='p-3 texto' style={{marginTop:'-300px',textAlign:'start'}}>
    <p>Uno de los elementos fundamentales para el buen desempeño de las Entidades Fiscalizadoras Superiores (EFS), es promover la participación de la ciudadanía en las actividades de fiscalización por medio de mecanismos que permitan ejercer el derecho que tiene la sociedad de intervenir en la toma de decisiones del sector público.</p>
    <div className=''>
    <h5>La <strong>participación ciudadana</strong> en el contexto de la administración pública se define como:</h5>
    <div style={{justifyContent:'center', display:'flex'}}>
    <LazyLoadImage src={pg2} style={{width:'50%'}} alt="Image Alt" />
    </div>
    <div className='d-flex ' style={{justifyContent:'center'}}>
      <div className='card p-3' style={{width:'60%',textAlign:'center', backgroundColor:'#a8a8a7', color:'white'}}>
    <p >La interacción e intervención activa de las y los ciudadanos, así como de las organizaciones sociales, con las entidades gubernamentales y autónomas. La participación ciudadana consiste en un derecho tanto individual, como colectivo de interactuar e intervenir en la toma de decisiones y las actividades que realizan los servidores públicos.</p>
      </div>
    </div>
    
    <h5 style={{marginTop:'20px'}}>Importancia de la <strong>participación ciudadana</strong> con los organismos públicos</h5>
    <p>Ésta radica en que, cuanto más alto sea el nivel de participación, más democrático es un sistema. Por consiguiente, funciona como un complemento que permite estrechar la confianza entre la ciudadanía y las instituciones públicas que la representan.</p>
    <div className='d-flex' style={{justifyContent:'space-evenly',flexWrap:'wrap'}}>
    <LazyLoadImage src={pg1} style={{maxWidth:'15%',height:'20%'}} alt="Image Alt" />
    <div className='centrado col-12 col-sm-8'>
        <h5>Beneficios de la <strong>participación ciudadana</strong> en la fiscalización</h5>
        <p>Fortalece la credibilidad en las instituciones gubernamentales, incentiva la responsabilidad y el compromiso de los servidores públicos y genera un entorno con una menor tendencia a la corrupción. </p>
    </div>
    </div>
    </div>
    <div className=' p-3 mt-3'>
      <div className='d-flex' style={{flexWrap:'wrap'}}>
        <div>
    <h5><strong>Participación ciudadana</strong> en ISAF</h5>
    <p>El Instituto promueve la colaboración de la población sonorense en los procesos fiscalización, a través de las convocatorias anuales de <strong>participación ciudadana</strong> para la elaboración del Programa Anual de Auditorías. Éstas permiten que las instituciones públicas, las cámaras empresariales, los sindicatos, las organizaciones de la sociedad civil, las universidades, al igual que, las y los ciudadanos puedan realizar solicitudes, propuestas o peticiones en temas que se consideren de interés público y de trascendencia para el ámbito estatal y municipal. </p>
        </div>
        <div>
        <LazyLoadImage src={pg3} style={{width:'100%'}} alt="Image Alt" />
        </div>
      </div>
    </div>
    </div>
    </>
  )
}
