import React, { useEffect } from 'react';
import { useConstructor } from '../../Utils';
import imgIntegral from '../../images/misc/integral-23-24.png'


export function AuditoriaIntegral(props) {
    useConstructor(() => props.setTitle("Auditoría integral"));



    useEffect(() => {

    }, [])




    return (
        <div>
            
            <div style={{ textAlign: 'center' }}>
                <img alt='img' style={{ width: '100%',  maxHeight: '100%', display: 'block' }} src={imgIntegral}></img>
            </div>
            <div><br/>
            <h3>Las auditorías integrales podrán ser:</h3>
                <b>- De gabinete</b>, en la que se señala que las auditorías no excederán los 90 días hábiles para su aplicación.<br/>
                <b>- En el lugar de la obra pública o en el domicilio del sujeto fiscalizado</b>, para el cual se contará con 100 días hábiles.<br/><br/>
                A continuación, se definen cada uno de los tipos de auditoría, dado que la auditoría integral los agrupa, según corresponda.<br/><br/>
            </div>
            
            
            <h3>
                 <div style={{ backgroundColor: "#832347", color:"white", paddingLeft:30, borderRadius:30, height:38 }}> Legal </div>
            </h3>
            <p>
                Determinar el grado de cumplimiento del marco jurídico, por parte del ente, inherente a su actividad, y en qué
                medida la referida reglamentación permite que los programas y proyectos de éste se cumplan de manera
                económica, eficaz y eficiente.
            </p>


            <h3 style={{ marginTop: '30px' }}>
                 <div style={{ backgroundColor: "#ccb656", color:"white", paddingLeft:30, borderRadius:30, height:38 }}> Financiera </div>
            </h3>
            <p>
                Obtener la seguridad sobre el cumplimiento de la cuenta pública y que los estados financieros en su conjunto
                se encuentren libres de errores materiales. Al igual que, emitir un informe de auditoría que incluya una opinión
                basada en la evidencia suficiente y competente, según lo obtenido.
            </p>
            <h3 style={{ marginTop: '30px' }}>
            <div style={{ backgroundColor: "#097275", color:"white", paddingLeft:30, borderRadius:30, height:38 }}> Presupuestal </div>
           
            </h3>
            <p>
                Verificar los documentos mínimos indispensables para la implementación del Modelo de Presupuesto Basado
                en Resultados y el Sistema de Evaluación del Desempeño (PbR-SED) mediante la metodología y marco lógico
                aplicable, los criterios mínimos que deberán atenderse en cada una de las siete etapas del ciclo presupuestario.
                Asimismo, que sus programas sectoriales y presupuestarios se encuentren alineados con el contenido
                en el Plan Estatal de Desarrollo (PED), los cuales deberán estar evaluados por los indicadores de resultados
                plasmados en su Matriz de lndicadores para Resultados (MlR); las adecuaciones liquidas, adecuaciones compensadas
                y los calendarios para las ministraciones de los recursos asignados, que se apeguen a lo establecido
                en el Decreto del Presupuestos de Egresos.
            </p>
            <h3 style={{ marginTop: '30px' }}>
            <div style={{ backgroundColor: "#c96c1c", color:"white", paddingLeft:30, borderRadius:30, height:38 }}> Información Financiera Trimestral y de Cuenta Pública  </div>
             </h3>
            <p>
                Revisar y controlar la información presentada en los Informes Trimestrales y de Cuenta Pública del ejercicio,
                además del cumplimiento legal de la presentación de la información contable, presupuestal, programática y
                anexos, con la finalidad de evaluar la calidad de la información financiera de conformidad a lo establecido en la
                Ley General de Contabilidad Gubernamental.
            </p>
            <h3 style={{ marginTop: '30px' }}>
            <div style={{ backgroundColor: "#7c5c15", color:"white", paddingLeft:30, borderRadius:30, height:38 }}>  Deuda Pública </div>
            </h3>
            <p>
                Evaluar y controlar la información financiera, contable y presupuestal de deuda pública, presentada en los Informes
                Trimestrales y de Cuenta Pública del ejercicio correspondiente y cualquier otra información solicitada
                de manera adicional sobre deuda pública, con el objeto de evaluar su calidad, el cumplimiento a la Ley General
                de Contabilidad Gubernamental y Nacional de Armonización Contable, así como la Ley de Disciplina Financiera
                de las Entidades Federativas y los Municipios.
            </p>
            <h3 style={{ marginTop: '30px' }}>
            <div style={{ backgroundColor: "#a8a8a7", color:"white", paddingLeft:30, borderRadius:30, height:38 }}> De Desempeño </div>
            </h3>
            <p>
                Contribuir a mejorar la economía, eficiencia y eficacia del sector público. También tiene por objetivo contribuir
                a la buena gobernanza, a la rendición de cuentas y a la transparencia. La auditoría de desempeño busca aportar
                nueva información, análisis o perspectivas, además de recomendaciones de mejora, cuando esto último
                aplique.
            </p>
            <h3 style={{ marginTop: '30px' }}>
            <div style={{ backgroundColor: "#097275", color:"white", paddingLeft:30, borderRadius:30, height:38 }}>Técnica a la Obra Pública</div>
             </h3>
            <p>
                Constatar que las obras públicas ejecutadas para o por los entes públicos, fueron presupuestalmente aprobadas
                y que el uso de los recursos fue el apropiado; que existieron los estudios y proyectos respectivos y que
                éstos fueron autorizados; que la contratación se ajustó a la normatividad técnica y jurídica aplicable; que los
                costos fueron acordes con los volúmenes de obra ejecutada, con el tipo y calidad de materiales utilizados y
                con los precios unitarios y extraordinarios autorizados. Por otra parte, que la oportunidad en la ejecución y
                entrega de las obras y el suministro de los equipos se realizó de acuerdo con lo previsto. Cabe señalar que, en
                este tipo de auditorías se efectúan inspecciones físicas y pruebas técnicas.
            </p>
        </div>
    );

}
