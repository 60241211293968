import React, { Component } from "react";
//import axios from 'axios';
import "./Informes.css";
import { hostSigas } from "../../Utils";

class Informes extends Component {
  constructor(props) {
    super(props);
    this.state = { informes: [] };
  }
  informeSelected(informe) {
    this.setState({
      informes: this.props.informes.map((x) => {
        let result = x;
        if (x.id === informe.id) x.selected = true;
        else x.selected = false;
        return result;
      }),
    });
      console.log(informe)
    let url = hostSigas() + `/api/File/Informe/${informe.id}`;
    window.open(url, "_blank");
  }
  informeSelectedWord(informe) {
    this.setState({
      informes: this.props.informes.map((x) => {
        let result = x;
        if (x.id === informe.id) x.selected = true;
        else x.selected = false;
        return result;
      }),
    });

    let url = hostSigas() + `/api/File/InformeWord/${informe.id}`;
    window.open(url, "_blank");
  }

  deuda(codigo) {
    if (codigo) {
      if (codigo.includes("DP")) {
        return "(Deuda Pública)";
      } else {
        return "";
      }
    }
  }
  render() {
    let result = (
      <div id="informes-container">
        <label id="informes-label">Informes {this.props.enteName}</label>
        <div
          className={this.props.message.length > 0 ? "alert alert-danger" : ""}
        >
          {this.props.message}
        </div>
        <ul className="list-group">
          {this.props.informes.map((informe) => (
            <li
              key={informe.id}
              className={
                "list-group-item informe-item" +
                (informe.selected ? " active" : "")
              }
              style={{ padding: "0 0" }}
            >
              <div
                style={{
                  display: "flex",
                  alignContent: "center",
                  height: "100%"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "space-between",
                    flexGrow: "4",
                  }}
                  onClick={this.informeSelected.bind(this, informe)}
                >
                  <div style={{ padding: "10px 15px" }}>
                              {" "}
                              Informe {informe.tipoInformeName} {informe.tipoInformeName==="Ciudadano"? informe.tipoAuditoriaCiudadano : informe.tipoAuditoria}{" "}
                    {this.deuda(informe.auditoria)} {informe.nombre}
                  </div>
                  <span style={{ padding: "10px 15px" }} className="pull-right">
                    <i
                      className="bi bi-link-45deg"
                      style={{ fontSize: "1.2em" }}
                    ></i>{" "}
                  </span>
                </div>
                <span
                  style={{
                    flexGrow: "1",
                    display: informe.informeTestado && informe.ejercicioId<18 ? "block" : "none",
                    padding: "",
                    textAlign: "center",
                    borderLeft: "solid 1px #ddd",
                    marginTop: "8px"
                  }}
                  onClick={this.informeSelectedWord.bind(this, informe)}
                  className="pull-right"
                >
                  <i
                    className="bi bi-file-earmark-word-fill"
                    style={{ fontSize: "1.2em" }}
                  ></i>{" "}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );

    return result;
  }
}

export default Informes;
