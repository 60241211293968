import React from 'react';
import { NavMenu } from './NavMenu';
import { MainHeader } from './MainHeader';
import { Footer } from './Footer';


export function Layout(props) {
const smallScreen =() =>{
   return window.screen.width < 600;
}

    const isHome = () => {
        if (props.title === 'home')
            return true;
        else
            return false;
    }

    const isMapas = () => {
        if (window.location.href.indexOf("entrada")> -1)
            return true;
        else
            return false;
    }
    return (
        <div>
            <MainHeader />
            <div style={{ margin: 'auto' }}>
                <NavMenu title={props.title} />

                <div style={{
                    maxWidth: isHome() || isMapas() ? '100%' : 1500,
                    margin: 'auto',
                    paddingBottom: 40,
                    padding: !isHome() ? !smallScreen()? '0px 30px 40px': '0px 10px 10px' : '0 0 40px',
                    backgroundColor: "#fafafa"
                }}>
                    {!isHome() ? <h1 className="font-serif center">{props.title}</h1> : ""}
                
                    {props.children}
                </div>
            </div>         

           
          
          <Footer title="" />
      </div>
  );
}
