import React from 'react';
import pg1 from "../../../images/orientacionCiudadana/Manual/pg101.jpg"
import pg2 from "../../../images/orientacionCiudadana/Manual/pg102.jpg"
import pg3 from "../../../images/orientacionCiudadana/Manual/pg103.jpg"
import pg4 from "../../../images/orientacionCiudadana/Manual/pg104.jpg"
import pg5 from "../../../images/orientacionCiudadana/Manual/pg105.jpg"
import pg6 from "../../../images/orientacionCiudadana/Manual/pg106.jpg"
import pg7 from "../../../images/orientacionCiudadana/Manual/pg107.jpg"
import pg8 from "../../../images/orientacionCiudadana/Manual/pg108.jpg"
import pg9 from "../../../images/orientacionCiudadana/Manual/pg109.jpg"
import pg10 from "../../../images/orientacionCiudadana/Manual/pg110.jpg"
import pg11 from "../../../images/orientacionCiudadana/Manual/pg111.jpg"
import pg12 from "../../../images/orientacionCiudadana/Manual/pg112.jpg"
import pg13 from "../../../images/orientacionCiudadana/Manual/pg113.jpg"
import pg14 from "../../../images/orientacionCiudadana/Manual/pg114.jpg"
import pg15 from "../../../images/orientacionCiudadana/Manual/pg115.jpg"
import pg16 from "../../../images/orientacionCiudadana/Manual/pg116.jpg"
import pg17 from "../../../images/orientacionCiudadana/Manual/pg117.jpg"
import pg18 from "../../../images/orientacionCiudadana/Manual/pg118.jpg"
import pg19 from "../../../images/orientacionCiudadana/Manual/pg119.jpg"
import pg20 from "../../../images/orientacionCiudadana/Manual/pg120.jpg"
import pg21 from "../../../images/orientacionCiudadana/Manual/pg121.jpg"
// import pg22 from "../../../images/orientacionCiudadana/Manual/pg122.jpg"
// import pg23 from "../../../images/orientacionCiudadana/Manual/pg123.jpg"
// import pg24 from "../../../images/orientacionCiudadana/Manual/pg124.jpg"
// import pg25 from "../../../images/orientacionCiudadana/Manual/pg125.jpg"
// import pg26 from "../../../images/orientacionCiudadana/Manual/pg126.jpg"
// import pg27 from "../../../images/orientacionCiudadana/Manual/pg127.jpg"
// import pg28 from "../../../images/orientacionCiudadana/Manual/pg128.jpg"
// import pg29 from "../../../images/orientacionCiudadana/Manual/pg129.jpg"
// import pg30 from "../../../images/orientacionCiudadana/Manual/pg130.jpg"
// import pg31 from "../../../images/orientacionCiudadana/Manual/pg131.jpg"
// import pg32 from "../../../images/orientacionCiudadana/Manual/pg132.jpg"
// import pg33 from "../../../images/orientacionCiudadana/Manual/pg133.jpg"
// import pg34 from "../../../images/orientacionCiudadana/Manual/pg134.jpg"
// import pg35 from "../../../images/orientacionCiudadana/Manual/pg135.jpg"
import { LazyLoadImage } from "react-lazy-load-image-component";
import { host } from '../../../Utils';
export const ManualDeOrientacionCiudadana = () => {
  
    return (
      <>
      {/* <div className='fondo5'>
    <div className="text-container" >
        <h1>MANUAL DE ORIENTACIÓN</h1>
    </div>
    </div> */}
    <div className='d-flex' style={{justifyContent:'end'}}>
                <a className='p-2 mb-3' style={{ borderRadius: '10px', color: 'white', backgroundColor: '#832347' }} href={host() + "/Publico/Documento/6408"}>Descargar Manual de Orientación Ciudadana</a>
    </div>
      <div >
    <LazyLoadImage src={pg1} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg2} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg3} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg4} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg5} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg6} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg7} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg8} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg9} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg10} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg11} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg12} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg13} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg14} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg15} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg16} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg17} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg18} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg19} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg20} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg21} style={{maxWidth:'100%'}} alt="Image Alt" />
    {/* <LazyLoadImage src={pg22} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg23} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg24} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg25} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg26} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg27} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg28} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg29} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg30} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg31} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg32} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg33} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg34} style={{maxWidth:'100%'}} alt="Image Alt" />
    <LazyLoadImage src={pg35} style={{maxWidth:'100%'}} alt="Image Alt" /> */}
      </div>
      </>
    );
}
