import React from 'react';
import ImgNotFound from "../images/not-found-500x500.png";
import { Link } from 'react-router-dom';
import './NotFound.css';


export function NotFound(props) {


    return (
        <div className="container">
            <div className="row">
                
                <div className="col-md-6 parent order-md-2">
                    <div className="error-template ">
                        <h1 className="stroked">Oops!</h1>
                        <h2 className="stroked">404 No Encontrado</h2>

                        <p className="error-details stroked">
                            Lo sentimos, ha ocurrido un error, la página solicitada no fue encontrada!
                        </p>
                        <div className="error-actions">
                            <Link className="btn btn-primary" to="/"> Ir al inicio </Link>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 order-md-1">
                    <img alt="No encontrado" style={{ maxWidth:'100%' }} src={ImgNotFound} title="Robot no encuentra" />
                </div>
            </div>
        </div>
    );

}
