import React, { useState, useEffect } from "react";
import { useConstructor, hostSigas, smallScreen } from '../../Utils';
import {  Collapse } from 'reactstrap';
import Acuerdos from "../../components/Acuerdos";
import axios from 'axios';
import { Nav,NavItem,NavLink,TabContent,TabPane } from 'reactstrap'
import { ListaAvisos } from "./ListaAvisos";

export function ListaAcuerdos(props) {

    const [Lista, setLista] = useState([]);
    const [Fecha, setFecha] = useState([]);
    const [FechaMostrar, setFechaMostrar] = useState([]);
    const [verAcuerdos, setVerAcuerdos] = useState(false);
    const [activeTab, setActiveTab] = useState('1');
    const [isChecked, setIsChecked] = useState(false);
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
          setActiveTab(tab);
          setIsChecked(false);
        }
      };
      
    useEffect(() => {
        ObtenerListas();
    }, []);
    const colorSet = (event) => {
        let item = event.target;
        if (item.tagName === "LI") {
            colorRemoveElsewere(item);
            item.className = "list-group-item list-group-item-info";
        }
    }

    const colorRemove = (event) => {
        let item = event.target;
        if (item.tagName === "LI") {
            item.className = "list-group-item";
            colorRemoveElsewere(item);
        } else {
            item = item.parentNode;
            item.className = "list-group-item";
            colorRemoveElsewere(item);
        }
    }

    const colorRemoveElsewere = (item) => {
        let childNodes = item.parentNode.childNodes;
        let childArray = [...childNodes];

        childArray.forEach(element => {
            if (element.tagName === "LI") {
                element.className = "list-group-item";
            }
        });
    }
    function ObtenerListas() {
        axios.get(
            hostSigas() + "/api/FechasAcuerdos/",

        ).then(res => {
            setLista(res.data)
        })
    }
    function VerAcuerdos(item) {
        setFecha(item.fecha);
        setFechaMostrar(item.fechaMostrar);
        setVerAcuerdos(true);
    }
    function VerLista() {
        setVerAcuerdos(false);
    }
    return (
        <div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === '1' ? 'active selected' : ''} onClick={() => toggleTab('1')}
            >
              Lista de Acuerdos
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '2' ? 'active selected' : ''} onClick={() => toggleTab('2')}
            >
              Notificaciones y Avisos
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent  activeTab={activeTab}>
          <TabPane tabId="1">
          <div>
            <h1 className="d-flex" style={{justifyContent:'center'}}>Lista de Acuerdos</h1>
        <p className="text-center text-bold title-extracto">
                Unidad de Substanciación
            </p>
            <div className="flex-container">
                
                {verAcuerdos == true ?
                    <div>
                        <Acuerdos fecha={Fecha} fechaMostrar={FechaMostrar}></Acuerdos>
                        <a style={{ cursor: 'pointer', textDecoration:'underline' }} onClick={() => VerLista()}> Regresar a la lista de fechas</a>
                    </div>
                    : <div className="documentos">
                        <ul className="list-group">

                            {Lista.map((item) => (

                                <li className="list-group-item hover"
                                    onMouseEnter={colorSet}
                                    onMouseLeave={colorRemove} style={{ cursor: 'pointer' }}><a onClick={() => VerAcuerdos(item)}>  {item.fechaMostrar} <i className="bi bi-box-arrow-right"></i></a></li>
                            ))}

                        </ul>
                    </div>}
        </div>
      </div>
          </TabPane>
          <TabPane tabId="2">
           <div className='mt-3'>
          <ListaAvisos/>
           </div>
          </TabPane>
        </TabContent>
      </div>
      
    );
  
}
