import React, { useEffect, useState } from "react";
import { useConstructor } from '../../Utils';
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import AcuerdoCovidPdf from "../../documentos/acuerdo-covid19.pdf";

export function AuditoriasCovid(props) {
  useConstructor(() => props.setTitle("Auditorías Covid"));

  const [numPages, setNumpages] = useState(null);
  const [pageNumber, setPagesNumber] = useState(1);
  const [innerSize, setInnerSize] = useState(0);

  useEffect(() => {
    let widthSize = window.innerWidth;
    console.log(widthSize);
    setInnerSize(widthSize);
  }, [setInnerSize]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumpages(numPages);
  }

  
    return (
      <div>
        <div className="flex-container justify-content-center">
          <h4 className="text-bold">
            Acciones emprendidas por el ISAF a partir de la Declaratoria de
            emergencia y contingencia sanitaria epidemiológica por COVID-19 en
            el estado de Sonora.!
          </h4>
          <div style={{ width: "100%" }}>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Auditoría en tiempo real a Servicios de Salud de Sonora
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <h3 className="text-danger">COVID-19</h3>
                    <strong>Solicitada por:</strong>
                    <p>
                      C. Gobernadora del Estado de Sonora, Lic. Claudia
                      Pavlovich Arellano (2 de abril de 2020) y el Secretario de
                      Salud, C.P. Enrique Claussen Iberri (26 de marzo de 2020).
                    </p>
                    <strong>Código de Auditoría: </strong>{" "}
                    <p>2020AE0113010001</p>
                    <br />
                    <strong>Sujeto fiscalizado: </strong>{" "}
                    <p>Servicios de Salud de Sonora</p>
                    <br />
                    <strong>Propósito: </strong>
                    <p>
                      Llevar a cabo la revisión por la adquisición de bienes,
                      insumos y equipamiento destinado para garantizar la
                      capacidad de atención médica a la población del estado,
                      con motivo de contingencia sanitaria-epidemiológica por el
                      Covid-19, para que se lleve a cabo de manera transparente
                      y apegado a la normatividad aplicable.
                    </p>
                    <br />
                    <strong>Tipo de Auditoría: </strong>
                    <p>Financiera y de cumplimiento.</p>
                    <br />
                    <strong>Objeto: </strong>
                    <p>
                      Evaluar que los recursos públicos se administren con
                      eficiencia, eficacia, economía, transparencia y honradez
                      para cumplir los objetivos y programas a los que están
                      destinados, en apego al artículo 150 de la Constitución
                      Política del Estado de Sonora. Evaluar los resultados de
                      la gestión financiera del ingreso y del egreso, atendiendo
                      lo dispuesto por la Ley General de Contabilidad
                      Gubernamental, los documentos del Consejo Nacional de
                      Armonización Contable (CONAC), Ley General de Disciplina
                      Financiera y demás disposiciones jurídicas aplicables.
                      Enfocado a los siguientes objetivos: Para el rubro de
                      ingresos: Verificar que los ingresos fueron obtenidos,
                      recaudados, captados y administrados por el sujeto
                      fiscalizado, de conformidad con la Ley de Ingresos; y que
                      se encuentren registrados y presentados correctamente, de
                      acuerdo con la normatividad establecida. Respecto al rubro
                      de Egresos: Comprobar que los recursos públicos se hayan
                      ejercido conforme a los programas aprobados y montos
                      autorizados, y con cargo a las partidas correspondientes;
                      que se hayan aplicado legal y eficientemente al logro de
                      los objetivos y metas; y que las adquisiciones se hayan
                      adjudicado atendiendo a su cuantía consideradas
                      individualmente, y en función del presupuesto total
                      autorizado.
                    </p>
                    <br />
                    <strong>Área responsable: </strong>
                    <p>
                      Auditoría Adjunta de Fiscalización al Gobierno del Estado.
                    </p>
                    <br />
                    <strong>
                      Fecha de notificación al sujeto fiscalizado:{" "}
                    </strong>
                    <p>27 de abril de 2020</p>
                    <br />
                    <table className="table table-striped table-bordered table-responsive">
                      <thead style={{background: "#4e152a", color: "white"}} >
                        <tr>
                          <th>Fecha</th>
                          <th>
                            Actividades Realizadas por el Personal del Isaf
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>27 de mayo al 30 de junio de 2020 </td>
                          <td>
                            Análisis de las actas de Comité de crédito emergente
                            Covid19, celebradas los días 14, 17, 22, 24, 28, 30
                            de abril, 5,11,15,19, 26, 29 de mayo, 2, 6, 9, 15,
                            18, 19, 22, 23, 25, 26, 29, 30 de junio, 1, 2 y 3 de
                            julio con la finalidad de conocer las reglas de
                            aplicación, así como el número de solicitudes
                            autorizadas para otorgarles el crédito a los
                            beneficiados.
                          </td>
                        </tr>
                        <tr>
                          <td>27 de abril a la fecha</td>
                          <td>
                            Revisión mediante prueba selectiva de 1746
                            expedientes que fueron aprobados para recibir el
                            apoyo, verificando que los mismos cumplan con los
                            requisitos señalados en sus lineamientos.
                          </td>
                        </tr>
                        <tr>
                          <td>27 de abril al 14 de agosto de 2020 </td>
                          <td>
                            Verificación del correcto registro en los sistemas
                            de contabilidad, así mismo que las trasferencias se
                            realicen a la cuenta destino señalada por el
                            beneficiario del crédito.
                          </td>
                        </tr>
                        <tr>
                          <td>27 de abril al 14 de agosto de 2020 </td>
                          <td>
                            Se han realizado 105 llamadas telefónicas a los
                            beneficiarios del PROGRAMA DE CREDITO EMERGENTE
                            EFECTO COVID-19 para corroborar la recepción de los
                            créditos otorgados.
                          </td>
                        </tr>
                        <tr>
                          <td>11 de noviembre de 2020 </td>
                          <td>
                            Se notifican observaciones de precierre mediante
                            acta circunstanciada deactuaciones y precierre de
                            auditoría
                          </td>
                        </tr>
                        <tr>
                          <td>6 de enero de 2021 </td>
                          <td>
                            Se notifica pronunciamiento mediante el cual se
                            informan las observaciones de precierre solventadas
                            y no solventadas, de estas últimas los asuntos
                            pendientes de atenter
                          </td>
                        </tr>
                        <tr>
                          <td>11 de enero de 2021 </td>
                          <td>
                            Se informan los resultados finales de la revisión,
                            mediante acta de cierre de auditoría
                          </td>
                        </tr>
                        <tr>
                          <td>11 de febrero de 2021 </td>
                          <td>
                            Se notifica formalmente el informe individual que
                            muestra el resultado de las observaciones derivadas
                            de la revisión.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="btn-group">
                      <button className="btn btn-large btn-secondary">
                        Denuncias
                      </button>
                      <button className="btn btn-large btn-secondary">
                        Comentarios
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    Auditoría en tiempo real a la Secretaría de Desarrollo
                    Social
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <h3 className="text-danger">COVID-19</h3>
                    <strong>Solicitada por:</strong>
                    <p>
                      C. Gobernadora del Estado de Sonora, Lic. Claudia
                      Pavlovich Arellano (2 de abril de 2020).
                    </p>
                    <strong>Código de Auditoría: </strong>{" "}
                    <p>2020AE0113010002</p>
                    <br />
                    <strong>Sujeto fiscalizado: </strong>{" "}
                    <p>Secretaría de Desarrollo Social del estado de Sonora.</p>
                    <br />
                    <strong>Propósito: </strong>
                    <p>
                      Llevar a cabo la revisión por la adquisición de bienes,
                      insumos y equipamiento destinado para garantizar la
                      capacidad de atención médica a la población del estado,
                      con motivo de contingencia sanitaria-epidemiológica por el
                      Covid-19, para que se lleve a cabo de manera transparente
                      y apegado a la normatividad aplicable.
                    </p>
                    <br />
                    <strong>Tipo de Auditoría: </strong>
                    <p>Financiera y de cumplimiento.</p>
                    <br />
                    <strong>Objeto: </strong>
                    <p>
                      Evaluar que los recursos públicos se administren con
                      eficiencia, eficacia, economía, transparencia y honradez
                      para cumplir los objetivos y programas a los que están
                      destinados, en apego al artículo 150 de la Constitución
                      Política del Estado de Sonora. Evaluar los resultados de
                      la gestión financiera del ingreso y del egreso, atendiendo
                      lo dispuesto por la Ley General de Contabilidad
                      Gubernamental, los documentos del Consejo Nacional de
                      Armonización Contable (CONAC), Ley General de Disciplina
                      Financiera y demás disposiciones jurídicas aplicables.
                      Enfocado a los siguientes objetivos: Para el rubro de
                      ingresos: Verificar que los ingresos fueron obtenidos,
                      recaudados, captados y administrados por el sujeto
                      fiscalizado, de conformidad con la Ley de Ingresos; y que
                      se encuentren registrados y presentados correctamente, de
                      acuerdo con la normatividad establecida. Respecto al rubro
                      de Egresos: Comprobar que los recursos públicos se hayan
                      ejercido conforme a los programas aprobados y montos
                      autorizados, y con cargo a las partidas correspondientes;
                      que se hayan aplicado legal y eficientemente al logro de
                      los objetivos y metas; y que las adquisiciones se hayan
                      adjudicado atendiendo a su cuantía consideradas
                      individualmente, y en función del presupuesto total
                      autorizado.
                    </p>
                    <br />
                    <strong>Área responsable: </strong>
                    <span>
                      Auditoría Adjunta de Fiscalización al Gobierno del Estado.
                    </span>
                    <br />
                    <strong>
                      Fecha de notificación al sujeto fiscalizado:{" "}
                    </strong>
                    <span>6 de abril de 2020</span>
                    <br />
                    <table className="table table-striped table-bordered table-responsive">
                      <thead style={{background: "#4e152a", color: "white"}}>
                        <tr>
                          <th>Fecha</th>
                          <th>
                            Actividades Realizadas por el Personal del Isaf
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>8 de abril de 2020</td>
                          <td>Inicio de Auditoría</td>
                        </tr>
                        <tr>
                          <td>9 de abril de 2020</td>
                          <td>
                            Verificación en la bodega del proveedor en donde es
                            estaban elaborando las despensas con la finalidad de
                            verificar si las mismas cumplen con las
                            especificaciones señaladas en el contrato para la
                            elaboración de las 50,000 despensas.
                          </td>
                        </tr>
                        <tr>
                          <td>13 de abril de 2020 al 30 de junio de 2020 </td>
                          <td>
                            Verificación de los requisitos señalados por las
                            reglas de operación del programa PESA, de igual
                            forma el contrato celebrado con el proveedor que
                            resultó ganador para elaborar las despensas
                          </td>
                        </tr>
                        <tr>
                          <td>14 de abril de 2020 al 30 de junio de 2020 </td>
                          <td>
                            Revisión de forma selectiva en dos de los lotes
                            emplayados y preparados para su distribución y
                            entrega, con la finalidad de verificar que los
                            ingredientes y contenidos señalados en el contrato,
                            coincidan con los artículos que integran las
                            despesas.
                          </td>
                        </tr>
                        <tr>
                          <td>14 de abril de 2020 al 30 de junio de 2020 </td>
                          <td>
                            Verificación de forma aleatoria de los documentos
                            generados para el control de salidas de almacén de
                            las despensas, con la finalidad de comprobar si
                            cumplen con lo señalado en las localidades de
                            entrega y población objetivo.
                          </td>
                        </tr>
                        <tr>
                          <td>8 de abril </td>
                          <td>
                            Análisis comparativo con las 5 propuestas
                            presentadas por los proveedores que fueron invitados
                            a participar ara la elaboración y entrega SEDESSON
                            de las 50,000 despensas requeridas para atender la
                            contingencia Covid-19.
                          </td>
                        </tr>
                        <tr>
                          <td>13 de abril al 29 de mayo de 2020 </td>
                          <td>
                            Se realizó el procedimiento de compulsa, con el fin
                            de verificar que el proveedor, cuenta con
                            instalaciones en el domicilio señalado en sus
                            comprobantes o CFDI.
                          </td>
                        </tr>
                        <tr>
                          <td>03 al 29 de mayo de 2020 </td>
                          <td>
                            Se verificó que las despensas fueron entregadas en
                            las colonias de los sectores señalados en los
                            polígonos de pobreza y sectores marginados
                            establecidos por la secretaría de desarrollo social.
                          </td>
                        </tr>
                        <tr>
                          <td>15 de abril de 2020 al 30 de junio de 2020 </td>
                          <td>Análisis de evidencias.</td>
                        </tr>
                        <tr>
                          <td>25 de mayo de 2020</td>
                          <td>
                            Se realizó un inventario en el Almacén de Activo
                            Fijo, tomando como base las notas de remisión.
                          </td>
                        </tr>
                        <tr>
                          <td>5 noviembre de 2020 </td>
                          <td>
                            Se notifican observaciones de precierre mediante
                            acta circunstanciada deactuaciones y precierre de
                            auditoría
                          </td>
                        </tr>
                        <tr>
                          <td>18 de enero de 2021 </td>
                          <td>
                            Se notifica pronunciamiento mediante el cual se
                            informan las observaciones de precierre solventadas
                            y no solventadas, de estas últimas los asuntos
                            pendientes de atenter
                          </td>
                        </tr>
                        <tr>
                          <td>20 enero de 2021 </td>
                          <td>
                            Se informan los resultados finales de la revisión,
                            mediante acta de cierre de auditoría
                          </td>
                        </tr>
                        <tr>
                          <td>4 de febrero de 2021 </td>
                          <td>
                            Se notifica formalmente el informe individual que
                            muestra el resultado de las observaciones derivadas
                            de la revisión.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="btn-group">
                      <button className="btn btn-large btn-secondary">
                        Denuncias
                      </button>
                      <button className="btn btn-large btn-secondary">
                        Comentarios
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="true"
                    aria-controls="collapseThree"
                  >
                    Auditoría en tiempo real a FIDESON
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <h3 className="text-danger">COVID-19</h3>
                    <strong>Solicitada por:</strong>
                    <p>
                      C. Gobernadora del Estado de Sonora, Lic. Claudia
                      Pavlovich Arellano (2 de abril de 2020).
                    </p>
                    <strong>Código de Auditoría: </strong>{" "}
                    <p>2020AE0113010005</p>
                    <br />
                    <strong>Sujeto fiscalizado: </strong>{" "}
                    <p>Financiera para el Desarrollo Económico de Sonora.</p>
                    <br />
                    <strong>Propósito: </strong>
                    <p>
                      Llevar a cabo la revisión por la adquisición de bienes,
                      insumos y equipamiento destinado para garantizar la
                      capacidad de atención médica a la población del estado,
                      con motivo de contingencia sanitaria-epidemiológica por el
                      Covid-19, para que se lleve a cabo de manera transparente
                      y apegado a la normatividad aplicable.
                    </p>
                    <br />
                    <strong>Tipo de Auditoría: </strong>
                    <p>Financiera y de cumplimiento.</p>
                    <br />
                    <strong>Objeto: </strong>
                    <p>
                      Evaluar que los recursos públicos se administren con
                      eficiencia, eficacia, economía, transparencia y honradez
                      para cumplir los objetivos y programas a los que están
                      destinados, en apego al artículo 150 de la Constitución
                      Política del Estado de Sonora. Evaluar los resultados de
                      la gestión financiera del ingreso y del egreso, atendiendo
                      lo dispuesto por la Ley General de Contabilidad
                      Gubernamental, los documentos del Consejo Nacional de
                      Armonización Contable (CONAC), Ley General de Disciplina
                      Financiera y demás disposiciones jurídicas aplicables.
                      Enfocado a los siguientes objetivos: Para el rubro de
                      ingresos: Verificar que los ingresos fueron obtenidos,
                      recaudados, captados y administrados por el sujeto
                      fiscalizado, de conformidad con la Ley de Ingresos; y que
                      se encuentren registrados y presentados correctamente, de
                      acuerdo con la normatividad establecida. Respecto al rubro
                      de Egresos: Comprobar que los recursos públicos se hayan
                      ejercido conforme a los programas aprobados y montos
                      autorizados, y con cargo a las partidas correspondientes;
                      que se hayan aplicado legal y eficientemente al logro de
                      los objetivos y metas; y que las adquisiciones se hayan
                      adjudicado atendiendo a su cuantía consideradas
                      individualmente, y en función del presupuesto total
                      autorizado.
                    </p>
                    <br />
                    <strong>Área responsable: </strong>
                    <span>
                      Auditoría Adjunta de Fiscalización al Gobierno del Estado.
                    </span>
                    <br />
                    <strong>
                      Fecha de notificación al sujeto fiscalizado:{" "}
                    </strong>
                    <span>6 de abril de 2020</span>
                    <br />
                    <table className="table table-striped table-bordered table-responsive">
                      <thead style={{background: "#4e152a", color: "white"}}>
                        <tr>
                          <th>Fecha</th>
                          <th>
                            Actividades Realizadas por el Personal del Isaf
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>8 de abril de 2020</td>
                          <td>Inicio de Auditoría</td>
                        </tr>
                        <tr>
                          <td>9 de abril de 2020</td>
                          <td>
                            Verificación en la bodega del proveedor en donde es
                            estaban elaborando las despensas con la finalidad de
                            verificar si las mismas cumplen con las
                            especificaciones señaladas en el contrato para la
                            elaboración de las 50,000 despensas.
                          </td>
                        </tr>
                        <tr>
                          <td>13 de abril de 2020 al 30 de junio de 2020 </td>
                          <td>
                            Verificación de los requisitos señalados por las
                            reglas de operación del programa PESA, de igual
                            forma el contrato celebrado con el proveedor que
                            resultó ganador para elaborar las despensas
                          </td>
                        </tr>
                        <tr>
                          <td>14 de abril de 2020 al 30 de junio de 2020 </td>
                          <td>
                            Revisión de forma selectiva en dos de los lotes
                            emplayados y preparados para su distribución y
                            entrega, con la finalidad de verificar que los
                            ingredientes y contenidos señalados en el contrato,
                            coincidan con los artículos que integran las
                            despesas.
                          </td>
                        </tr>
                        <tr>
                          <td>14 de abril de 2020 al 30 de junio de 2020 </td>
                          <td>
                            Verificación de forma aleatoria de los documentos
                            generados para el control de salidas de almacén de
                            las despensas, con la finalidad de comprobar si
                            cumplen con lo señalado en las localidades de
                            entrega y población objetivo.
                          </td>
                        </tr>
                        <tr>
                          <td>8 de abril </td>
                          <td>
                            Análisis comparativo con las 5 propuestas
                            presentadas por los proveedores que fueron invitados
                            a participar ara la elaboración y entrega SEDESSON
                            de las 50,000 despensas requeridas para atender la
                            contingencia Covid-19.
                          </td>
                        </tr>
                        <tr>
                          <td>13 de abril al 29 de mayo de 2020 </td>
                          <td>
                            Se realizó el procedimiento de compulsa, con el fin
                            de verificar que el proveedor, cuenta con
                            instalaciones en el domicilio señalado en sus
                            comprobantes o CFDI.
                          </td>
                        </tr>
                        <tr>
                          <td>03 al 29 de mayo de 2020 </td>
                          <td>
                            Se verificó que las despensas fueron entregadas en
                            las colonias de los sectores señalados en los
                            polígonos de pobreza y sectores marginados
                            establecidos por la secretaría de desarrollo social.
                          </td>
                        </tr>
                        <tr>
                          <td>15 de abril de 2020 al 30 de junio de 2020 </td>
                          <td>Análisis de evidencias.</td>
                        </tr>
                        <tr>
                          <td>25 de mayo de 2020</td>
                          <td>
                            Se realizó un inventario en el Almacén de Activo
                            Fijo, tomando como base las notas de remisión.
                          </td>
                        </tr>
                        <tr>
                          <td>5 noviembre de 2020 </td>
                          <td>
                            Se notifican observaciones de precierre mediante
                            acta circunstanciada deactuaciones y precierre de
                            auditoría
                          </td>
                        </tr>
                        <tr>
                          <td>18 de enero de 2021 </td>
                          <td>
                            Se notifica pronunciamiento mediante el cual se
                            informan las observaciones de precierre solventadas
                            y no solventadas, de estas últimas los asuntos
                            pendientes de atenter
                          </td>
                        </tr>
                        <tr>
                          <td>20 enero de 2021 </td>
                          <td>
                            Se informan los resultados finales de la revisión,
                            mediante acta de cierre de auditoría
                          </td>
                        </tr>
                        <tr>
                          <td>4 de febrero de 2021 </td>
                          <td>
                            Se notifica formalmente el informe individual que
                            muestra el resultado de las observaciones derivadas
                            de la revisión.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="btn-group">
                      <button className="btn btn-large btn-secondary">
                        Denuncias
                      </button>
                      <button className="btn btn-large btn-secondary">
                        Comentarios
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="true"
                    aria-controls="collapseFour"
                  >
                    Auditoría en tiempo real a CECOP
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <h3 className="text-danger">COVID-19</h3>
                    <strong>Solicitada por:</strong>
                    <p>
                      C. Gobernadora del Estado de Sonora, Lic. Claudia
                      Pavlovich Arellano (2 de abril de 2020)
                    </p>
                    <strong>Código de Auditoría: </strong>{" "}
                    <p>2020AE0113010007</p>
                    <br />
                    <strong>Sujeto fiscalizado: </strong>{" "}
                    <p>
                      Consejo Estatal para la Concertación de Obra Pública.
                    </p>
                    <br />
                    <strong>Propósito: </strong>
                    <p>
                      Llevar a cabo la revisión por la adquisición de bienes,
                      insumos y equipamiento destinado para garantizar la
                      capacidad de atención médica a la población del estado,
                      con motivo de contingencia sanitaria-epidemiológica por el
                      Covid-19, para que se lleve a cabo de manera transparente
                      y apegado a la normatividad aplicable.
                    </p>
                    <br />
                    <strong>Tipo de Auditoría: </strong>
                    <p>Financiera y de cumplimiento.</p>
                    <br />
                    <strong>Objeto: </strong>
                    <p>
                      Evaluar que los recursos públicos se administren con
                      eficiencia, eficacia, economía, transparencia y honradez
                      para cumplir los objetivos y programas a los que están
                      destinados, en apego al artículo 150 de la Constitución
                      Política del Estado de Sonora. Evaluar los resultados de
                      la gestión financiera del ingreso y del egreso, atendiendo
                      lo dispuesto por la Ley General de Contabilidad
                      Gubernamental, los documentos del Consejo Nacional de
                      Armonización Contable (CONAC), Ley General de Disciplina
                      Financiera y demás disposiciones jurídicas aplicables.
                      Enfocado a los siguientes objetivos: Para el rubro de
                      ingresos: Verificar que los ingresos fueron obtenidos,
                      recaudados, captados y administrados por el sujeto
                      fiscalizado, de conformidad con la Ley de Ingresos; y que
                      se encuentren registrados y presentados correctamente, de
                      acuerdo con la normatividad establecida. Respecto al rubro
                      de Egresos: Comprobar que los recursos públicos se hayan
                      ejercido conforme a los programas aprobados y montos
                      autorizados, y con cargo a las partidas correspondientes;
                      que se hayan aplicado legal y eficientemente al logro de
                      los objetivos y metas; y que las adquisiciones se hayan
                      adjudicado atendiendo a su cuantía consideradas
                      individualmente, y en función del presupuesto total
                      autorizado.
                    </p>
                    <br />
                    <strong>Área responsable: </strong>
                    <p>
                      Auditoría Adjunta de Fiscalización al Gobierno del Estado.
                    </p>
                    <br />
                    <strong>
                      Fecha de notificación al sujeto fiscalizado:{" "}
                    </strong>
                    <p>07 de mayo de 2020</p>
                    <br />
                    <table className="table table-striped table-bordered table-responsive">
                      <thead style={{background: "#4e152a", color: "white"}}>
                        <tr>
                          <th>Fecha</th>
                          <th>
                            Actividades Realizadas por el Personal del Isaf
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>7 de mayo de 2020 </td>
                          <td>Notificación de auditoría</td>
                        </tr>
                        <tr>
                          <td>11 de mayo de 2020 </td>
                          <td>
                            Designación de enlaces y firma de acta de inicio
                          </td>
                        </tr>
                        <tr>
                          <td>12 de mayo de 2020</td>
                          <td>
                            Verificación de los oficios de solicitud de
                            colaboración realizado por los Servicios de Salud de
                            Sonora a CECOP.
                          </td>
                        </tr>
                        <tr>
                          <td>13 de mayo de 2020 </td>
                          <td>
                            Revisión del convenio de colaboración realizado
                            entre CECOP y SSS para la donación de 73
                            ventiladores.
                          </td>
                        </tr>
                        <tr>
                          <td>14 de mayo de 2020 </td>
                          <td>
                            Revisión del acta de sesión del Consejo Directivo
                            del CECOP donde se autoriza la utilización de
                            recursos para la compra y donación de los 73
                            ventiladores.
                          </td>
                        </tr>
                        <tr>
                          <td>14 de mayo de 2020 </td>
                          <td>
                            Verificó el oficio a través del cual el
                            Subsecretario de Egresos de la SH autoriza la
                            solicitud de recursos (remanentes 2018 y 2019)
                            realizada por CECOP.
                          </td>
                        </tr>
                        <tr>
                          <td>15 de mayo de 2020 </td>
                          <td>
                            Revisión del estudio de mercado realizado para la
                            adquisición de los 73 ventiladores.
                          </td>
                        </tr>
                        <tr>
                          <td>18 de mayo de 2020 </td>
                          <td>
                            Verificación el Dictamen de Adjudicación Directa
                            para la compra de los 73 ventiladores.
                          </td>
                        </tr>
                        <tr>
                          <td>18 de mayo de 2020 al 30 de junio </td>
                          <td>
                            Revisión de las facturas y contratos por la compra
                            de los 73 ventiladores.
                          </td>
                        </tr>
                        <tr>
                          <td>22 de mayo de 2020 </td>
                          <td>
                            Verificación del convenio de donación de los 73
                            ventiladores entre CECOP y SSS
                          </td>
                        </tr>
                        <tr>
                          <td>01 de julio y 31 de agosto de 2020 </td>
                          <td>
                            Se verificó la entrega oportuna de los equipos
                            adquiridos al proveedor, y en caso de incumplimiento
                            en los tiempos de entrega y condiciones del equipo
                            se verifico que se aplicaran las penas
                            convencionales, así como la restitución del recursos
                            o reposición de los equipos.
                          </td>
                        </tr>
                        <tr>
                          <td>18 de noviembre de 2020 </td>
                          <td>
                            Se notifican observaciones de precierre mediante
                            acta circunstanciada deactuaciones y precierre de
                            auditoría
                          </td>
                        </tr>
                        <tr>
                          <td>9 de diciembre de 2020 </td>
                          <td>
                            Se informan los resultados finales de la revisión,
                            mediante acta de cierre de auditoría
                          </td>
                        </tr>
                        <tr>
                          <td>2 de febrero de 2021 </td>
                          <td>
                            Se notifica formalmente el informe individual que
                            muestra el resultado de la revisión.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="btn-group">
                      <button className="btn btn-large btn-secondary">
                        Denuncias
                      </button>
                      <button className="btn btn-large btn-secondary">
                        Comentarios
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="true"
                    aria-controls="collapseFive"
                  >
                    Auditoría en tiempo real al DIF Hermosillo
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <h3 className="text-danger">COVID-19</h3>
                    <strong>Solicitada por:</strong>
                    <p>
                      Dir. Gral del DIF Hermosillo, Lic. Bernardeth Ruíz Romero
                      (20 de abril de 2020).
                    </p>
                    <strong>Código de Auditoría: </strong>{" "}
                    <p>2020AM0113010004</p>
                    <br />
                    <strong>Sujeto fiscalizado: </strong>{" "}
                    <p>DIF Hermosillo.</p>
                    <br />
                    <strong>Propósito: </strong>
                    <p>
                      Llevar a cabo la revisión al proceso de compra y entrega
                      de despensas a la población vulnerable del municipio de
                      Hermosillo con motivo de la contingencia derivada del
                      COVID-19 en Sonora, para que se lleve a cabo de manera
                      transparente y apegado a la normatividad aplicable.
                    </p>
                    <br />
                    <strong>Tipo de Auditoría: </strong>
                    <p>Financiera y de cumplimiento.</p>
                    <br />
                    <strong>Objeto: </strong>
                    <p>
                      Evaluar que los recursos públicos se administren con
                      eficiencia, eficacia, economía, transparencia y honradez
                      para cumplir los objetivos y programas a los que están
                      destinados, en apego al artículo 150 de la Constitución
                      Política del Estado de Sonora. Evaluar los resultados de
                      la gestión financiera del ingreso y del egreso, atendiendo
                      lo dispuesto por la Ley General de Contabilidad
                      Gubernamental, los documentos del Consejo Nacional de
                      Armonización Contable (CONAC), Ley General de Disciplina
                      Financiera y demás disposiciones jurídicas aplicables.
                      Enfocado a los siguientes objetivos: Para el rubro de
                      ingresos: Verificar que los ingresos fueron obtenidos,
                      recaudados, captados y administrados por el sujeto
                      fiscalizado, de conformidad con la Ley de Ingresos; y que
                      se encuentren registrados y presentados correctamente, de
                      acuerdo con la normatividad establecida. Respecto al rubro
                      de Egresos: Comprobar que los recursos públicos se hayan
                      ejercido conforme a los programas aprobados y montos
                      autorizados, y con cargo a las partidas correspondientes;
                      que se hayan aplicado legal y eficientemente al logro de
                      los objetivos y metas; y que las adquisiciones se hayan
                      adjudicado atendiendo a su cuantía consideradas
                      individualmente, y en función del presupuesto total
                      autorizado.
                    </p>
                    <br />
                    <strong>Área responsable: </strong>
                    <p>Auditoría Adjunta de Fiscalización a Municipios</p>
                    <br />
                    <strong>
                      Fecha de notificación al sujeto fiscalizado:{" "}
                    </strong>
                    <p>22 de abril de 2020</p>
                    <br />
                    <table className="table table-striped table-bordered table-responsive">
                      <thead style={{background: "#4e152a", color: "white"}}>
                        <tr>
                          <th>Fecha</th>
                          <th>
                            Actividades Realizadas por el Personal del Isaf
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>4 de mayo de 2020 </td>
                          <td>Inicio de auditoría</td>
                        </tr>
                        <tr>
                          <td>4 de mayo de 2020 a la fecha </td>
                          <td>
                            Revisión de información proporcionada referente a
                            los procedimientos de llevados a cabo tanto para la
                            compra como para la entrega de despensas, incluida
                            el acta extraordinaria de fecha 29 de abril de 2020,
                            que fue proporcionada a este instituto el día 6 de
                            mayo, autoriza los procedimientos llevados a cabo
                            tanto para la compra como para la entrega de
                            despensas.
                          </td>
                        </tr>
                        <tr>
                          <td>8 y 18 de mayo de 2020 </td>
                          <td>Verificación física de entrega de despensas</td>
                        </tr>
                        <tr>
                          <td>8 de junio de 2020 </td>
                          <td>
                            Solicitud del acta donde se autorizan los
                            procedimientos llevados a cabo tanto para la compra
                            como para la entrega de despensas
                          </td>
                        </tr>
                        <tr>
                          <td>8 de junio de 2020 al 30 de junio de 2020 </td>
                          <td>
                            Se realizó un análisis de los gastos efectuados por
                            parte del Ente para la compra de despensas a
                            entregar
                          </td>
                        </tr>
                        <tr>
                          <td>8 de junio de 2020 al 30 de junio de 2020 </td>
                          <td>
                            Se realizó la verificación del estatus de las
                            facturas en el portal del SAT, así mismo que
                            cumplieran con los requisitos fiscales, obteniéndose
                            resultados satisfactorios en dicha revisión
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="btn-group">
                      <button className="btn btn-large btn-secondary">
                        Denuncias
                      </button>
                      <button className="btn btn-large btn-secondary">
                        Comentarios
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="true"
                    aria-controls="collapseSix"
                  >
                    Acuerdo por el cual se suspenden los plazos y los términos
                    relacionados con las actividades del ISAF derivado de la
                    contingencia COVID-19
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    {innerSize >= 480 ? (
                      <>
                        <Document
                          renderMode="svg"
                          className="p-3 border bg-light"
                          file={AcuerdoCovidPdf}
                          onLoadSuccess={onDocumentLoadSuccess}
                        >
                          <Page fixed pageNumber={pageNumber}></Page>
                          <Page fixed pageNumber={pageNumber + 1}></Page>
                        </Document>
                        <div className="justify-content-center d-flex btn-pages">
                          <div style={{ marginTop: "10px" }}>
                            <button
                              disabled={pageNumber <= 1}
                              onClick={() => setPagesNumber(pageNumber - 2)}
                              className="btn-outlined btn-sm btn-page"
                            >
                              <i class="bi bi-arrow-left"></i>
                            </button>
                            {pageNumber} / {numPages}{" "}
                            <button
                              onClick={() => setPagesNumber(pageNumber + 2)}
                              className="btn-outlined btn-sm btn-page"
                            >
                              <i class="bi bi-arrow-right"></i>
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <Document
                          renderMode="svg"
                          className="p-3 border bg-light"
                          file={AcuerdoCovidPdf}
                          onLoadSuccess={onDocumentLoadSuccess}
                        >
                          <Page fixed pageNumber={pageNumber}></Page>
                        </Document>
                        <div className="justify-content-center d-flex btn-pages">
                          <div style={{ marginTop: "10px" }}>
                            <button
                              disabled={pageNumber <= 1}
                              onClick={() => setPagesNumber(pageNumber - 1)}
                              className="btn-outlined btn-sm btn-page"
                            >
                              <i class="bi bi-arrow-left"></i>
                            </button>
                            {pageNumber} / {numPages}{" "}
                            <button
                              onClick={() => setPagesNumber(pageNumber + 1)}
                              className="btn-outlined btn-sm btn-page"
                            >
                              <i class="bi bi-arrow-right"></i>
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="true"
                    aria-controls="collapseSeven"
                  >
                    Respuesta del ISAF a solicitud de Secretaría de Salud ante
                    emergencia del COVID-19
                  </button>
                </h2>
                <div
                  id="collapseSeven"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      Derivado de la situación en la que se encuentra el estado
                      y el país por la contingencia del COVID-19, la Secretaría
                      de Salud del Estado de Sonora, solicita a las entidades
                      públicas poder contar con vehículos para poder dar
                      atención a las campañas que se han estado llevando a cabo
                      derivadas de la pandemia en la que nos encontramos.
                    </p>
                    <em>Se hace entrega de vehículos el día 8 de abril de 2020.</em>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  
}
