import React, { Component } from "react";
import "./Ambitos.css";
import Select from "react-select";
import axios from "axios";
import { hostSigas } from "../../Utils";

class Ambitos extends Component {
  constructor(props) {
    super();
    this.state = {
      ambitos: [
        { value: "Estatal", label: "Estatal" },
        { value: "Municipal", label: "Municipal" },
      ],
    };
  }

  ambitoSelected(selected) {
    if (this.props.tipoInforme === "General") {
      this.getInformes(selected.value);
    } else {
      axios
        .get(
          hostSigas() +
            `/api/TipoEnte/Ambito/${selected.value}/${this.props.ejercicio.value}/${this.props.tipoInforme}`
        )
        .then((x) => {
          this.props.callback({
            selectedTipoEnte: "",
            tipoEntes: x.data.map((e) => {
              return {
                value: e.id,
                label: e.nombre,
              };
            }),
            ambito: selected.value,
            informes: [],
            message: "",
            entes: [],
          });
        })
        .catch((x) => console.log(x));
    }
  }

  getInformes(ambito) {
    if (this.props.ejercicio.value) {
      //"api/Informes/EnteEjercicio/{EnteId}/{EjercicioId}/{TipoInforme}/{Ambito}/{FondoId}"
      axios
        .get(
          hostSigas() +
            `/api/Informes/EnteEjercicio/0/${this.props.ejercicio.value}/${this.props.tipoInforme}/${ambito}/0`
        )
        .then((x) => {
          if (x.data.length < 1) {
            this.props.callback({
              informes: x.data.map((x) => {
                x.selected = false;
                return x;
              }),
              message: `No se ha cargado el Informe de ${ambito.label} para el ejercicio ${this.props.ejercicio.label}`,
              enteName: ambito,
            });
          } else {
            this.props.callback({
              informes: x.data.map((x) => {
                x.selected = false;
                return x;
              }),
              message: "",
              enteName: ambito,
            });
          }

          this.navigateToInformes();
        })
        .catch((x) => console.log(x));
    } else {
      this.props.callback({
        message: "Seleccione un Ejercicio para cargar los Informes",
      });
    }
  }

  render() {
    let result = (
      <div id="ambitos-container">
        <label id="ambitos-label">Ambito</label>
        <Select
          placeholder="Seleccione Ambito"
          options={this.state.ambitos}
          onChange={this.ambitoSelected.bind(this)}
        />
        <br />
      </div>
    );

    if (this.props.tipoInforme === "Federal") return <div></div>;

    return result;
  }
}

export default Ambitos;
