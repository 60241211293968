import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import esLocale from '@fullcalendar/core/locales/es';
import Swal from 'sweetalert2'
import './Calendario.css'

export const Calendario = () => {
    const customLocale = {
        ...esLocale,
        monthNames: [
          'ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO',
          'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'
        ],
        monthNamesShort: [
          'ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN',
          'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'
        ],
    };

    const eventos = [
        {
            title: "Cuenta Pública 2023",
            start: '2024-04-15',
            allDay: true,
            description: 'Presentación ante el H. Congreso del Estado de Sonora de la Cuenta Pública 2023 del Gobierno del Estado y los gobiernos municipales.',
            className:'event-publicacion1'
        },
        {
            title: "Informe de Cuenta Pública 2023",
            start: '2024-04-16',
            allDay: true,
            description: 'Publicación oficial del Informe de Cuenta Pública 2023 del Gobierno del Estado y los gobiernos municipales.',
            className:'event-publicacion2'
        },
        {
            title: "Primer Informe Semestral",
            start: '2024-05-15',
            allDay: true,
            description: 'Primer Informe Semestral 2024 del ISAF.',
            className:'event-publicacion3'
        },
        {
            title: "Publicación de la convocatoria",
            start: '2024-06-03',
            allDay: true,
            description: 'Publicación de la 5ta. Convocatoria de Participación Ciudadana en la Fiscalización.',
            className:'event-publicacion4'
        },
        {
            title: "Informe de Actividades ",
            start: '2024-06-28',
            allDay: true,
            description: '2do. Informe de Actividades 2023-2024 del ISAF.',
            className:'event-publicacion1'
        },
        {
            title: "Informes de Resultados",
            start: '2024-08-30',
            allDay: true,
            description: 'El ISAF presenta los Informes de Resultados y la Propuesta de Calificación de las cuentas públicas 2023 al H. Congreso del Estado de Sonora.',
            className:'event-publicacion2'
        },
        {
            title: "Cierre de recepción",
            start: '2024-08-31',
            allDay: true,
            description: 'Cierre de recepción de propuestas de la 5ta. Convocatoria de Participación Ciudadana en la Fiscalización.',
            className:'event-publicacion1'
        },
        
        {
            title: "Reunión del Comité",
            start: '2024-09-10',
            allDay: true,
            description: 'Reunión del Comité de Planeación Estratégica y Participativa de Auditorías.',
            className:'event-publicacion4'
        },
        {
            title: "Resultados de la convocatoria",
            start: '2024-09-13',
            allDay: true,
            description: 'Emisión de resultados de la convocatoria.',
            className:'event-publicacion1'
        },
        {
            title: "Programa Anual de Auditorías",
            start: '2024-09-30',
            allDay: true,
            description: 'Publicación del Programa Anual de Auditorías 2024-2025.',
            className:'event-publicacion2'
        },
        {
            title: "Segundo Informe Semestral ",
            start: '2024-11-15',
            allDay: true,
            description: 'Segundo Informe Semestral 2024 del ISAF.',
            className:'event-publicacion3'
        },
        {
            title: "Informe Anual de Indicadores",
            start: '2024-12-13',
            allDay: true,
            description: 'Publicación del Informe Anual de Indicadores de Fiscalización 2024.',
            className:'event-publicacion4'
        }
    ];

    const handleEventClick = (info) => {
        Swal.fire({
            title: info.event.title,
            text: info.event.extendedProps.description,
            icon: 'info',
            confirmButtonText: 'Cerrar'
        })
    }

    return (
        <>
            <div className='fondo4'>
                <div className="text-container">
                    <h1 style={{fontWeight:'bolder'}}>CALENDARIO</h1>
                </div>
            </div>
            <div style={{ marginTop: '-300px' }}>
                <FullCalendar
                    locale={customLocale}
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth"
                    height={700}
                    events={eventos}
                    eventClick={handleEventClick}
                />
            </div>
        </>
    )
}
