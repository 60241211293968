import { smallScreen } from "../../Utils";
import Documentos from "../../components/Documentos";

export default function PresupuestosEgresos() {
    return (
        <div className="container-fluid cuenta-publica-main">
            <div className="flex-container d-flex flex-column align-content-center" style={{ alignItems: 'center' }}>
                <div style={{ marginBottom: "30px", textAlign: "center", padding: '5px 25px 5px 20px', fontSize: smallScreen() ? '24px' : '42px', fontWeight: 600, color: '#832347' }}>PRESUPUESTOS DE EGRESOS</div>
            </div>

            <Documentos number="FA-0269"></Documentos>
        </div>
    )
}