import React, { useEffect, useState } from 'react';
import { useConstructor } from '../Utils';
import { Card, CardBody } from "reactstrap";
import './InformesIndividuales.css';
import Img0 from '../images/iconos/entrevista.png';
import Img1 from '../images/iconos/archivo.png';
import Img2 from '../images/iconos/rechazado.png';
import Img3 from '../images/iconos/aprobado.png';
import ImgDevengado from '../images/devengado.png';
import ImgIsssteson from '../images/isssteson.png';
import ImgObs from '../images/iconos/auditoria-hand.png';
import ImgRecomendacion2 from '../images/iconos/intercambio.png';
import ImgFinanciera from '../images/iconos/activos.png';
import axios from 'axios';
export function InformeIndividual(props) {

    const [secciones, setSecciones] = useState([]);

    useConstructor(() => props.setTitle("Informe Ciudadano"));

    useEffect(() => {
        axios.get('https://localhost:44340/api/Informes/Ciudadano/2/17/2').then(res => {
            console.log(res.data)
            setSecciones(res.data)
        })

    }, [])

    const renderSecciones = secciones.map(seccion => {
        var pos = secciones.indexOf(seccion)
        var image;
        switch (pos) {
            case 0:
                image = Img0;
                break;
            case 1:
                image = Img1;
                break;
            case 2:
                image = Img2;
                break;
            case 3:
                image = Img3;
                break;
            default:
                image = Img0;
        }

            const render =
                <div>
                    <Card className='card-ii' style={{ maxWidth: 610, minHeight: 500 }}>
                        <img src={image} className='image-ii' width="200px !important"  alt="Por qué?" />
                            <CardBody>
                                <h3>{seccion.seccionesInformesNombre}</h3>
                                <p>{seccion.texto }</p>
                            </CardBody>
                        </Card>
                       
                </div>

            return render;
        })
    

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', alignItems: 'center' }}>
                <div className='center' style={{ maxWidth: 500 }}>
                    <img src={ImgIsssteson} style={{ marginBottom: "10px" }} className='image-ii' height="100px" alt="ISSSTESON" />
                    <h3 className="h3 fw-bold">Instituto de Seguridad y Servicios Sociales de los Trabajadores del Estado de Sonora</h3>
                </div>

                <div className='flex-container' style={{ flexDirection: 'column', alignItems: 'center' }}>
                    <div className="tipo-informe">
                        <h3 className="fw-bold">Auditoría Financiera</h3>
                        <img src={ImgFinanciera} className='image-ii' height="100px" alt="ISSSTESON" />
                        
                        <div className="flex-container" style={{ flexDirection: 'column', justifyContent: 'space-evenly', height:100 }}>
                            <span className="badge bg-primary" > Cuenta Pública 2020</span>
                            <span className="badge bg-primary" >Código de auditoría: 2020AE0102010377 </span> 
                            <span className="badge bg-black" >Publicado el 28 de Mayo del 2021</span>
                        </div>
                        <div >
                            <b>Informe Completo Digital</b><br />
                            <a style={{ textDecoration: "none", color: "#0366d6"}} href="https://observaciones.isaf.gob.mx/api/File/InformeWord/15373" rel="noreferrer" target="_blank"> <i className="x2 bi bi-file-earmark-word"></i> </a>
                            <a style={{ textDecoration: "none", color:"red"}} href="https://sigas.isaf.gob.mx/api/File/Informe/15373" rel="noreferrer" target="_blank" ><i className="x2 bi bi-filetype-pdf"></i></a>
                        </div>
                    </div>
                </div>
            </div>            
           
            <div className='flex-container' style={{ justifyContent: 'space-evenly' }}>
                {renderSecciones}
                
            </div>
            <div className='flex-container' style={{ justifyContent: 'space-evenly' }}>
                <Card className='card-ii center' style={{ width: 400 }}>
                    <img src={ImgDevengado} className='image-ii' height="125px" alt="Egresos" />
                    <CardBody>
                    <h4>Egresos devengados</h4>
                    <h4>$8,139,269,319</h4>
                    </CardBody>
                </Card>
                <Card className='card-ii center' style={{ width: 400 }}>
                    <img src={ImgObs} className='image-ii'height="125px" alt="Observaciones" />
                    <CardBody>
                    <h4>Observaciones</h4>
                    <h4>54</h4>
                    </CardBody>
                </Card>
                <Card className=' card-ii center' style={{ width: 400 }}>
                    <img src={ImgRecomendacion2} className='image-ii' height="125px" alt="Recomendaciones" />
                    <CardBody>
                    <h4>Recomendaciones</h4>
                    <h4>1</h4>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
}

//<a href="https://www.flaticon.es/iconos-gratis/negocios-y-finanzas" title="negocios-y-finanzas iconos">Negocios-y-finanzas iconos creados por Freepik - Flaticon</a>