import React, { useEffect, useState } from 'react';
import { useConstructor, host } from '../../Utils';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser'


export function Nota  (props) { 


    const [notaId, setNotaId] = useState(0);
    const [nota, setNota] = useState('');
    const [imagenDispaly, setImagenDisplay] = useState('');
    const [notasPrevNext, setNotasPrevNext] = useState([]);

    useConstructor(() => {
        props.setTitle(nota.titulo)
        const urlparams = new URLSearchParams(window.location.search);
        const urlNotaId = urlparams.get('nota');
        setNotaId(urlNotaId)

    });

    useEffect(() => {
        if (notaId !== 0) {
            axios.get(host() + "/api/Notas/Nota/" + notaId)
                .then(res => {
                    console.log(res.data)
                    setNota(res.data)
                    props.setTitle(res.data.titulo)
                    if (res.data.imagen) {
                        axios.get(host() +'/api/File/Download/' + res.data.imagen)
                            .then((x) => {
                                setImagenDisplay(x.data.blockBlob.uri + x.data.sharedPolicy)
                            }).catch(x=> console.log(x))
                    }
                }).catch(x => console.log(x))
           
            axios.get(host() + "/api/Notas/NotasPrevNext/" + notaId)
                .then(res => {
                    setNotasPrevNext(res.data)
                }).catch(x => console.log(x))
        } else {
           
        }        
    }, [notaId])
   
    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <span className="nota-fecha">{nota.publicarDespuesDe}</span>
                {imagenDispaly ? <div className="center flex-column"><img className='nota-foto' src={imagenDispaly} alt="img" /> <span className="center">{nota.slug}</span></div> : ''}
                
                <span className='nota-contenido'>
                    {ReactHtmlParser(nota.contenido)
                    }                    
                </span>
            </div>
            <hr/>
            <div className='flex-between'>
            <span>Nota Anterior</span>
            <span>{(notasPrevNext.length>1? 'Nota Siguiente' : '')}</span>

            </div>
            <div className={'flex-between'+ (notasPrevNext.length>1? ' reverse' : '')} style={{flexWrap:'nowrap'}}>
                {notasPrevNext.map(x =>
                    <div className=
                    {"nota-sides" + 
                    (notasPrevNext.length>1 && (notasPrevNext.indexOf(x) === 0)?' text-right' :'') } key={x.id}>
                        <small className="text-muted">{x.publicarDespuesDe}</small><br/>
                        <a href={"/difusion/nota?nota=" + x.id}>{x.titulo} </a>
                        
                    </div>
                )}

            </div>

        </div>
    );

}
