import React, { useState } from 'react'
import { ManualesYGuias } from './ManualesYGuias';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { LeyesDeIngresos } from './LeyesDeIngresos';
import { Capacitaciones } from './Capacitaciones';
import { ConveniosMunicipales } from '../transparencia/ConveniosMunicipales';

export const DocumentosDeInteres = () => {
    const [activeTab, setActiveTab] = useState('1');
    const [isChecked, setIsChecked] = useState(false);
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
          setActiveTab(tab);
          setIsChecked(false);
        }
      };
  return (
    <>
     <Nav tabs>
    <NavItem>
      <NavLink
        className={activeTab === '1' ? 'active selected' : ''} onClick={() => toggleTab('1')}
      >
        Manuales y Guias
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={activeTab === '2' ? 'active selected' : ''} onClick={() => toggleTab('2')}
      >
        Convenio legislativo
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={activeTab === '3' ? 'active selected' : ''} onClick={() => toggleTab('3')}
      >
        Reservorio de capacitaciones
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={activeTab === '4' ? 'active selected' : ''} onClick={() => toggleTab('4')}
      >
       Convenios Municipales
      </NavLink>
    </NavItem>
  </Nav>
  <TabContent  activeTab={activeTab}>
    <TabPane tabId="1">
    <div className='mt-3'>
      <div className='d-flex' style={{justifyContent:'center'}}>
        <h1>Manuales y Guias</h1>
      </div>
    <ManualesYGuias setTitle={'Normativa Institucional'}/>
    </div>
    </TabPane>
    <TabPane tabId="2">
     <div className='mt-3'>
     <div className='d-flex' style={{justifyContent:'center'}}>
        <h1>Leyes de Ingresos</h1>
      </div>
      <LeyesDeIngresos/>
     </div>
    </TabPane>
    <TabPane tabId="3">
     <div className='mt-3'>
     {/* <div className='d-flex' style={{justifyContent:'center'}}>
        <h1>Reservorio de capacitaciones</h1>
      </div> */}
      <Capacitaciones/>
     </div>
    </TabPane>
    <TabPane tabId="4">
     <div className='mt-3'>
     <div className='d-flex' style={{justifyContent:'center'}}>
        <h1>Convenios Municipales</h1>
      </div>
      <ConveniosMunicipales/>
     </div>
    </TabPane>
  </TabContent>
    </>
  )
}
