import React, { Component } from "react";
import axios from "axios";
import "./Ejercicios.css";
import Select from "react-select";
import { hostSigas } from "../../Utils";

class Ejercicios extends Component {
  constructor(props) {
    console.log("props ejercicios ->", props)
    super();
    this.state = {
      select: {
        ejercicios: [],
      },
    };

    var tipo =
      props.tipoInforme === "de Resultados" ? "Resultados" : props.tipoInforme;

    axios
      .get(hostSigas() + "/api/Ejercicios/TipoInforme/" + tipo)
      .then((x) => {
        this.setState({
          select: {
            ejercicios: x.data.map((e) => {
              return {
                value: e.id,
                label: e.año,
              };
            }),
          },
        });
      })
      .catch((x) => console.log(x));
  }
  ejercicioChange = (selected) => {
    this.setValue(selected);
    this.props.callback({
      ejercicio: selected,
      informes: [],
      message: "",
    });

    this.props.unselect();

    if (this.props.tipoInformeName === "Federal") this.getFondos(selected);
  };

  getFondos(selected) {
    axios
      .get(
        hostSigas() +
          `/api/FondoFederal/${selected.value}/${this.props.tipoInformeName}`
      )
      .then((x) => {
        let ct = 0;
        this.props.callback({
          fondosFederales: x.data.map((e) => {
            return {
              index: (ct += 1),
              value: e.id,
              label: e.programa,
              selected: false,
            };
          }),
        });
      })
      .catch((x) => console.log(x));
  }

  setValue = (value) => {
    this.setState((prevState) => ({
      select: {
        ...prevState.select,
        value,
      },
    }));
  };

  handleClick = () => {
    this.setValue(null);
  };

  render() {
    const { select } = this.state;

    let result = (
      <div id="ejercicios-container">
        {/* <button className="btn btn-default" type="button" onClick={ this.handleClick }>Reset</button> */}
        <label id="ejercicios-label">Ejercicio</label>
        <Select
          placeholder="Seleccione Ejercicio"
          options={select.ejercicios}
          value={select.value}
          onChange={this.ejercicioChange}
        />
        <br />
      </div>
    );
    return result;
  }
}

export default Ejercicios;
