import React, { useEffect } from "react";
import { useConstructor } from '../../Utils';
import Documentos from "../../components/Documentos";

export function NormatividadInterna(props) {


  useEffect(() => {}, []);

  
    return (
      <div>
        <div style={{display:'flex', justifyContent:'center'}}>
        <h1>Normatividad Institucional</h1>
        </div>
        <p className="text-center text-bold title-extracto">
                Conjunto de criterios de observancia obligatoria específica para los servidores públicos del ISAF,
                que regula las distintas actividades para el funcionamiento óptimo de la Institución. Considerando ordenamientos como el Reglamento Interior, el Manual de Organización, entre otros.
            </p>
            <div className="text-right"> <a target="_blank" href="https://www.conac.gob.mx/es/CONAC/Normatividad_Vigente">Normatividad Vigente CONAC</a></div>
        <div className="flex-container">
          <Documentos number="FA-0247"></Documentos>
        </div>
      </div>
    );
  
}
