import React from 'react';
import { useConstructor } from '../../Utils';
import Documentos from '../../components/Documentos'

export function InformesAnterioresMunicipales  (props) { 
    useConstructor(() => {
        props.setTitle("Informes Municipales anteriores")
    
    });

    return (
        <div>
            <div className="flex-container">
            <h3>Informes de Resultados Municipales anteriores al 2017</h3>
                <div className="container">
                    <Documentos number="FA-0020" detail="true"></Documentos>
                </div>
            </div>

      </div>
    );
  
}
