import React, { useState, useEffect } from "react";
import { useConstructor, hostSigas, smallScreen } from '../../Utils';
import {  Collapse } from 'reactstrap';
import Acuerdos from "../../components/Acuerdos";
import axios from 'axios';


export function ListaAvisos(props) {
    const [Lista, setLista] = useState([]);
    
    useEffect(() => {
        ObtenerListas();
    }, []);
    const colorSet = (event) => {
        let item = event.target;
        if (item.tagName === "LI") {
            colorRemoveElsewere(item);
            item.className = "list-group-item list-group-item-info";
        }
    }

    const colorRemove = (event) => {
        let item = event.target;
        if (item.tagName === "LI") {
            item.className = "list-group-item";
            colorRemoveElsewere(item);
        } else {
            item = item.parentNode;
            item.className = "list-group-item";
            colorRemoveElsewere(item);
        }
    }

    const colorRemoveElsewere = (item) => {
        let childNodes = item.parentNode.childNodes;
        let childArray = [...childNodes];

        childArray.forEach(element => {
            if (element.tagName === "LI") {
                element.className = "list-group-item";
            }
        });
    }
    function ObtenerListas() {
        console.log("Entre ");
        axios.get(
            hostSigas() + "/api/ListaAvisosPortal/",

        ).then(res => {
            setLista(res.data)
            console.log(res);
        })
    }
    function VerAviso(archivo) {
        axios.get(
            hostSigas() + "/api/File/DownloadPublic/"+archivo,
        ).then(res => {
            console.log(res);
        })
    }
    function fetchFile(name, downloadName) {
        console.log(name, downloadName)
        let ext = name.split('.').pop();
        var link = document.createElement('a');
        name = name.replace('/', '-');
        downloadName = downloadName.replace('/', '-');
        //link.href = `${hostSigas()}/api/Storage/GetUrlWord/` + name + '/' + downloadName + '.' + ext;
        window.open(`${hostSigas()}/api/Storage/GetUrl/${name}/${downloadName}.${ext}`, '_blank');
        link.click();
    }
    
    return (
        <div>
            <p className="text-center text-bold title-extracto">
                Unidad de Substanciación
            </p>
         <h1 className="d-flex" style={{justifyContent:'center'}}>Lista de Notificaciones y Avisos</h1>
        
            <div className="flex-container">
                   
                    <div className="documentos">
                        <ul className="list-group">

                            {Lista.map((item) => (

                                <li className="list-group-item hover"
                                    onMouseEnter={colorSet}
                                    onMouseLeave={colorRemove}>
                                    <span className="title-extracto" >{item.fecha}</span>
                                    <p className="text-bold"><span style={{ display: item.expediente != "" ? "" : "none", }}>Expediente {item.expediente}</span> {item.descripcion}</p>
                                    <a style={{ display: item.acuerdo !="" ? "" : "none", }} onClick={() => fetchFile(item.acuerdo, "Acuerdo")}><i className="bi bi-box-arrow-down"></i>  Acuerdo</a>
                                    &nbsp;
                                    <a style={{ display: item.archivo != "" ? "" : "none", }} onClick={() => fetchFile(item.archivo, "Archivo")}><i className="bi bi-box-arrow-down"></i> Cédula de emplazamiento</a>
                                    
                                </li>
                            ))}

                        </ul>
                    </div>
        </div>
      </div>
    );
  
}
