import React, { useState } from 'react';
//import { useConstructor } from '../../Utils';
import { Button, Form,  Label } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export function SolicitudDeInformacionForm  (props) { 
    //useConstructor(() => props.setTitle("Solicitud de Información"));

  const getSource = () => {
    var source = "";
    if (document.location.href.includes("localhost")) {
      source = "https://localhost:44365";
    } else {
      source = "https://intranet.isaf.gob.mx";
    }
    return source;
  };

  const [formDenuncia, setValuesDenuncia] = useState({
    Denunciante: "",
    Organizacion: "",
    Ambito: "Estatal",
    AreaDeInteres: "Accesibilidad",
    Direccion: "",
    Email: "",
    Telefono: "",
    Dependencia: "",
    Motivo: "",
    Tipo: "",
    Evidencia: "",
  });

  function CleanUp() {
    setValuesDenuncia({
      Denunciante: "",
      Organizacion: "",
      Ambito: "Estatal",
      AreaDeInteres: "Accesibilidad",
      Direccion: "",
      Email: "",
      Telefono: "",
      Dependencia: "",
      Motivo: "",
      Tipo: "",
      Evidencia: "",
      ArchivoEvidencia: null,
    });

    document.getElementById("Form-Denuncia").reset();
  }

  const verifyRequired = (e) => {
    if (
      e.Denunciante !== "" &&
      e.Direccion !== "" &&
      e.Email !== "" &&
      e.Motivo !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const MySwal = withReactContent(Swal);
  const postDenuncia = (e) => {
    e.preventDefault();

    if (verifyRequired(formDenuncia)) {
      document.getElementById("SubmitButton").disabled = true;

      const denuncia = {
        Denunciante: formDenuncia.Denunciante,
        Ambito: "",
        Organizacion: "",
        AreaDeInteres: "",
        Direccion: formDenuncia.Direccion,
        Email: formDenuncia.Email,
        Telefono: "",
        Dependencia: "",
        Motivo: formDenuncia.Motivo,
        ArchivoEvidencia: "",
        Tipo: "Solicitud",
        Evidencia: formDenuncia.Evidencia,
      };

      const json = JSON.stringify(denuncia);
      const data = new FormData();
      data.append("denuncia", json);
      axios({
        method: "post",
        url: getSource() + "/Publico/Denuncia",
        data: data,
      }).then((res) => {
        MySwal.fire(
          <p>
            {res.data.Tipo} registrada con éxito, El número de Folio de su{" "}
            {res.data.Tipo} es: {res.data.Folio}.
          </p>
        );
        CleanUp();
        document.getElementById("SubmitButton").disabled = false;
      });
    } else {
      MySwal.fire(
        <p>
          Por favor, llene todos los campos.
        </p>
      );
    }
  };

  {
    const updateField = (e) => {
      setValuesDenuncia({
        ...formDenuncia,
        [e.target.name]: e.target.value,
      });
    };

    const isHome =()=>{
      if(!window.location.href.includes('solicitud')){
        return true
      }
      else{
        return false;
      }
    }


    return (
      <div style={{backgroundColor:'#ffffff43'}}>       
          <Form id="Form-Denuncia" onSubmit={postDenuncia}>
            <div className="form-inputs">
              <Label className= {isHome()? 'hidden' : ''}>
                Nombre de quien solicita{" "}
                <i className="bi bi-asterisk text-primary"></i>
              </Label>
              <input placeholder="👤 Nombre" 
                value={formDenuncia.Denunciante}
                name="Denunciante"
                onChange={updateField}
              />
            
              <Label className= {isHome()? 'hidden' : ''}>
                Dirección o medio para recibir notificaciones (email){" "}
                <i className="bi bi-asterisk text-primary"></i>
              </Label>
              <input placeholder="📧 Email"
                value={formDenuncia.Email}
                name="Email"
                onChange={updateField}
              />
              <Label className= {isHome()? 'hidden' : ''}>
                Descripción de la Información Solicitada{" "}
                <i className="bi bi-asterisk text-primary"></i>
              </Label>
              <textarea
              placeholder="💬 Solicitud" 
                type="textarea"
                rows="5"
                value={formDenuncia.Motivo}
                name="Motivo"
                onChange={updateField}
              />
            
              <Label className= {isHome()? 'hidden' : ''}>
                Medio por el cual desea recibir respuesta. (Copias, correo,
                consulta directa o CD){" "}
                <i className="bi bi-asterisk text-primary"></i>{" "}
              </Label>
              <input
              placeholder='💾 indique el medio por el cual desea recibir respuesta. (Copias, correo,
                consulta directa o CD)'
                value={formDenuncia.Direccion}
                name="Direccion"
                onChange={updateField}
              />
             
            <Button
              id="SubmitButton"
              className="btn btn-default mt-20"
              style={{ backgroundColor: '#832347', width: isHome() ? '' : '200px'}}
            >
              {" "}
              Enviar{" "}
            </Button>
            </div>
          </Form>
        </div>
     
    );
  }
}
