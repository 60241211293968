import React, { useEffect } from 'react';
import { useConstructor } from '../../Utils';
import imgheader from '../../images/misc/reservorio.jpg'


export function Capacitaciones(props) {
    // useConstructor(() => props.setTitle("Reservorio de Capacitaciones"));



    useEffect(() => {

    }, [])




    return (
        <div>
            <img src={imgheader} width='100%' alt="" />
            <div style={{ textAlign: 'center', textAlignLast: 'center' }}>
                <p className=''>Con el propósito de contribuir a la solventación de reincidencias, el ISAF pone a disposición de los municipios del Estado de Sonora que hayan firmado convenio de no reincidencias con la Institución, el catálogo de reuniones en línea llevadas a cabo con personal de Auditoría Adjunta de Fiscalización a Municipios, contralores municipales, presidentes municipales, y paramunicipales.</p>
                <div className='flex-container evenly' style={{ alignItems: 'flex-end' }}>
                    <div className='cap-container'>
                        <h3 className="has-text-align-center"><strong>DEPURACIÓN DE SALDOS ANTIGUOS DE CUENTAS POR COBRAR Y CUENTAS POR PAGAR</strong></h3>
                        <p>25 de Noviembre de 2020</p>
                        <p><iframe title="1" src="https://www.youtube.com/embed/3NGgcc5D3yg" width="100%" height="280" frameBorder="0" allowFullScreen></iframe></p>
                        <p><strong>Material de Apoyo:</strong></p>
                        <p>
                            <a href="https://intranet.isaf.gob.mx/Publico/Documento/5416"><i className="x2 bi bi-file-earmark-word"></i></a>
                            <a href="https://intranet.isaf.gob.mx/Publico/Documento/5417"><i className="x2 bi bi-file-earmark-word"></i></a>
                            <a href="https://intranet.isaf.gob.mx/Publico/Documento/5418"><i className="x2 bi bi-filetype-pdf no-decor red"></i></a>
                        </p>
                    </div>
                    <div className='cap-container'>
                        <h3 className="has-text-align-center"><strong>GASTOS POR COMPROBAR</strong></h3>
                        <p>27 de noviembre de 2020</p>
                        <p><iframe title="2" src="https://www.youtube.com/embed/3Vp46OQwwpY" width="100%" height="280" frameBorder="0" allowFullScreen></iframe></p>
                        <p><strong>Material de Apoyo:</strong></p>
                        <p>
                            <a href="https://intranet.isaf.gob.mx/Publico/Documento/5419"><i className="x2 bi bi-file-earmark-word"></i></a>
                            <a href="https://intranet.isaf.gob.mx/Publico/Documento/5420"><i className="x2 bi bi-filetype-pdf no-decor red"></i></a>
                        </p>
                    </div>
                    <div className='cap-container'>
                        <h3 className="has-text-align-center"><strong>IMPLEMENTACIÓN DEL SISTEMA DE ARMONIZACIÓN CONTABLE.</strong></h3>
                        <p>02 de diciembre de 2020</p>
                        <p><iframe title="3" src="https://www.youtube.com/embed/-E0pUXYkVaU" width="100%" height="280" frameBorder="0" allowFullScreen></iframe></p>
                        <p><strong>Material de Apoyo:</strong></p>
                        <p>
                            <a href="https://intranet.isaf.gob.mx/Publico/Documento/5421"><i className="x2 bi bi-filetype-pdf no-decor red"></i></a>
                        </p>
                    </div>
                    <div className='cap-container'>
                        <h3 className="has-text-align-center"><strong>2% SOBRE NÓMINA.</strong></h3>
                        <p>04 de diciembre de 2020</p>
                        <p><iframe title="4" src="https://www.youtube.com/embed/v0sXY0Km8NU" width="100%" height="280" frameBorder="0" allowFullScreen></iframe></p>
                        <p><strong>Material de Apoyo:</strong></p>
                        <p>
                            <a href="https://intranet.isaf.gob.mx/Publico/Documento/5422"><i className="x2 bi bi-file-earmark-word"></i></a>
                            <a href="https://intranet.isaf.gob.mx/Publico/Documento/5423"><i className="x2 bi bi-filetype-pdf no-decor red"></i></a>
                        </p>
                    </div>
                    <div className='cap-container'>
                        <h3 className="has-text-align-center"><strong>SEvAC.</strong></h3>
                        <p>09 de diciembre de 2020</p>
                        <p><iframe title="5" src="https://www.youtube.com/embed/Bv6eRlH55Hw" width="100%" height="280" frameBorder="0" allowFullScreen></iframe></p>
                        <p><strong>Material de Apoyo:</strong></p>
                        <p>
                            <a href="https://intranet.isaf.gob.mx/Publico/Documento/5424"><i className="x2 bi bi-filetype-pdf no-decor red"></i></a>
                        </p>
                    </div>
                    <div className='cap-container'>
                        <h3 className="has-text-align-center"><strong>REGISTROS SIN SOPORTE DOCUMENTAL.</strong></h3>
                        <p>11 de diciembre de 2020</p>
                        <p><iframe title="6" src="https://www.youtube.com/embed/-HXfDroV4tY" width="100%" height="280" frameBorder="0" allowFullScreen></iframe></p>
                        <p><strong>Material de Apoyo:</strong></p>
                        <p>
                            <a href="https://intranet.isaf.gob.mx/Publico/Documento/5425"><i className="x2 bi bi-file-earmark-word"></i></a>
                            <a href="https://intranet.isaf.gob.mx/Publico/Documento/5426"><i className="x2 bi bi-filetype-pdf no-decor red"></i></a>
                        </p>
                    </div>
                    <div className='cap-container'>
                        <h3 className="has-text-align-center"><strong>FONDO DE APORTACIONES PARA EL FORTALECIMIENTO MUNICIPAL (FORTAMUN)</strong></h3>
                        <p>15 de diciembre de 2020</p>
                        <p><iframe title="7" src="https://www.youtube.com/embed/vXBIL1zAf0c" width="100%" height="280" frameBorder="0" allowFullScreen></iframe></p>
                        <p><strong>Material de Apoyo:</strong></p>
                        <p>
                            <a href="https://intranet.isaf.gob.mx/Publico/Documento/5427"><i className="x2 bi bi-file-earmark-word"></i></a>
                            <a href="https://intranet.isaf.gob.mx/Publico/Documento/5428"><i className="x2 bi bi-filetype-pdf no-decor red"></i></a>
                        </p>
                    </div>
                    <div className='cap-container'>
                        <h3 className="has-text-align-center"><strong>FONDO PARA LA INFRAESTRUCTURA SOCIAL MUNICIPAL (FISM).</strong></h3>
                        <p>17 de diciembre de 2020</p>
                        <p><iframe title="8" src="https://www.youtube.com/embed/yffVyeQKLms" width="100%" height="280" frameBorder="0" allowFullScreen></iframe></p>
                        <p><strong>Material de Apoyo:</strong></p>
                        <p>
                            <a href="https://intranet.isaf.gob.mx/Publico/Documento/5430"><i className="x2 bi bi-file-earmark-word"></i></a>
                            <a href="https://intranet.isaf.gob.mx/Publico/Documento/5431"><i className="x2 bi bi-filetype-pdf no-decor red"></i></a>
                        </p>
                    </div>
                    <div className='cap-container'>
                        <h3 className="has-text-align-center"><strong>EXPEDIENTES TÉCNICOS DE LAS OBRAS PÚBLICAS</strong></h3>
                        <p>12 de febrero de 2021</p>
                        <p><iframe title="9" src="https://www.youtube.com/embed/fc_6jKfFS08" width="100%" height="280" frameBorder="0" allowFullScreen></iframe></p>
                    </div>
                    <div className='cap-container'>
                        <h3 className="has-text-align-center"><strong>BITÁCORA DE OBRAS PÚBLICAS</strong></h3>
                        <p>17 de febrero de 2021</p>
                        <p><iframe title="10" src="https://www.youtube.com/embed/es4W_GaDgjo" width="100%" height="280" frameBorder="0" allowFullScreen></iframe></p>
                        <p><strong>Material de Apoyo:</strong></p>
                        <p>
                            <a href="https://intranet.isaf.gob.mx/Publico/Documento/5432"><i className="x2 bi bi-filetype-pdf no-decor red"></i></a>
                        </p>
                    </div>
                    <div className='cap-container'>
                        <h3 className="has-text-align-center"><strong>CALIDAD DE LAS OBRAS PÚBLICAS</strong></h3>
                        <p>23 de febrero de 2021</p>
                        <p><iframe title="11" src="https://www.youtube.com/embed/GWzaKytUgcA" width="100%" height="280" frameBorder="0" allowFullScreen></iframe></p>
                        <p><strong>Material de Apoyo:</strong></p>
                        <p>
                            <a href="https://intranet.isaf.gob.mx/Publico/Documento/5433"><i className="x2 bi bi-filetype-pdf no-decor red"></i></a>
                        </p>
                    </div>
                    <div className='cap-container'>
                        <h3 className="has-text-align-center"><strong>PROCESO ENTREGA RECEPCIÓN, OBSERVACIONES NO SOLVENTADAS EN EL EJERCICIO FISCAL 2017, 2018 Y 2019.</strong></h3>
                        <p>24 de marzo de 2021</p>
                        <p><iframe title="12" src="https://www.youtube.com/embed/WyDjWIg-X_c" width="100%" height="280" frameBorder="0" allowFullScreen></iframe></p>
                    </div>
                </div>
            </div>

        </div>
    );

}
