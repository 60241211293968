import React, { useState, useEffect } from "react";
import { useConstructor } from '../Utils';
import axios from "axios";
import { hostSigas } from '../Utils.js';

//const Folder = ({ keyboard, setKeyboard, folderName }) => {
//    let [currentName, setCurrentName] = useState("");

//    useConstructor(()=>{
//        setCurrentName(folderName);
//    })

//    const handleFormSearch = (e) => {
//        e.preventDefault();
//        console.log(currentName, folderName)
//        setKeyboard(e.target.value.toLowerCase());
//    };
    
//    return (
//        <div className="search-docs-bar">
//            <input
//                type="Search"
//                placeholder="Buscar..."
//                value={keyboard}
//                onChange={handleFormSearch}
//                className="form-control mb-4"
//            />
//        </div>
//    );
//};

const Acuerdos = (props) => {
   
    const [documents, setDocuments] = useState([]);
    console.log("props documentos", props)
    

    useEffect(() => {
      
        console.log(hostSigas() + "/api/Acuerdos/" + props.fecha.slice(0, -9));
        axios.get(hostSigas() + "/api/Acuerdos/" +props.fecha.slice(0,-9))
            .then((res) => {
                setDocuments(res.data);
                console.log("res data", res.data)
            })
            .catch((res) => console.log(res));
    }, [props.fecha],[props.fechaMostrar]);


    
    return (
        <div className="documentos">
            <div key={props.number}>
                <h5 align="center">Fecha de Publicación {props.fechaMostrar}</h5>
                                      
                       <div>
                          <table className="table table-sm table-bordered" style={{ fontSize: 'small' }}>
                                <thead style={{ backgroundColor: '#832347', color: 'white' }}>
                                    <tr align="center">
                                        <th width="10%">No.</th>
                                        <th align="center">Expediente</th>
                                        <th align="center">Fecha de Actuación</th>
                                        <th align="center">Síntesis</th>
                                    </tr>
                                </thead>
                                <tbody>
                                 {documents.map((item1) => (
                                    <tr id={item1.id}>
                                        <td>{ item1.consecutivo}</td>
                                        <td>{item1.expediente}</td>
                                        <td align="right">{item1.fechaActuacion}</td>
                                        <td >{item1.sintesis}</td>
                                                
                                        </tr>
                                    ))}
                                </tbody>
                                    
                            </table>
                        </div> 
                       
                   
                
            </div>
        </div>
    );
}

export default Acuerdos;