import React, { useEffect } from 'react';
import { useConstructor } from '../../Utils';

export function OtrosInformes  (props) { 
    useConstructor(() => props.setTitle("Otros Informes"));

    

    useEffect(() => {
        
    }, [])

    

   
    return (
        <div>
            {/*<div className="flex-container">*/}
            {/*    <Documentos number="FA-0170"></Documentos>*/}
            {/*</div>*/}

      </div>
    );
  
}
