import React, { useState, useEffect } from "react";
import { useConstructor } from '../Utils';
import axios from "axios";
import { host } from '../Utils.js';

const Folder = ({ keyboard, setKeyboard, folderName }) => {
    let [currentName, setCurrentName] = useState("");

    useConstructor(()=>{
        setCurrentName(folderName);
    })

    const handleFormSearch = (e) => {
        e.preventDefault();
        console.log(currentName, folderName)
        setKeyboard(e.target.value.toLowerCase());
    };
    
    return (
        <div className="search-docs-bar">
            <input
                type="Search"
                placeholder="Buscar..."
                value={keyboard}
                onChange={handleFormSearch}
                className="form-control mb-4"
            />
        </div>
    );
};

const Documentos = (props) => {
    const [keyboard, setKeyboard] = useState("");
    const [documents, setDocuments] = useState([]);
    console.log("props documentos", props)
    

    useEffect(() => {
        axios.get(host() + "/Publico/VersionDocumentos/" + props.number)
            .then((res) => {
                setDocuments(res.data);
                console.log("res data", res.data)
            })
            .catch((res) => console.log(res));
    }, [props.number]);


    const search = (keyboard) => (c) => {
        if (c.Anotaciones === c.number) {
            return c.Anotaciones.toLowerCase().includes(keyboard);
        } else {
            return c.Anotaciones.toLowerCase().includes(keyboard);
        }
    };

    const colorSet = (event) => {
        let item = event.target;
        if (item.tagName === "LI") {
            colorRemoveElsewere(item);
            item.className = "list-group-item list-group-item-info";
        }
    }

    const colorRemove = (event) => {
        let item = event.target;
        if (item.tagName === "LI") {
            item.className = "list-group-item";
            colorRemoveElsewere(item);
        } else {
            item = item.parentNode;
            item.className = "list-group-item";
            colorRemoveElsewere(item);
        }
    }
    
    const colorRemoveElsewere = (item) => {
        let childNodes = item.parentNode.childNodes;
        let childArray = [...childNodes];

        childArray.forEach(element => {
            if (element.tagName === "LI") {
                element.className = "list-group-item";
            }
        });
    }

    const sortDocs = (a, b) => {
        var _a = a[props.orderBy] ? a[props.orderBy] : "_";
        var _b = b[props.orderBy] ? b[props.orderBy] : "_";

        //console.log("orderBy", orderBy,a, a["Seccion"]);
        if (props.order === "ASC" || !props.order) {
            return _a.localeCompare(_b);
        } else {        
            return _b.localeCompare(_a);
        }
    }

    const openInNewTab = (name) => {
        axios.get(host() + `/api/File/VistaPrevia/${name}`)
            .then((x) => {
                console.log("Download x", x.data.blockBlob.uri + x.data.sharedPolicy);
                var array = name.split('.');
                var link = document.createElement('a');
                link.download = x.data.blockBlob.metadata.originalName;
                link.href = x.data.blockBlob.uri + x.data.sharedPolicy;
                link.href = "https://docs.google.com/viewerng/viewer?url=" + encodeURIComponent(x.data.blockBlob.uri + x.data.sharedPolicy);
                link.target = "_blank";
                link.name = name;
                document.body.appendChild(link);
                link.click();
            }).catch((err) => console.log(err.message));
    }

    const searchAno = (anoSelect) => (c) => {
        if (props.number != "FA-0216") {
            return c
        } else {
            return c.Anotaciones.toLowerCase().includes(anoSelect)
        }
    }

    const testDocument = (id) => {
       return window.open(host() + "/Publico/Documento/" + id, "_blank")
    }

    return (
        <div className="documentos">
            <div key={props.number}>
                <h2>
                    {props.name}
                    {""}
                </h2>
                <span style={{ fontSize: "12px" }}>({props.number})</span>
                <Folder keyboard={keyboard} setKeyboard={setKeyboard} folderName={props.name} />
                <ul className="list-group">
                    {console.log("Documentos -->", documents)}
                    {documents.sort(sortDocs).filter(search(keyboard)).filter(searchAno(props.anoSelected)).map((item) => (
                        <li
                            className="list-group-item hover"
                            onMouseEnter={colorSet}
                            onMouseLeave={colorRemove}
                            key={item.Id}
                            style={{ textAlign: "left" }}
                            href={host() + "/Publico/Documento/" + item.Id}
                        >
                            <button
                                className="btn btn-xs btn-link pull-right"
                                style={{ display: props.extra === "true" ? "none" : "show" }}
                                onClick={() => openInNewTab(item.NombreDocumento)}
                            >
                                Visualizar
                            </button>
                            <a
                                type="link"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-xs btn-link pull-right"
                                style={{ display: props.extra === "true" ? "show" : "none" }}
                                href={host() + "/Publico/Documento/" + item.Id + "?documentoNo=2"}
                            >
                                {item.Etiqueta2 ? item.Etiqueta2 : item.Extension2}
                            </a>
                            <a
                                type="link"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-xs btn-link pull-right"
                                style={{ display: props.extra === "true" ? "show" : "none" }}
                                href={host() + "/Publico/Documento/" + item.Id}
                            >
                                {item.Etiqueta ? item.Etiqueta : item.Extension}
                            </a>
                            <span className="pull-right" style={{ marginRight: "20px", display: props.detail === "true" ? "show" : "none" }}>{item.Seccion}</span>
                            {item.Anotaciones}
                            </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default Documentos;