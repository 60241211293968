import React, {  useState, useEffect } from 'react';
import axios from 'axios';
import { host } from '../Utils';


export default function NotasRecientes() {
    
    const [notas, setNotas] = useState([]);

    useEffect(()=>{
        
        axios.get(host() + "/api/Notas/Top/3")
            .then(res => {
                if (res.data.length > 0) {
                    fillNotas(res.data)
                }
            }).catch(x => console.log(x))
        
    }, []);
    

    const fillNotas = (_notas) => {  
        setNotas(_notas);
    }
    if (notas.length > 0)
        return (
            <div>
            <h2 style={{ textAlign: "center", margin: 40, color: "#6a6a6a" }}>
                    <b className="font-title">LO MÁS RECIENTE / NOTICIAS</b>
                </h2>
                <div id="notas" className="flex-container" style={{ justifyContent: "space-evenly" }}>

                    {notas.map((nota) =>
                        <div key={nota.id} className="home-nota-content">
                        <span className="nota-title" title={nota.titulo.toUpperCase()}>
                            {nota.titulo.toUpperCase()}
                    </span>
                    <span>
                            Por: <b> {nota.autor} </b> · {nota.publicarDespuesDe}.
                    </span>
                    <span style={{ backgroundColor: '#832347', height: 8, width: 100, borderRadius: 5, margin: '20px 0 20px' }}></span>
                    <div style={{ textAlign: 'justify', lineHeight:1.3, fontSize: '1.2rem' }}>
                            {nota.contenido.substring(0, 400)}...
                                <a href={"/difusion/nota?nota=" + nota.id} className="btn btn-link no-decor"
                                style={{ color: "#832347", fontSize: "1.2rem", marginTop:-6 }}>
                                Seguir Leyendo <i className="bi bi-arrow-right-circle"></i>
                            </a>
                    </div>
                </div> 
                )}               
            </div>
</div>
        );
    else
        return (<div></div>);

}
