import React, { useEffect, useState } from "react";
import { host, useConstructor } from "../../Utils";
import axios from 'axios';

import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

export function InformeActividades(props) {
  useConstructor(() => props.setTitle("cargando..."));

  const [numPages, setNumpages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

    const [innerSize, setInnerSize] = useState(0);
    const [documentoId, setDocumentoId] = useState("");

  useEffect(() => {
    let widthSize = window.innerWidth;
    console.log(widthSize);
      setInnerSize(widthSize);

      axios.get(host() + "/Publico/Discover/6113").then(res => {
          console.log(res.data)
          props.setTitle(res.data.NombreDocumento)
          setDocumentoId(res.data.NumeroCarpeta+": "+res.data.Id)
      })

  }, [setInnerSize]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumpages(numPages);
    }

    let prevPage = () => {
     setPageNumber(pageNumber === 2 ? pageNumber - 1 : pageNumber - 2)
    }

    let nextPage = () => {
        let val = 0;
        if ((pageNumber ===1) || (pageNumber + 2 > numPages))
            val = 1;
        else
            val = 2;
        setPageNumber(pageNumber + val)
    }

   


    return (
        <div>
            <div className="flex-container" style={{ justifyContent: "center" }}>
          <div className="justify-content-center">
            {innerSize >= 480 ? (
              <>
                <Document
                  renderMode="png"
                  className="p-3 border bg-light"
                                //file={host() + "/Publico/Documento/5446"}
                                file={host() + "/Publico/Documento/6113"}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                                <Page pageIndex={1} fixed pageNumber={pageNumber}></Page>
                                {pageNumber !== 1 ? pageNumber !== numPages? <Page fixed pageNumber={pageNumber + 1}></Page> : '':''}
                </Document>
                <div className="justify-content-center d-flex btn-pages">
                                <div style={{ marginTop: "10px" }}>
                                    <button style={pageNumber === 1 ? { display: "none" } : {}}
                                        onClick={prevPage}
                      className="btn-outlined btn-sm btn-page"
                    >
                      <i className="bi bi-arrow-left"></i>
                    </button>
                                    {pageNumber} / {numPages}{" "}
                                    <button style={pageNumber === numPages ? { display: "none" } : {}}
                                        onClick={nextPage}
                      className="btn-outlined btn-sm btn-page"
                    >
                      <i className="bi bi-arrow-right"></i>
                                    </button>
                  </div>

                            </div>
                            <div className="text-center"> <a href={host() + "/Publico/Documento/6113"}>Descargar Informe de Actividades 2022-2023</a></div>
              </>
            ) : (
              <>
                                <Document
                                    
                  renderMode="canvas"
                  className="p-3 border bg-light"
                                    file={host() + "/Publico/Documento/6113"}
                  onLoadSuccess={onDocumentLoadSuccess}
                                >
                                    <Page width={innerSize} pageIndex={1} fixed pageNumber={pageNumber}></Page>
                </Document>
                <div className="justify-content-center d-flex btn-pages">
                  <div style={{ marginTop: "10px" }}>
                   <button style={pageNumber === 1 ? { display: "none" } : {}}
                      onClick={() => setPageNumber(pageNumber - 1)}
                      className="btn-outlined btn-sm btn-page"
                    >
                      <i className="bi bi-arrow-left"></i>
                    </button>
                    {pageNumber} / {numPages}{" "}
                    <button style={pageNumber === numPages ? { display: "none" } : {}}
                      onClick={() => setPageNumber(pageNumber + 1)}
                      className="btn-outlined btn-sm btn-page"
                    >
                      <i className="bi bi-arrow-right"></i>
                    </button>
                  </div>
                </div>
            <div className="text-center"> <a href={host() + "/Publico/Documento/6113"}>Descargar Informe de Actividades 2022-2023</a></div>
              </>
                    )}

          </div>
            </div>
            <div style={{ textAlign: "right", color: "#c0c0c0", fontSize: "12px" }}>{documentoId}</div>
      </div>
    );
  
}
