import axios from "axios";
import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { host, useConstructor } from "../../Utils";
import './Directorio.css'

export function Directorio(props) {
  const [empleados, setEmpleados] = useState({});
  const [cargando, setCargando] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(host() + "/Publico/DirectorioEmpleadosPagina");
        const data = res.data.reduce((acc, user) => {
          const department = user.DepartamentoName;
          if (!acc[department]) {
            acc[department] = [];
          }
          acc[department].push(user);
          return acc;
        }, {});
        setEmpleados(data);
        setCargando(false);
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchData();
  }, []);

  if (cargando) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="directorio">
      {Object.entries(empleados).map(([department, users]) => (
        <div key={department}>
            <h2 style={{marginTop:'20px', color: "#6a6a6a" }}>
                   <b className="font-title">{department}</b>
               </h2>
               <div style={{overflowX:'auto'}}>

          <table className="table table-hover table-borderless">
            <thead >
              <tr>
                <th style={{fontWeight:'bolder'}}>NOMBRE</th>
                <th style={{fontWeight:'bolder'}}>TELÉFONO / EXT.</th>
                <th style={{fontWeight:'bolder'}}>CORREO ELECTRÓNICO</th>
                {/* Agrega más columnas según las propiedades que desees mostrar */}
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td className="nombre">
                    <div style={{display:'flex', flexDirection:'column'}}>
                    <h4 style={{marginBottom:'-10px'}}>
                    <strong style={{fontSize:'20px'}}>
                    {user.DisplayName}
                    </strong>
                    </h4>
                    <p>
                    {user.Puesto}
                    </p>
                    </div>
                    </td>
                  <td>{user.NumeroExtension}</td>
                  <td>{user.Correo}</td>
                  {/* Agrega más celdas según las propiedades que desees mostrar */}
                </tr>
              ))}
            </tbody>
          </table>
               </div>
        </div>
      ))}
    </div>
  );
}
