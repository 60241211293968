import React, { Component } from 'react';
import './TipoInformes.css';
import Select from 'react-select';
import axios from 'axios';
import { hostSigas } from '../../Utils';

const formatOptionLabel = ({ value, label, customAbbreviation }) => {
    if (label === "Individual") return <div>Informes Individuales</div>;
    if (label === "de Resultados") return <div>Informes de Resultados</div>;
    if (label === "Federal") return <div>Resumenes Federales</div>;
    if (label === "General") return <div>Complementarios</div>;
    if (label === "Externo") return <div>Informes de Despachos Externos</div>;

    return (<div>
        <div>{label}</div>
    </div>)
};

class TipoInformes extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            tipoInformes: []        
        };
    }

    componentDidMount() {
        axios.get(hostSigas() + `/api/TipoInformes`).then(x => {
            x.data.shift();
           
            let options = x.data;//.filter(x => x.nombre !== "Ciudadano")
            console.log(options)
             options = options.map(e => {
               // if (e.nombre != "Ciudadano")
                return {
                    value: e.id,
                    label: e.nombre
                }
            });
            this.setState({
                tipoInformes: options
            });
        }).catch(x => console.log(x));

        
    }

    tipoInformesSelected(selected) {
        this.props.callback({
            tipoInformesId: selected.value,
            tipoInformesLabel: selected.label,
            informes: [],
            fondosFederales: [],
            ejercicio_selected:'',
            message: ""
        })
        this.props.unselect();

        //console.log("tipoInformes selected",selected)
    }
    render() {
        let result =
            (<div id="tipoEntes-contaienr">
                <label id="tipoEntes-label">Tipo de Informes</label>
                <Select placeholder="Seleccione Tipo de Informes"
                    options={this.state.tipoInformes}
                    formatOptionLabel={formatOptionLabel}
                    onChange={this.tipoInformesSelected.bind(this)} />
                <br />
            </div>);
        
        if(this.props.hide)
            return <div></div>

        return result;
    }
}

export default TipoInformes;
