import axios from 'axios';
import { Nota } from './Nota';
import React, { useEffect, useState } from 'react';
import { host, useConstructor } from '../../Utils';
import {Input} from 'reactstrap'
export function Notas  (props) { 
    
    const [notas, setNotas] = useState([]);    
    const [busquedaVacia, setBusquedaVacia] = useState(false);  
    
    useConstructor(() =>{ 
        axios.get(host() + "/api/Notas/Top/100")
        .then(res => {
            //console.log(activeTab === "6")
            if(res.data.length > 0){
                fillNotas(res.data)
            }
        }).catch(x => console.log(x))
        
        
        props.setTitle("Notas Informativas")
    });
    const fillNotas = (_notas) => {
        setNotas(_notas);
    }
    useEffect(() => {
        
    }, [])
  const search=(x)=> {
      console.log(x)
    axios.post(
        host() + "/api/Notas/Search/",
    {
      Texto: x  
    }
    ).then(res => {        
            fillNotas(res.data)
            setBusquedaVacia(res.data.length===0)
        
    }).catch(x => console.log(x))
   }
    return (
        <div>
            
            <div className='flex-container reverse'>
                <Input placeholder='Buscar...' className='w-250' onChange={(e)=>{search(e.target.value)}}></Input>
                </div>
            <div className="mt-20">
            {notas.map(x => 
                                    <li className="list-group-item" key={x.id}>
                                        <a href={"/difusion/nota?nota="+x.id}>{x.titulo} </a>
                                        <small className="text-muted">({x.publicarDespuesDe})</small>
                                    </li>                               
                                )}
            </div>
            
            { busquedaVacia? <h2 className='center'>Sin información para mostrar</h2> :''}

      </div>
    );
  
}
