import React, { useEffect, useState } from 'react';
import { useConstructor, host } from '../../Utils';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser'
import IFrame from 'react-iframe'

export function Entrada  (props) { 


    const [entradaId, setEntradaId] = useState(0);
    const [entrada, setEntrada] = useState('');
    const [imagenDisplay, setImagenDisplay] = useState('');

    useConstructor(() => {         
        setEntradaId(props.id)

        const urlparams = new URLSearchParams(window.location.search);
        const urlEntradaId = urlparams.get('entrada');

        if(urlEntradaId){
        setEntradaId(urlEntradaId)

        
    }

    });
    const limpiarTexto = (texto)=> {
        if(texto){
        texto = texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        texto = texto.replace(/[¿¡?!]/g, "");
        texto = texto.replace(/\s+/g, "_");
        return texto;
    }else{
        return "";
    }
      }

      const Navegar =()=>{
        window.open('/institucion/analisis-de-coyuntura/share?entrada='+entradaId+'#'+limpiarTexto(entrada.titulo))
      }
    useEffect(() => {
        if (entradaId !== 0) {

            axios.get(host() + "/api/EntradasBlog/EntradaBlog/" + entradaId)
                .then(res => {
                    setEntrada(res.data)
                    if (res.data.imagen) {
                        axios.get(host() +'/api/File/Download/' + res.data.imagen)
                            .then((x) => {
                                setImagenDisplay(x.data.blockBlob.uri + x.data.sharedPolicy)
                            }).catch(x=> console.log(x))
                    }
                }).catch(x => console.log(x))              
        } else {
           
        }        
    }, [entradaId])
   
    return (
        <div>
            <div style={{ display: entradaId? 'flex':"none", flexDirection: 'column' }}>
                <span style={{display:entrada.mapa?"none":'inherit'}} id={limpiarTexto(entrada.titulo)} onClick={Navegar} className='blog-title pointer'>{entrada.titulo}</span>
               
                <span style={{display:entrada.mapa?"none":'inherit'}} className="blog-autor">{entrada.autor + " • " + entrada.publicarDespuesDe}</span>
                <div className='blog-group'>
                    <div className='blog-content'>
                        {ReactHtmlParser(entrada.contenido)}
                    </div>
                    {imagenDisplay && !entrada.mapa?
                        <div className="center flex-column">
                            <img style={{maxWidth:'100%'}} className='blog-imagen' src={imagenDisplay} alt="img" />
                            <span className="blog-imagen-desc">{entrada.slug}</span>
                        </div> : ''}
                    {entrada.mapa ?
                        entrada.mapa.toLowerCase().includes('iframe') ?
                            ReactHtmlParser(entrada.mapa)
                            :
                            <IFrame url={entrada.mapa}
                                width="100%"
                                height="1000px"
                                id=""
                                className=""
                                display="block"
                                position="relative" />
                        :
                        ""
                    }
                </div>
                <hr style={{display:entrada.mapa?"none":'inherit'}} className='blog-separator' />
            </div>
        </div>
    );

}
