import React, { useState } from 'react';
import { SolicitudDeInformacionForm } from './SolicitudDeInformacion-form';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { PreguntasFrecuentes } from '../institucion/PreguntasFrecuentes';
import { UnidadDeTransparencia } from './UnidadDeTransparencia';
export function SolicitudDeInformacion(args) {
  //useConstructor(() => props.setTitle("Solicitud de Información"));
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);


  return (
    <>
      <div className='d-flex mb-3' style={{ justifyContent: 'flex-end' }}>
        <Button style={{backgroundColor:'#832347'}} onClick={toggle}>
          Preguntas Frecuentes
        </Button>
        <Modal isOpen={modal} toggle={toggle} size="xl" {...args}>
          <ModalHeader toggle={toggle}>Preguntas Frecuentes</ModalHeader>
          <ModalBody>
            <PreguntasFrecuentes />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>
              Cerrar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <div className="Container">
        <p className="text-center text-bold title-extracto">
          Formato que permite gestionar una solicitud de acceso a la información
          por parte de la ciudadanía.
        </p>
        <div>
          <span className="text-center">
            Con fundamento en el Artículo 117 de la Ley de Transparencia y
            Acceso a la Información Pública del Estado de Sonora, ponemos a su
            disposición el siguiente formato para dar seguimiento a las
            solicitudes de acceso a la información.
          </span>
          <SolicitudDeInformacionForm />
        </div>
      </div>
      <div className='Container p-5'>
        <UnidadDeTransparencia/>
      </div>
    </>
  );
}

