import React from 'react';


import ASF from "../images/iconos/sitios/LogoASF.png"
import ASOFIS from "../images/iconos/sitios/LogoASOFIS.png"
import CONAC from "../images/iconos/sitios/LogoCONAC.png"
import CONGRESO from "../images/iconos/sitios/LogoCongreso2.png"
import LEGISLATURA from "../images/iconos/sitios/LogoCongreso1.png"
import GOBIERNO from "../images/iconos/sitios/LogoGobierno.png"
import INTOSAI from "../images/iconos/sitios/LogoINTOSAI.png"
import PNT from "../images/iconos/sitios/LogoPNT.png"

export default function SitiosDeInteres() {
       return (
            <div>
              
               <h2 style={{ textAlign: "center", margin: 40, color: "#6a6a6a" }}>
                   <b className="font-title">SITIOS DE INTERÉS</b>
               </h2>
               <div id="sitiosDeInteres">
                   <span>
                       <a title='ASF' href="https://www.asf.gob.mx/"><img alt='' src={ASF} /></a>
                   </span>
                   <span>
                       <a title='ASOFIS' href="https://www.asf.gob.mx/Section/57_Ambito_Nacional"> <img alt='' src={ASOFIS} /></a>
                   </span>
                   <span>
                       <a title='CONAC' href="https://www.conac.gob.mx/"><img alt='' src={CONAC} /></a>
                   </span>
                   <span>
                       <a title='Congreso del Estado de Sonora' href="http://www.congresoson.gob.mx/"><img alt='' src={CONGRESO} /></a>
                   </span>
                   <span>
                       <a title='LXIII Legislatura' href="http://congresoson.gob.mx/Legislatura/LegislaturaActual"><img alt='' src={LEGISLATURA} /></a>
                   </span>
                   <span>
                       <a title='Gobierno del Estado de Sonora' href="https://sonora.gob.mx/"><img alt='' src={GOBIERNO} /></a>
                   </span>
                   <span>
                       <a title='INTOSAI' href="https://www.intosai.org/es/"><img alt='' src={INTOSAI} /></a>
                   </span>
                   <span>
                       <a title='PNT' href="https://www.plataformadetransparencia.org.mx/"><img alt='' src={PNT} /></a>
                   </span>
               </div>

</div>
        );
    

}
