import React, { Component } from "react";
import axios from "axios";
import { hostSigas, smallScreen } from "../../Utils";
import TipoInformes from "../../components/informes/TipoInformes";
import Ejercicios from "../../components/informes/Ejercicios";
import Ambitos from "../../components/informes/Ambitos";
import TipoEntes from "../../components/informes/TipoEnte";
import Entes from "../../components/informes/Entes";
import FondosFederales from "../../components/informes/FondosFederales";
import Informes from "../../components/informes/Informes";
import {Link} from "react-router-dom";
import ConsultaObservaciones from "../../images/cuenta-publica/ConsultaObservaciones.png";
import CuentasAnteriores from "../../images/cuenta-publica/CuentasAnteriores.png";
import InformesIndividuales from "../../images/cuenta-publica/InformesIndividuales.png";
import InformesResultados from "../../images/cuenta-publica/InformesResultados.png";
import CuentaBg from "../../images/cuenta-publica/Cuenta-bg.png";

class CuentasPublicasAnteriores extends Component {
  constructor(props) {
    props.setTitle("Cuentas Públicas");
    super();

    var state = {
      hideTipoInforme: false,
      ejercicio: {},
      tipoEntes: [],
      entes: [],
      fondosFederales: [],
      enteName: "",
      informes: [],
      message: "",
      selectedTipoEnte: "",
      tipoInformesLabel: "",
      tipoInformesId: "",
    };

    if (props.tipoInforme !== "Cuenta") {
      axios
        .get(hostSigas() + `/api/TipoInformesNombre/${props.tipoInforme}`)
        .then((x) => {
          //console.log(x.data);
          this.setState({
            hideTipoInforme: true,
            tipoInformesLabel: x.data.nombre,
            tipoInformesId: x.data.id,
          });
        })
        .catch((x) => console.log(x));
    }

    this.state = state;
  }
  execute(data) {
    this.setState(data);
  }
  unselect() {
    this.setState({
      entes: this.state.entes.map((x) => {
        x.selected = false;
        return x;
      }),
    });
  }
  render() {
    return (
      <div className="container-fluid">
      



        <div className="flex-container">
          <div className="col-md-4">
            <TipoInformes
              hide={this.state.hideTipoInforme}
              callback={this.execute.bind(this)}
              unselect={this.unselect.bind(this)}
              tipoInformeHtml={this.props.tipoInforme}
            />

            <Ejercicios
              callback={this.execute.bind(this)}
              unselect={this.unselect.bind(this)}
              tipoInforme={this.props.tipoInforme}
              tipoInformeName={this.state.tipoInformesLabel}
            />
            <p>{this.state.ejercicio_selected}</p>
            <Ambitos
              callback={this.execute.bind(this)}
              ejercicio={this.state.ejercicio}
              tipoInforme={this.state.tipoInformesLabel}
            />

            <TipoEntes
              callback={this.execute.bind(this)}
              selectedTipoEnte={this.state.selectedTipoEnte}
              ejercicio={this.state.ejercicio}
              tipoEntes={this.state.tipoEntes}
              tipoInforme={this.state.tipoInformesLabel}
            />
          </div>
          <div className="col-md-4" >
            <Entes
              callback={this.execute.bind(this)}
              entes={this.state.entes}
              ejercicio={this.state.ejercicio}
              ambito={this.state.ambito}
              tipoInforme={this.state.tipoInformesLabel}
            />

            <FondosFederales
              callback={this.execute.bind(this)}
              fondosFederales={this.state.fondosFederales}
              tipoInformeSelected={this.state.tipoInformesSelected}
              ejercicio={this.state.ejercicio}
              tipoInforme={this.props.tipoInforme}
              tipoInformeName={this.state.tipoInformesLabel}
            />
          </div>
          <div className="col-md-4">
            <Informes
              message={this.state.message}
              informes={this.state.informes}
              enteName={this.state.enteName}
            />
          </div>
        </div>
        <div className="card-group">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Informes anteriores al 2017</h5>
              <p className="card-text">Listado de informes de resultado de las cuentas publicas estatales, anteriores al 2017.</p>
            </div>
            <ul className="list-group list-group-flush">
            <li className="list-group-item"><Link className="link-primary" to="/auditorias/informes-anteriores-estatales"> Ver informes estatales </Link></li>
            <li className="list-group-item"><Link className="link-primary" to="/auditorias/informes-anteriores-municipales"> Ver informes municipales </Link></li>
            </ul>
          </div>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Informes generales municipal</h5>
              <p className="card-text">Listado de informes generales de resultados de las cuentas públicas municipales, anteriores al 2017.</p>
            </div>
            <ul className="list-group list-group-flush">
              <li className="list-group-item"><a className="link-primary" href="https://intranet.isaf.gob.mx/Publico/Documento/3163" rel="noreferrer" target="_blank">Ver informe general del 2017</a></li>
              <li className="list-group-item"><a className="link-primary" href="https://intranet.isaf.gob.mx/Publico/Documento/3676" rel="noreferrer" target="_blank">Ver informe general del 2018</a></li>
            </ul>
          </div>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Propuesta de calificación</h5>
              <p className="card-text">Propuesta de calificación a las Cuentas Públicas municipales 2018.</p>
            </div>
            <ul className="list-group list-group-flush">
            <li className="list-group-item"><a className="link-primary" href="https://intranet.isaf.gob.mx/Publico/Documento/3677" rel="noreferrer" target="_blank">Ver propuesta 2018</a></li>
            </ul>
          </div>
         
        </div>
      </div>
    );
  }
}

export default CuentasPublicasAnteriores;
