import React from 'react';
import { useConstructor } from '../../Utils';

import { CardTitle, CardText, Card, CardBody } from 'reactstrap';


import pai1 from '../../images/transparencia/1pai.svg';
import pai2 from '../../images/transparencia/2pai.svg';
import pai3 from '../../images/transparencia/3pai.svg';
import pai4 from '../../images/transparencia/4pai.svg';
import pai5 from '../../images/transparencia/5pai.svg';
import pai6 from '../../images/transparencia/6pai.svg';
import pai7 from '../../images/transparencia/7pai.svg';

export function ProcedimientoDeAcceso  (props) { 
    useConstructor(() => props.setTitle("Procedimiento De Acceso a la Información"));





        return (
            <div>
                <div className="flex-container">
                    <div className='flex-container' style={{ justifyContent: "space-evenly" }}>
                        <Card style={{ maxWidth: '600px', marginBottom: "25px" }}>
                            <CardBody>
                                <CardTitle style={{ display: 'flex', alignItems: 'center' }}>
                                    <span className={'x4 bg-primary'} style={{ color: 'white', padding: "0px 15px 0px 15px", margin: "10px 10px 0 0", borderRadius: "10px" }}>1</span>
                                    <span className={"x2"}>El solicitante</span>
                                </CardTitle>
                                <CardText style={{ display: 'flex', justifyContent: "center" }}>

                                    <p style={{ marginLeft: 30, fontSize: '1rem' }}>
                                        <img alt="El Solicitante"
                                            src={pai1}
                                            style={{ float: 'left', width:'10rem', padding:10 }}
                                        />
                                        presenta su solicitud por el medio que considere apropiado,
                                        ante la Unidad de Transparencia. La solicitud de información podrá efectuarse en
                                        forma escrita o verbal manera personal o telefónicamente,  mediante un correo
                                        electrónico y/o la Plataforma Nacional de Transparencia.
                                    </p>
                                </CardText>
                            </CardBody>
                        </Card>
                        <Card style={{ maxWidth: '600px', marginBottom: "25px" }}>
                            <CardBody>
                                <CardTitle style={{ display: 'flex', alignItems: 'center' }}>
                                    <span className={'x4 bg-primary'} style={{ color: 'white', padding: "0px 15px 0px 15px", margin: "10px 10px 0 0", borderRadius: "10px" }}>2</span>
                                    <span className={"x2"}>Se recibirán</span>
                                </CardTitle>
                                <CardText style={{ display: 'flex', justifyContent: "center" }}>

                                    <p style={{ marginLeft: 30 }}>
                                        <img alt="Se Recibirán" src={pai2}  style={{ float: 'left', width:'10rem', padding:10 }} />
                                        las solicitudes únicamente en días y horas hábiles, considerados para tal efecto,
                                        los días de Lunes a Viernes, con horario de atención de las 8:00 a las 15:00 horas.
                                        Las solicitudes que se presenten en días inhábiles y fuera del horario señalado,
                                        se registrarán al día siguiente inmediato hábil.
                                    </p>
                                </CardText>
                            </CardBody>
                        </Card>
                        <Card style={{ maxWidth: '600px', marginBottom: "25px" }}>
                            <CardBody>
                                <CardTitle style={{ display: 'flex', alignItems: 'center' }}>
                                    <span className={'x4 bg-primary'} style={{ color: 'white', padding: "0px 15px 0px 15px", margin: "10px 10px 0 0", borderRadius: "10px" }}>3</span>
                                    <span className={"x2"}> La solicitud</span>
                                </CardTitle>
                                <CardText style={{ display: 'flex', justifyContent: "center" }}>

                                    <p style={{ marginLeft: 30 }}>
                                        <img alt="La Solicitud" src={pai3}  style={{ float: 'left', width:'10rem', padding:10 }} />
                                        de información pública deberá reunir los datos estipulados en el artículo 120 de la
                                        Ley de Transparencia y Acceso a la Información Pública del Estado de Sonora:<br />
                                        <i className="bi bi-dot"></i>Nombre o, en su caso, los datos generales de su representante (no obligatorio)<br />
                                        <i className="bi bi-dot"></i>Domicilio o medio para recibir notificaciones<br />
                                        <i className="bi bi-dot"></i>La descripción de la información solicitada<br />
                                        <i className="bi bi-dot"></i>Cualquier otro dato que facilite su búsqueda y eventual localización (no obligatorio)<br />
                                        <i className="bi bi-dot"></i>La modalidad en la que prefiere se otorgue el acceso a la información, la cual podrá ser verbal,
                                        siempre y cuando sea para fines de orientación, mediante consulta directa,
                                        mediante la expedición de copias simples o certificadas o la reproducción en cualquier otro medio, incluidos los electrónicos.
                                    </p>
                                </CardText>
                            </CardBody>
                        </Card>
                        <Card style={{ maxWidth: '600px', marginBottom: "25px" }}>
                            <CardBody>
                                <CardTitle style={{ display: 'flex', alignItems: 'center' }}>
                                    <span className={'x4 bg-primary'} style={{ color: 'white', padding: "0px 15px 0px 15px", margin: "10px 10px 0 0", borderRadius: "10px" }}>4</span>
                                    <span className={"x2"}>Aceptada</span>
                                </CardTitle>
                                <CardText style={{ display: 'flex', justifyContent: "center" }}>
                                    <p style={{ marginLeft: 30 }}>
                                        <img alt="Aceptada" src={pai4}  style={{ float: 'left', width:'10rem', padding:10 }} />
                                        la solicitud de información la unidad de transparencia registrará en un acta
                                        o formato electrónico los detalles de la solicitud y para el caso de presentación en
                                        forma escrita, procederá a entregar una copia de la misma al solicitante, con número
                                        de folio, el que se entregará para todos los casos de solicitudes a fin de dar seguimiento a la petición.
                                    </p>
                                </CardText>
                            </CardBody>
                        </Card>
                        <Card style={{ maxWidth: '600px', marginBottom: "25px" }}>
                            <CardBody>
                                <CardTitle style={{ display: 'flex', alignItems: 'center' }}>
                                    <span className={'x4 bg-primary'} style={{ color: 'white', padding: "0px 15px 0px 15px", margin: "10px 10px 0 0", borderRadius: "10px" }}>5</span>
                                    <span className={"x2"}>La unidad de transparencia</span>
                                </CardTitle>
                                <CardText style={{ display: 'flex', justifyContent: "center" }}>

                                    <p style={{ marginLeft: 30 }}>
                                        <img alt="Transparencia" src={pai5} style={{ float: 'left', width:'10rem', padding:10 }} />
                                        mediante memorándum notificara a la unidad administrativa correspondiente de generar la información solicitada.
                                    </p>
                                </CardText>
                            </CardBody>
                        </Card>
                        <Card style={{ maxWidth: '600px', marginBottom: "25px" }}>
                            <CardBody>
                                <CardTitle style={{ display: 'flex', alignItems: 'center' }}>
                                    <span className={'x4 bg-primary'} style={{ color: 'white', padding: "0px 15px 0px 15px", margin: "10px 10px 0 0", borderRadius: "10px" }}>6</span>
                                    <span className={"x2"}>El plazo</span>
                                </CardTitle>
                                <CardText style={{ display: 'flex', justifyContent: "center" }}>

                                    <p style={{ marginLeft: 30 }}>
                                        <img alt="El plazo" src={pai6} style={{ float: 'left', width:'10rem', padding:10 }} />
                                        para la respuesta  al solicitante será de 15 días hábiles después de aceptada la solicitud.
                                    </p>
                                </CardText>
                            </CardBody>
                        </Card>
                        <Card style={{ maxWidth: '600px', marginBottom: "25px" }}>
                            <CardBody>
                                <CardTitle style={{ display: 'flex', alignItems: 'center' }}>
                                    <span className={'x4 bg-primary'} style={{ color: 'white', padding: "0px 15px 0px 15px", margin: "10px 10px 0 0", borderRadius: "10px" }}>7</span>
                                    <span className={"x2"}>Para el caso</span>
                                </CardTitle>
                                <CardText style={{ display: 'flex', justifyContent: "center" }}>

                                    <p style={{ marginLeft: 30 }}>
                                        <img alt="para el caso" src={pai7} style={{ float: 'left', width:'10rem', padding:10 }} />
                                        que la unidad de transparencia no tenga la información solicitada
                                        por no ser competencia de la misma, se notificará al interesado la resolución
                                        conducente y se turnará la solicitud a la Unidad de Transparencia que considere competente.
                                    </p>
                                </CardText>
                            </CardBody>
                        </Card>
                    </div>

                </div>

      </div>
    );
  
}
