import React from 'react';
import { useConstructor } from '../../Utils';
import Documentos from "../../components/Documentos";

export function ForosYEventos (props) { 
    useConstructor(() => {
        props.setTitle("Foros y Eventos")
    
    });

    return (
        <div>
            <div className="flex-container">
                <Documentos number="FA-0251"></Documentos>
            </div>

      </div>
    );
  
}
