import React, { Component } from "react";
import "./FondosFederales.css";
import Pagination from "react-js-pagination";
import { isMobile } from "react-device-detect";
import axios from "axios";
import { hostSigas } from "../../Utils";

class FondosFederales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipoInformeSelected: this.props.tipoInformeSelected,
      activePage: 1,
      searchWord: "",
      informes: [],
      message: "",
    };
  }

  fondoFederalSelected(fondoFederal) {
    this.setState({
      fondosFederales: this.props.fondosFederales.map((x) => {
        let result = x;
        if (x.value === fondoFederal.value) x.selected = true;
        else x.selected = false;

        return result;
      }),
    });
    //console.log('fondoFederal: ', fondoFederal, 'ejercicio: ', this.props.ejercicio)
    this.getInformes(fondoFederal, this.props.ejercicio);
  }

  navigateToInformes() {
    if (isMobile)
      document.getElementById("informes-container").scrollIntoView();
  }

  getInformes(fondoFederal, ejercicio) {
    if (ejercicio.value) {
      //"api/Informes/EnteEjercicio/{EnteId}/{EjercicioId}/{TipoInforme}/{Ambito}/{FondoId}"
      axios
        .get(
          hostSigas() +
            `/api/Informes/EnteEjercicio/0/${ejercicio.value}/${this.props.tipoInformeName}/${this.props.ambito}/${fondoFederal.value}`
        )
        .then((x) => {
          console.log(x.data);
          if (x.data.length < 1) {
            this.props.callback({
              informes: x.data.map((x) => {
                x.selected = false;
                return x;
              }),
              message: `No se ha cargado el Informe de ${fondoFederal.label} para el ejercicio ${ejercicio.label}`,
              fondoFederal: fondoFederal.label,
            });
          } else {
            this.props.callback({
              informes: x.data.map((x) => {
                x.selected = false;
                return x;
              }),
              message: "",
              fondoFederal: fondoFederal.label,
            });
          }

          this.navigateToInformes();
        })
        .catch((x) => console.log(x));
    } else {
      this.props.callback({
        message: "Seleccione un Ejercicio para cargar los Informes",
      });
    }
  }
  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
  }
  search(data, word) {
    //console.log(data)
    //if(data.length > 0)
    //    console.log("search", data, data[0].index, data[0].label);

    let result = data.filter((x) =>
      (x.index + x.label)
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(word.toLowerCase())
    );

    return result;
  }
  filter(data) {
    //console.log("filter",data)
    var dataSearched = this.search(data, this.state.searchWord);

    //this.setState({filtrados: dataSearched.length});

    if (this.props.fondosFederales.length <= 5) {
      return dataSearched;
    } else {
      let from = this.state.activePage * 5 - 5;
      let to = this.state.activePage * 5;
      return dataSearched.slice(from, to);
    }
  }
  handleInputCapture(e) {
    var countFiltrados = this.search(
      this.props.fondosFederales,
      e.target.value
    ).length;
    var countMostrados = countFiltrados >= 5 ? 5 : countFiltrados;
    this.setState({
      activePage: 1,
      searchWord: e.target.value,
      filtrados: countFiltrados,
      mostrados: countMostrados,
    });
  }

  render() {
    let result = (
      <div id="tipoEntes-contaienr">
        <label id="tipoEntes-label">Fondos Federales</label>
        <div className={this.props.fondosFederales.length <= 5 ? "hide" : ""}>
          <input
            type="text"
            className="form-control busqueda"
            placeholder="Buscar"
            onChange={this.handleInputCapture.bind(this)}
          />
        </div>
        <ul className="list-group">
          {this.filter(this.props.fondosFederales).map((fondoFederal) => (
            <li
              key={fondoFederal.value}
              onClick={this.fondoFederalSelected.bind(this, fondoFederal)}
              className={
                "list-group-item fondoFederal-item" +
                (fondoFederal.selected ? " active" : "")
              }
            >
              {fondoFederal.index}.- {fondoFederal.label}
            </li>
          ))}
        </ul>
        <div className={this.props.fondosFederales.length <= 5 ? "hide" : ""}>
          <div className={this.state.searchWord ? "" : "hide"}>
            Mostrando {this.state.mostrados} de {this.state.filtrados} Programas
            de un total de {this.props.fondosFederales.length}
          </div>
          <div className={this.state.searchWord ? "hide" : ""}>
            {this.props.fondosFederales.length} Programas encontrados
          </div>
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={5}
            totalItemsCount={this.props.fondosFederales.length}
            pageRangeDisplayed={3}
            onChange={this.handlePageChange.bind(this)}
          />
        </div>
      </div>
    );

    if (this.props.tipoInformeName === "Federal") return result;

    return <div></div>;
  }
}

export default FondosFederales;
