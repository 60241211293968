import React, { useEffect } from "react";
import { useConstructor } from '../../Utils';
import Documentos from "../../components/Documentos";

export function CompendioLegislativo(props) {

  useEffect(() => {}, []);

  
    return (
      <div>
         <div style={{display:'flex', justifyContent:'center'}}>
        <h1>Compendio legislativo</h1>
        </div>
        <p className="text-center text-bold title-extracto">
          Considera las principales normas, leyes y reglamentos que dirigen la
          gestión pública en el Estado, así como legislación en general.
            </p>

            <div className="text-right"> <a target="_blank" href="https://www.conac.gob.mx/es/CONAC/Normatividad_Vigente">Normatividad Vigente CONAC</a></div>
        <div className="flex-container">
          <Documentos number="FA-0163"></Documentos>
        </div>
      </div>
    );
  
}
