import React, { useState } from "react";
import ReactTable from "react-table";
import axios from "axios";
import Select from "react-select";
import { hostSigas, useConstructor } from "../../Utils";
import "./AvanceDeSolventacion.css";

export function AvanceDeSolventacion(props) {
  props.setTitle("Avance de Solventacion");
  const [observaciones, setObservaciones] = useState([]);
  const [observacionesPorSolventar, setObservacionesPorSolventar] = useState(
    []
  );
  const [tipoEntes, setTipoEntes] = useState([]);
  const [cargando, setCargando] = useState(true);
  const [tableVisible, setTableVisible] = useState(false);

  const [header, setHeader] = useState("");
  const [año, setAño] = useState("");
  const [tipoEnteSelected, setTipoEnteSelected] = useState({});

  const SelectTipos = () => (
    <div style={{ width: "400px" }}>
      <Select
        placeholder="Seleccione . . . "
        options={tipoEntes}
        onChange={(val) => {
          getData(val);
        }}
      />
    </div>
  );

  useConstructor(() => {
    axios.get(hostSigas() + "/api/tipoEnte/open").then((res) => {
      var tipos = res.data.filter(
        (x) => x.id !== 5 && x.id !== 10 && x.id !== 16 && x.id !== 18
      );

      setTipoEntes(
        tipos.map((x) => {
          x.value = x.id;
          x.label = x.nombre;
          return x;
        })
      );

      console.log("entes", tipoEntes);
    });
  });
  
  function getData(tipo) {
    setObservacionesPorSolventar([]);
    setTipoEnteSelected(tipo);
    axios
      .get(hostSigas() + "/api/Observaciones/CountPorEjercicio/" + tipo.id)
      .then((res) => {
        setObservaciones(
          res.data.map((x) => {
            x.selected = [0, 0, 0];
            return x;
          })
        );
      });
  }

  function Clicked(Año, tipo) {
    setTableVisible(true);
    setCargando(true);
    setObservaciones(
      observaciones.map((x) => {
        if (x.ejercicio === Año) {
          if (tipo === 1) x.selected = [1, 0, 0];
          if (tipo === 2) x.selected = [0, 1, 0];
          if (tipo === 3) x.selected = [0, 0, 1];
        } else {
          x.selected = [0, 0, 0];
        }
        return x;
      })
    );

    switch (tipo) {
      case 1:
        setHeader("Total de Observaciones");
        setAño(Año);
        break;
      case 2:
        setHeader("Observaciones Solventadas y Concluidas");
        setAño(Año);
        break;
      case 3:
        setHeader("Observaciones por Solventar");
        setAño(Año);
        break;
      default: break;
    }
    axios
      .get(
        hostSigas() +
          "/api/Observaciones/AppSeguimiento/" +
          Año +
          "/" +
          tipo +
          "/" +
          tipoEnteSelected.id
      )
      .then((res) => {
        setObservacionesPorSolventar(res.data);
        setCargando(false);
      });
  }

  
    return (
      <div className="sgmt-container">
        <div>
          <b>
            {" "}
            {tipoEnteSelected.id === undefined
              ? "Para comenzar, seleccione un tipo de ente:"
              : "Seleccionar tipo de ente"}{" "}
          </b>{" "}
          <SelectTipos></SelectTipos>
          <div className="sgmt-title">{tipoEnteSelected.nombre}</div>
        </div>

        <div
          className="sgmt-row-header"
          style={{ display: tipoEnteSelected.id > 0 ? "" : "none" }}
        >
          <div className="sgmt-header-ejercicio">Cuenta Pública</div>
          <div className="sgmt-header">Total de Observaciones</div>
          <div className="sgmt-header">Solventadas y Concluidas</div>
          <div className="sgmt-header">Observaciones por Solventar</div>
        </div>

        <div>
          {observaciones.map((x) => (
            <div className="sgmt-row" key={x.id}>
              <div className="sgmt-ejercicio">{x.ejercicio}</div>
              <div
                id={x.ejercicio + "1"}
                className="sgmt-numero"
                style={{
                  backgroundColor: x.selected[0] ? "#88c5ff" : "white",
                }}
                onClick={() => Clicked(x.ejercicio, 1)}
              >
                {x.total_Observaciones}
              </div>
              <div
                id={x.ejercicio + "2"}
                className="sgmt-numero"
                style={{
                  backgroundColor: x.selected[1] ? "#88c5ff" : "white",
                }}
                onClick={() => Clicked(x.ejercicio, 2)}
              >
                {x.solventadas}
              </div>
              <div
                id={x.ejercicio + "3"}
                className="sgmt-numero"
                style={{
                  backgroundColor: x.selected[2] ? "#88c5ff" : "white",
                }}
                onClick={() => Clicked(x.ejercicio, 3)}
              >
                {x.porSolventar}
              </div>
            </div>
          ))}
        </div>

        <div>
          <h2>{header + " " + año}</h2>
          <ReactTable
            data={observacionesPorSolventar}
            filterable
            style={{
              fontSize: "1.1em",
              display:
                tipoEnteSelected.id > 0 && tableVisible ? "block" : "none",
            }}
            //defaultFilterMethod={this.filterCaseInsensitive}
            loading={cargando}
            loadingText="Cargando..."
            columns={[
              {
                Header: "Ente",
                accessor: "tcc",
              },
              {
                Header: "Ente",
                accessor: "ente",
                width: 450,
              },

              {
                Header: "Financieras",
                accessor: "financieras",
              },
              {
                Header: "Presupuestales",
                accessor: "presupuestales",
              },
              {
                Header: "Gabinete",
                accessor: "gabinete",
              },
              {
                Header: "Obra Pública",
                accessor: "obraPublica",
              },
              {
                Header: header,
                accessor: "observaciones",
                style: { fontWeight: "bold", backgroundColor: "#88c5ff" },
              },
            ]}
            nextText={"Siguiente"}
            previousText={"Anterior"}
            pageText={"Página"}
            ofText={"de"}
            rowsText={"registros"}
          />
        </div>
      </div>
    );
  
}
