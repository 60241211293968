import React, { Component } from "react";
import axios from "axios";
import { hostSigas } from "../../Utils";
import Ejercicios from "../../components/informes/Ejercicios";
import Ambitos from "../../components/informes/Ambitos";
import TipoEntes from "../../components/informes/TipoEnte";
import Entes from "../../components/informes/Entes";
import FondosFederales from "../../components/informes/FondosFederales";
import Informes from "../../components/informes/Informes";

class InformesDeResultados extends Component {
  constructor(props) {
    props.setTitle("Informes De Resultados");
    super();

    var state = {
      hideTipoInforme: false,
      ejercicio: {},
      tipoEntes: [],
      entes: [],
      fondosFederales: [],
      enteName: "",
      informes: [],
      message: "",
      selectedTipoEnte: "",
      tipoInformesLabel: "",
      tipoInformesId: "",
    };

    if (props.tipoInforme !== "Cuenta") {
      axios
        .get(hostSigas() + `/api/TipoInformesNombre/${props.tipoInforme}`)
        .then((x) => {
          //console.log(x.data);
          this.setState({
            hideTipoInforme: true,
            tipoInformesLabel: x.data.nombre,
            tipoInformesId: x.data.id,
          });
        })
        .catch((x) => console.log(x));
    }

    this.state = state;
    }
    DownloadIR() {

        return window.open("https://intranet.isaf.gob.mx/Publico/Documento/6123")

    }
  execute(data) {
    this.setState(data);
  }
  unselect() {
    this.setState({
      entes: this.state.entes.map((x) => {
        x.selected = false;
        return x;
      }),
    });
  }
  render() {
    return (
      <div className="container-fluid">
        <div className="flex-container">
          <div className="col-md-3" style={{marginRight: "10px"}} >
            <Ejercicios
              callback={this.execute.bind(this)}
              unselect={this.unselect.bind(this)}
              tipoInforme={this.props.tipoInforme}
              tipoInformeName={this.state.tipoInformesLabel}
            />
            <p>{this.state.ejercicio_selected}</p>
            <Ambitos
              callback={this.execute.bind(this)}
              ejercicio={this.state.ejercicio}
              tipoInforme={this.state.tipoInformesLabel}
            />

            <TipoEntes
              callback={this.execute.bind(this)}
              selectedTipoEnte={this.state.selectedTipoEnte}
              ejercicio={this.state.ejercicio}
              tipoEntes={this.state.tipoEntes}
              tipoInforme={this.state.tipoInformesLabel}
            />
          </div>
          <div className="col-md-4" style={{marginRight: "5px"}} >
            <Entes
              callback={this.execute.bind(this)}
              entes={this.state.entes}
              ejercicio={this.state.ejercicio}
              ambito={this.state.ambito}
              tipoInforme={this.state.tipoInformesLabel}
            />

            <FondosFederales
              callback={this.execute.bind(this)}
              fondosFederales={this.state.fondosFederales}
              tipoInformeSelected={this.state.tipoInformesSelected}
              ejercicio={this.state.ejercicio}
              tipoInforme={this.props.tipoInforme}
              tipoInformeName={this.state.tipoInformesLabel}
            />
          </div>
          <div className="col-md-4">
            <Informes
              message={this.state.message}
              informes={this.state.informes}
              enteName={this.state.enteName}
            />
          </div>
            </div>
            <div className="flex-container">
                <div className="flex-container download-button" onClick={() => this.DownloadIR()}>
                    <span className="download-icon-container">
                        <span className="download-icon-inside"><i className="download-icon bi bi-download"></i></span>
                    </span>
                    <span style={{ marginLeft: "6px", color: "#fff", fontSize: "15px", fontWeight: "600" }}>Informe de Resultados del Gobierno del Estado</span>
                </div> 
            </div>
      </div>
    );
  }
}

export default InformesDeResultados;
