import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import 'react-table/react-table.css';
import axios from 'axios';
import { host } from '../../../Utils.js';
import './media-size.css';

const Fracciones = () => {
    
    const [fracciones, setFracciones] = useState([]);
    const { articulo } = useParams();
    useEffect(() => {
        console.log("ENTRE", host() + '/api/Fraccions/Articulo/' + routeToId(articulo));
        axios.get(host() + '/api/Fraccions/Articulo/' + routeToId(articulo)).then(res => {
            setFracciones(res.data);
        });
    }, [articulo])

  const routeToId = (text) => {
    var id = "";
    switch(text){
      case '70': id = 1 ; break;
      case '81':id = 2 ; break;
      case '83bis': id = 3 ; break;
      default: break;
    }
    
    return id;
  }
  
    return (
        <div>
            <h1>Transparencia y Acceso a la Información Pública</h1>
            <h1>Artículo {articulo}</h1>
            <div className="contenedor">
                {
                    fracciones.map(fraccion => (
                        <Link key={fraccion.nombre} className="no-underline" to={"/transparencia/obligaciones-de-transparencia/" + articulo + "/" + fraccion.numero}>
                            <div className="square">
                                <div className="v-center">
                                    <div className="big-number"> {fraccion.numero} </div>
                                    <div className="descripcion"> {fraccion.nombre} </div>
                                    <div className="fecha-upd"> Fecha de actualización: {fraccion.fechaActualizacion} </div>
                                </div>
                            </div>
                        </Link>
                    ))}
            </div>
        </div>
    );    
}
export default Fracciones