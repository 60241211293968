import React, { useEffect, useState } from "react";
import { host, useConstructor } from "../../Utils";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

export function EstructuraOrganica(props) {
  useConstructor(()=>{
    props.setTitle("Estructura Orgánica");
  });
    const [numPages, setNumpages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const [innerSize, setInnerSize] = useState(0);

    useEffect(() => {
        let widthSize = window.innerWidth;
        console.log(widthSize);
        setInnerSize(widthSize);
    }, [setInnerSize]);

    function onDocumentLoadSuccess({ numPages }) {
        setNumpages(numPages);
    }
   
    
    let prevPage = () => {
        setPageNumber(pageNumber - 1)
    }

    let nextPage = () => {
        let val = 0;
        if ((pageNumber === 1) || (pageNumber + 1 > numPages))
            val = 1;
        else
            val = 1;
        setPageNumber(pageNumber + val)
    }

    return (
      <div>
        <p className="text-center text-bold title-extracto">
          Establece la manera en que se encuentra organizado el ISAF, las
          unidades administrativas y jerarquías que integran la institución, por
          medio de las cuales se revisan y fiscalizan los estados financieros y
          las cuentas públicas estatal y municipales.
        </p>
        <div className="flex-container">
                <div className="justify-content-center">
                    {innerSize >= 480 ? (
                        <>
                            <Document
                                renderMode="svg"
                                className="p-3 border bg-light center"
                                //file={host() + "/Publico/Documento/2306"} //local
                                file={host() +"/Publico/Documento/5568"}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page pageIndex={1} fixed pageNumber={pageNumber}></Page>
                                
                            </Document>
                            <div className="justify-content-center d-flex btn-pages">
                                <div style={{ marginTop: "10px" }}>
                                    <button style={pageNumber === 1 ? { display: "none" } : {}}
                                        onClick={prevPage}
                                        className="btn-outlined btn-sm btn-page"
                                    >
                                        <i className="bi bi-arrow-left"></i>
                                    </button>
                                    {pageNumber} / {numPages}{" "}
                                    <button style={pageNumber === numPages ? { display: "none" } : {}}
                                        onClick={nextPage}
                                        className="btn-outlined btn-sm btn-page"
                                    >
                                        <i className="bi bi-arrow-right"></i>
                                    </button>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <Document
                                renderMode="svg"
                                    className="p-3 border bg-light justify-content-center"
                                    file={host() + "/Publico/Documento/5568"}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page pageIndex={1} fixed pageNumber={pageNumber}></Page>
                            </Document>
                                <div className="justify-content-center d-flex btn-pages">
                                    <div style={{ marginTop: "10px" }}>
                                        <button style={pageNumber === 1 ? { display: "none" } : {}}
                                            onClick={prevPage}
                                            className="btn-outlined btn-sm btn-page"
                                        >
                                            <i className="bi bi-arrow-left"></i>
                                        </button>
                                        {pageNumber} / {numPages}{" "}
                                        <button style={pageNumber === numPages ? { display: "none" } : {}}
                                            onClick={nextPage}
                                            className="btn-outlined btn-sm btn-page"
                                        >
                                            <i className="bi bi-arrow-right"></i>
                                        </button>
                                    </div>
                                </div>
                        </>
                    )}
                </div>
               
        </div>
      </div>
    );
  
}
