import React, { Component } from "react";
import axios from "axios";
import "./Entes.css";
import Pagination from "react-js-pagination";
import {isMobile} from 'react-device-detect';
import { hostSigas } from "../../Utils";

class Entes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      searchWord: "",
    };
  }
  enteSelected(ente) {
    this.setState({
      entes: this.props.entes.map((x) => {
        let result = x;
        if (x.value === ente.value) x.selected = true;
        else x.selected = false;

        return result;
      }),
    });

    this.getInformes(ente, this.props.ejercicio);
  }
  navigateToInformes() {
    if (isMobile)
      document.getElementById("informes-container").scrollIntoView();
  }
  getInformes(ente, ejercicio) {
    if (ejercicio.value) {
      //"api/Informes/EnteEjercicio/{EnteId}/{EjercicioId}/{TipoInforme}/{Ambito}/{FondoId}"
      axios
        .get(
          hostSigas() +
            `/api/Informes/EnteEjercicio/${ente.value}/${ejercicio.value}/${this.props.tipoInforme}/${this.props.ambito}/0`
        )
          .then((x) => {
            console.log("this", x.data)
          if (x.data.length < 1) {
            this.props.callback({
              informes: x.data.map((x) => {
                x.selected = false;
                return x;
              }),
              message: `No se ha cargado el Informe para el ejercicio ${ejercicio.label}`,
              enteName: ente.label,
            });
          } else {
            this.props.callback({
              informes: x.data.map((x) => {
                x.selected = false;
                return x;
              }),
              message: "",
              enteName: ente.label,
            });
          }

          this.navigateToInformes();
        })
        .catch((x) => console.log(x));
    } else {
      this.props.callback({
        message: "Seleccione un Ejercicio para cargar los Informes",
      });
    }
  }
  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
  }
  search(data, word) {
    let result = data.filter((x) =>
      (x.index + x.label)
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(word.toLowerCase())
    );

    return result;
  }
  filter(data) {
    var dataSearched = this.search(data, this.state.searchWord);

    // this.setState({filtrados: dataSearched.length});

    if (this.props.entes.length <= 8) {
      return dataSearched;
    } else {
      let from = this.state.activePage * 8 - 8;
      let to = this.state.activePage * 8;
      return dataSearched.slice(from, to);
    }
  }
  handleInputCapture(e) {
    var countFiltrados = this.search(this.props.entes, e.target.value).length;
    var countMostrados = countFiltrados >= 8 ? 8 : countFiltrados;
    this.setState({
      activePage: 1,
      searchWord: e.target.value,
      filtrados: countFiltrados,
      mostrados: countMostrados,
    });
  }

  render() {
    let result = (
      <div id="tipoEntes-contaienr">
        <label id="tipoEntes-label">Entidades Fiscalizadas</label>
        <div className={this.props.entes.length <= 8 ? "hide" : ""}>
          <input
            type="text"
            className="form-control busqueda"
            placeholder="Buscar"
            onChange={this.handleInputCapture.bind(this)}
          />
        </div>
        <ul className="list-group">
          {this.filter(this.props.entes).map((ente) => (
            <li
              key={ente.value}
              onClick={this.enteSelected.bind(this, ente)}
              className={
                "list-group-item ente-item text-black" + (ente.selected ? " active text-white" : "")
              }
            >
              {ente.index}.- {ente.label}
            </li>
          ))}
        </ul>
        <div className={this.props.entes.length <= 8 ? "hide" : ""}>
          <div className={this.state.searchWord ? "" : "hidden"}>
            Mostrando {this.state.mostrados} de {this.state.filtrados} Entidades
            de un total de {this.props.entes.length}
          </div>
          <div className={this.state.searchWord ? "hidden" : ""}>
            {this.props.entes.length} Entidades encontradas
          </div>
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={8}
            hideDisabled
            itemClass="page-item"
            linkClass="page-link"
            innerClass="pagination"
            totalItemsCount={this.props.entes.length}
            pageRangeDisplayed={3}
            onChange={this.handlePageChange.bind(this)}
          />
        </div>
      </div>
    );

    if (
      this.props.tipoInforme === "Federal" ||
      this.props.tipoInforme === "General"
    )
      return <div></div>;

    return result;
  }
}

export default Entes;
