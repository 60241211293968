import React from 'react';
import { useConstructor } from '../../Utils';

export function DenunciasOci(props) {
    useConstructor(() => {
        props.setTitle("Denuncia en Contra de Servidores Públicos del ISAF")

    });

    return (
        <div>
            <div className="flex-container">
                <h3>Sistema de Denuncia en Contra de Servidores Públicos del ISAF</h3>
                <p>Con fundamento en lo dispuesto en los <strong>artículos 131, 132 y 133 de la Ley Estatal de Responsabilidades, 95, fracción II, de
                    la Ley de Fiscalización Superior del Estado, artículo 49 fracción IX y XI del Reglamento Interior del Instituto Superior
                    de Auditoría y Fiscalización </strong>se le informa a los entes fiscalizados y ciudadanía que aquí podrán presentar quejas y denuncias
                    en contra de actuaciones indebidas por parte de servidores públicos del Instituto Superior de Auditoria y Fiscalización del
                    Estado de Sonora, sugerencias de mejora y reconocimientos por buen servicio o desempeño, las 24 horas del día, durante los
                    365 días del año:Con fundamento en lo dispuesto en el artículo 95, fracción II, de la Ley de Fiscalización Superior del
                    Estado se le informa a los entes fiscalizados y ciudadanía que aquí podrán presentar quejas y denuncias en contra de actuaciones
                    indebidas por parte de servidores públicos del Instituto Superior de Auditoria y Fiscalización del Estado de Sonora, sugerencias
                    de mejora y reconocimientos por buen servicio o desempeño, las 24 horas del día, durante los 365 días del año:</p>
                <div className='container'>
                    <div className="card cardenuncia" >
                        <div className="card-body">
                            <h5 className="card-title">Formato de denuncia</h5>
                            <p className="card-text">Descarga el formato de denuncia y envíalo a: <strong>buzon.oci@isaf.gob.mx</strong>.</p>
                            <button onClick={() => window.open('https://intranet.isaf.gob.mx/Publico/Documento/5409')} className="card-link">Descargar</button>
                        </div>
                    </div>
                </div>

                <h3>Otras opciones para presentar una denuncia ciudadana</h3>
                <p>Presencial o por escrito: En el Órgano de Control Interno del ISAF, Avenida No Reelección #53, Colonia Centro, Hermosillo, Sonora, México, C.P. 83000, de Lunes a Viernes, de 8:00 a 15:00 horas.</p>
                <p>Horario de Atención Telefónica: (662) 3129383 y (662) 3132164.</p>
                <p>Vía correspondencia: Enviando escrito libre al Órgano de Control Interno del ISAF, con domicilio en Avenida No Reelección #53, Colonia Centro, Hermosillo, Sonora, México, C.P. 83000.</p>
            </div>

        </div>
    );

}
