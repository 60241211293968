import React from 'react';
import { useConstructor } from '../../Utils';
import Documentos from "../../components/Documentos";


export function ConveniosMunicipales  (props) { 
    useConstructor(() => {
      // props.setTitle("Convenios Municipales")
    });
    
    return (
      <div>
        <p className="text-center text-bold title-extracto">
          Convenios de coordinación y colaboración para formalizar el apoyo
          interinstitucional que el ISAF presta a los distintos municipios del
          estado de Sonora.
        </p>
        <div className="flex-container">
          <Documentos number="FA-0219" extra="true"></Documentos>
        </div>
      </div>
    );
  
}
