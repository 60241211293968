import React from 'react';
import {   Navbar  } from 'reactstrap';

import facebook from '../images/iconos/social/facebook.png';
import email from '../images/iconos/social/email.png';
import instagram from '../images/iconos/social/instagram.png';
import x from '../images/iconos/social/x.png';
import youtube from '../images/iconos/social/youtube.png';
import './NavMenu.css';

export function MainHeader(props) {
    
  return (
    <header>         
          <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom" light>
              <div className="main-header-content">
                  
                      <a href="/" className="main-title"> INSTITUTO SUPERIOR DE AUDITORÍA Y FISCALIZACIÓN </a>
                  
                  <div className="social-container">
                      {/*<input className="input-search" placeholder=" 🔍 Buscar..." />*/}
                      <div className="social">
                          <a title='Facebook' href="https://www.facebook.com/ISAF-Sonora-101929202566779"><img alt='facebook' className="social-icon-fb" src={facebook} /></a>
                          <a title='X' href="https://x.com/SonoraIsaf"><img alt='twitter' className="social-icon-tw" src={x} /></a>
                          <a title='Instagram' href="https://www.instagram.com/isafsonora/"><img alt='instagram' className="social-icon-ig" src={instagram} /></a>
                          <a title='Youtube' href="https://www.youtube.com/channel/UChZCV8xjJAT2mHS4hxg7W1w"><img alt='youtube' className="social-icon-yt" src={youtube} /></a>
                          <a title='Email' href="mailto:atencion@isaf.gob.mx"> <img alt='email' className="social-icon-em" src={email} /></a>
                      </div>
                  </div>
        </div>
      </Navbar>
    </header>
  );

};