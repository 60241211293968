import React from "react";
import Documentos from "../../components/Documentos";
import { useConstructor } from "../../Utils";

export function ManualesYGuias(props) {
  useConstructor(()=>{
    // props.setTitle("Manuales y Guías");
  });
  
    return (
      <div>
        <p className="text-center text-bold title-extracto">
          Documentos normativos y de apoyo que explican los procedimientos a
          realizar. Considerando los manuales y guías para unificar la
          presentación de la información financiera de los municipios ante el
          ISAF, así como la evaluación de programas federales. Asimismo,
          estandariza las actividades del trabajo de auditoría y detalla cómo se
          efectúa ésta en el ISAF.
        </p>
        <div className="flex-container">
          <Documentos number="FA-0171" name="Guías" extra="true"></Documentos>
          <Documentos number="FA-0172" name="Lineamientos"></Documentos>
          <Documentos
            number="FA-0173"
            name="Manuales e Instructivos"
          ></Documentos>
        </div>
      </div>
    );
  
}
