import React, { useEffect, useState } from 'react';
import { useConstructor } from '../../Utils';
import { FormGroup } from "reactstrap";
import Documentos from "../../components/Documentos";

export function InformeMensualDeTransparencia  (props) { 
    useConstructor(() => props.setTitle("Informe Mensual De Transparencia"));
    const [anoSelected, setAnoSelected] = useState("");
        
    useEffect(() => {

    }, []);


    const valueSelected = (e) => {
        e.preventDefault()
        setAnoSelected(e.target.value)
    }

   
    return (
      <div>
        <p className="text-center text-bold title-extracto">
          Informes referentes a las solicitudes de acceso a la información
          realizadas al ISAF por cada mes, misma que incluye el tema requerido y
          los días de respuesta.
        </p>
            <div className="flex-container">
                <FormGroup>
                    <label>Seleccione Año de Filtro</label>
                    <select className="form-control" onChange={valueSelected}>
                        <option value="">Todos</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                    </select >
                </FormGroup>
                <Documentos number="FA-0216" anoSelected={anoSelected} ></Documentos>
        </div>
      </div>
    );
  
}
