import React, { useState } from "react";
import { FormGroup } from "reactstrap";
import Select from "react-select";
import {useConstructor} from "../../Utils";
import Documentos from "../../components/Documentos";

export function LeyesDeIngresos(props) {
  // props.setTitle("Leyes de Ingresos");
  const [ejercicios, setEjercicios] = useState([]);
  const [ejercicioSelected, setEjercicioSelected] = useState({
    id: 2023,
    label: 2023,
    folder: "FA-0263",
  });

  useConstructor(() => {
    setEjercicios([
      { id: 2023, folder: "FA-0263" },
      { id: 2022, folder: "FA-0227" },
      // { id: 2021, folder: "FA-0221" },
      // { id: 2020, folder: "FA-0189" },
      // { id: 2019, folder: "FA-0188" },
      // { id: 2018, folder: "FA-0052" },
      // { id: 2017, folder: "FA-0030" },
      // { id: 2016, folder: "FA-0029" },
    ]);
  });

  function ejercicioChange(data) {
    setEjercicioSelected(data);
    console.log("data: ", ejercicioSelected);
  }

  const EjerciciosRender = ejercicios.map((ejercicio) => {
    const render = {
      value: ejercicio.id,
      label: ejercicio.id,
      folder: ejercicio.folder,
    };
    return render;
  });

  
    return (
      <div>
        <p className="text-center text-bold title-extracto">
          Contiene las leyes de ingresos y presupuesto de ingresos de los
          municipios del Estado de Sonora autorizadas por el Congreso del
          Estado, en las que se establecen las contribuciones mediante las
          cuales se podrán captar los recursos financieros que permitan cubrir
          los gastos del municipio durante un ejercicio fiscal.
        </p>
        <div className="flex-container" style={{ justifyContent: "end" }}>
          <FormGroup>
            <label>Ejercicio a consultar:</label>
            <Select
              options={EjerciciosRender}
              defaultValue={ejercicioSelected}
              placeholder="Seleccione..."
              onChange={(e) => ejercicioChange(e)}
              className="w-250"
            />
          </FormGroup>
          <Documentos
            number={ejercicioSelected.folder}
            name={"Ejercicio  " + ejercicioSelected.label}
          ></Documentos>
        </div>
      </div>
    );
  
}
