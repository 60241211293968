import React, { useState, useEffect } from 'react';
//import './App.css';
import { useParams } from 'react-router-dom';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import axios from 'axios';
import { host } from '../../../Utils.js';


const Fraccion = () => {
    const [fraccion, setFraccion] = useState(useParams());
    const [cargando, setCargando] = useState(false);
    const [formatos, setFormatos] = useState([]);
    const [, setFormatoLast] = useState({});
    const [documentos, setDocumentos] = useState([]);
    const [, setFormatoSelected] = useState(0);
    const [periodoSelected, setPeriodoSelected] = useState(0);
    const [documentoSelected, setDocumentoSelected] = useState(0);
    const [registros, setRegistros] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [formatoName, setFormatoName] = useState("");
    const [documentSelectedName, setDocumentSelectedName] = useState("");
    const { articulo,fraccion1 } = useParams();

    useEffect(() => {
        axios.get(host() + '/api/Fraccions/Numero/' + routeToId(articulo) + '/' + fraccion1).then(res => {
            setFraccion(res.data);

            axios.get(host() + '/api/Formatos/Fraccion/' + res.data.id).then(res => {
                res.data.forEach(x => x.active = false);
                setFormatos(res.data);
                setFormatoSelected(res.data[0].id);
                setFormatoName(res.data[0].nombre);
                getFormatos(res.data[0].id, res.data[0].nombre);
            }).catch(x => console.log(x));
        }).catch(x => console.log(x));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    

   const routeToId=(text)=> {
        var id = "";
        switch (text) {
            case '70': id = 1; break;
            case '81': id = 2; break;
            case '83bis': id = 3; break;
            default: break;
        }
        return id;
    }
    const filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        const content = row[id];
        if (typeof content !== 'undefined') {
            // filter by text in the table or if it's a object, filter by key
            if (typeof content === 'object' && content !== null && content.key) {
                return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
            } else {
                return String(content).toLowerCase().includes(filter.value.toLowerCase());
            }
        }
    
        return true;
    };
    const renderTable=()=> {
        const data = registros;
        const columns = headers
            .map(x => createHeader(x))
        return <ReactTable
            data={data}
            filterable
            defaultFilterMethod={filterCaseInsensitive}
            loading={cargando}
            loadingText="Cargando..."
            columns={columns}
            nextText={'Siguiente'}
            previousText={'Anterior'}
            pageText={'Página'}
            ofText={'de'}
            rowsText={'registros'}
        />
    }
    function formatDate(d) {
        if (!d)
            return '';

        var string = d;
        var substring = 'T';

        if (string.includes(substring))
            return string.split(substring)[0];

        return string;
    }
    function createHeader(x) {
        if (x.propertyType === "Hyperlink") {
            return {
                Header: x.propertyName,
                accessor: x.propertyId,
                Cell: row => <a target="_blank" rel="noreferrer" href={row.value}>{row.value}</a>
            };
        }

        if (x.propertyType === "DateTime") {
            return {
                Header: x.propertyName,
                accessor: x.propertyId,
                Cell: row => formatDate(row.value)
            };
        }

        return {
            Header: x.propertyName,
            accessor: x.propertyId
        };

    }
    const onPeriodoChanged = (e) => {
        var param = parseInt(e.currentTarget.value)

       
        setPeriodoSelected(param);
        
        axios.get(host() + '/api/FormatoDocumentos/Documentos/' + param).then(Docum => {
             var doc = Docum.data;
            setFormatoLast(Docum.data);
            setDocumentoSelected(doc.id);
            setDocumentSelectedName(doc.formato + " (" + doc.año + " - " + doc.periodo + ") ");
            setCargando(true);
            
            axios.get(host() + '/api/FormatoDocumentos/Headers/' + Docum.data.id).then(res => {
                setHeaders(res.data)
                
            }).catch(x => console.log(x));
            axios.get(host() + '/api/FormatoDocumentos/Excel/' + Docum.data.id).then(res => {
                setRegistros(res.data);
                setCargando(false);
                
            }).catch(x => console.log(x));
        }).catch(x => console.log(x));
    }

    const download = ()=>{
        let url = host() + `/Publico/Transparencia/${documentoSelected}`
        window.open(url, '_blank');
    }

    const getFormatos = (formatoId, formatoNombre) => {
        setFormatoName(formatoName);
        
        axios.get(host() + '/api/FormatoDocumentos/Formato/' + formatoId).then(res => {
            setDocumentos(res.data);
            
        }).catch(x => console.log(x));
        axios.get(host() + '/api/FormatoDocumentos/FormatoLast/' + formatoId).then(formatoLast => {
            console.log(formatoLast);
            var form = formatoLast.data;
            console.log("form === ",form);
            setFormatoLast(form);
            setPeriodoSelected(form.id);
            setDocumentoSelected(form.id);
            setDocumentSelectedName(form.formato + " (" + form.año + " - " + form.periodo + ")");
            setCargando(true);
            
            axios.get(host() + '/api/FormatoDocumentos/Headers/' + form.id).then(res => {
                setHeaders(res.data);
                console.log(headers);
            }).catch(x => console.log(x));
            axios.get(host() + '/api/FormatoDocumentos/Excel/' + form.id).then(res => {
                setRegistros(res.data);
                setCargando(false);
                
            }).catch(x => console.log(x));

        }).catch(x => console.log(x));


    }

     
        return (
            <div>
                <h1>Transparencia y Acceso a la Información Pública</h1>
                <div className="h-center">
                    <div className='fraccion-titulo'>Art. {fraccion.articulosTransparencia}, Fracción {fraccion.numero}.- {fraccion.nombre}</div>
                    <div className='cardx'>
                        <div><b>Fecha de actualización:</b> {fraccion.fechaActualizacion}</div>
                        {/*<div><b>Fecha de validación:</b>  {fraccion.fechaValidacion}</div>*/}
                        <div><b>Área Responsable:</b>  {fraccion.departamento}</div>
                    </div>
                </div>
                <div className="contenedor">
                    <div>
                        <p className="paragraph">
                            {fraccion.descripcion}
                        </p>
                    </div>
                    <div className="formatos-bar">
                        <p className='capital'>Por favor seleccione un formato:</p>
                        <div className="list-group" role="group">
                            {formatos.map(form => {
                                return (
                                    <button key={form.id}
                                        type="button"
                                        className={form.active ? "list-group-item active" : "list-group-item"}
                                        onClick={getFormatos.bind(this, form.id, form.nombre)}
                                        id={form.id}>{form.inciso ? (form.inciso + ') ' + form.nombre) : form.nombre}</button>
                                );
                            })}
                        </div>
                    </div>                
                    <div id="downloadContainer">
                        <p>
                            <button className="btn btn-success" onClick={download}>
                            <i className="fa fa-download"></i>
                                {documentSelectedName} 
                            <i className="fa fa-file-excel-o"></i>
                            </button>
                        </p>
                    </div>
                    <div className="radios-container">
                        <form className='buttons-bar' action="#">
                            {documentos.map(doc => {
                                return (
                                    <div key={doc.id} className={"my-tabs " + (periodoSelected === doc.id ? "my-tab-on" : "")}>
                                        <label className="pointer">
                                            <input
                                                type="radio"
                                                name={doc.periodo}
                                                value={doc.id}
                                                checked={periodoSelected === doc.id}
                                                onChange={onPeriodoChanged}
                                            //className="form-check-input"
                                            />
                                            {doc.año + " - " + doc.periodo}
                                        </label>
                                    </div>

                                );
                            })}
                        </form>
                    </div>
                    <div className="full-width">
                        {renderTable()}
                    </div>
                        <p>{registros.length} registro{registros.length === 1 ? '' : 's'} en total</p>
                                       
                </div>
            </div>
        )
    

}

export default Fraccion