import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { host, useConstructor } from '../../Utils';
import {Input} from 'reactstrap'
import { Entrada } from './Entrada';

export function EntradasBlog  (props) { 
    
    const [EntradasBlog, setEntradasBlog] = useState([]);    
    const [busquedaVacia, setBusquedaVacia] = useState(false);  

    useConstructor(() =>{ 
        axios.get(host() + "/api/EntradasBlog/Top/100")
        .then(res => {
            if(res.data.length > 0){
                fillEntradasBlog(res.data)
                
            }
        }).catch(x => console.log(x))
        
        
        props.setTitle("Análisis de Coyuntura")
    });
    const fillEntradasBlog = (_EntradasBlog) => {        
        setEntradasBlog(_EntradasBlog);
    }
    useEffect(() => {
       
    }, [])

     
  const search=(x)=> {
    axios.post(
        host() + "/api/EntradasBlog/Search/",
    {
      Texto: x  
    }
    ).then(res => {        
            fillEntradasBlog(res.data)
            setBusquedaVacia(res.data.length===0)            
        
    }).catch(x => console.log(x))
   }
    return (
        <div>
            <div className='flex-container reverse'>
                <Input placeholder='Buscar...' className='w-250' onChange={(e)=>{search(e.target.value)}}></Input>
                </div>
            {EntradasBlog.map(e=>{
               return  <Entrada id={e.id} key = {e.id}/>
            })}

            { busquedaVacia? <h2 className='center'>Sin información para mostrar</h2> :''}

      </div>
    );
  
}
