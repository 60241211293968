import React, { useEffect, useState } from 'react';
import {
    hostSigas, useConstructor, tipoAuditoriaButtonClassBuilder,
    getEjerciciosDummy, getInformesDummy, getTiposAuditoriaDummy, smallScreen
} from '../../Utils';
import { Form, Card, Col, Collapse } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import { CheckboxList } from '../../components/CheckboxList';

export function InformesIndividuales(props) {
    const [ejercicios, setEjercicios] = useState([]);
    const [ejerciciosSelected, setEjerciciosSelected] = useState([]);
    const [tiposAuditoria, setTiposAuditoria] = useState([]);
    const [paramTipoAuditoria, setParamTipoAuditoria] = useState([]);
    const [tiposAuditoriaSelected, setTiposAuditoriaSelected] = useState([]);

    const [enteSelected, setEnteSelected] = useState({});
    const [, setEntes] = useState([]);
    const [informes, setInformes] = useState([]);
    const [timeoutId, setTimeoutId] = useState(0);
    const [collapse, setCollapse] = useState(false);

    useConstructor(() => {


        props.setTitle("Informes Individuales Cuenta Pública 2022");
        setEjercicios(getEjerciciosDummy());
        setTiposAuditoria(getTiposAuditoriaDummy());
        const urlparams = new URLSearchParams(window.location.search);
        const urlTipoAuditoria = urlparams.get('Tipo');
        setParamTipoAuditoria(parseInt(urlTipoAuditoria))
        setTiposAuditoriaSelected([parseInt(urlTipoAuditoria)])

    });

    useEffect(() => {
        updateInformes();
    }, [enteSelected, ejerciciosSelected]);


    function AddOrRemove(id) {

        const holder = ejerciciosSelected;
        const encontrado = holder.find(x => x === id)

        if (encontrado) {
            const index = holder.indexOf(encontrado)
            if (index > -1) {
                holder.splice(index, 1)
                setEjerciciosSelected(holder)
                updateInformes()
            }

        } else {
            holder.push(id)
            setEjerciciosSelected(holder)
            updateInformes()
        }
    }

    function AddOrRemoveTipo(id) {

        const holder = tiposAuditoriaSelected;
        const encontrado = holder.find(x => x === id)

        if (encontrado) {
            const index = holder.indexOf(encontrado)
            if (index > -1) {
                holder.splice(index, 1)
                setTiposAuditoriaSelected(holder)
                updateInformes()
            }

        } else {
            holder.push(id)
            setTiposAuditoriaSelected(holder)
            updateInformes()
        }
    };

    function fetchFile(name, downloadName) {
        let ext = name.split('.').pop();
        var link = document.createElement('a');
        name = name.replace('/', '-');
        downloadName = downloadName.replace('/', '-');
        //link.href = `${hostSigas()}/api/Storage/GetUrlWord/` + name + '/' + downloadName + '.' + ext;
        window.open(`${hostSigas()}/api/Storage/GetUrl/` + name + '/' + downloadName + '.' + ext, '_blank');
        link.click();
    }


    const InformesRender = informes.map(informe => {
        console.log(informe)
        informe.color = "#ebdaa0";
        informe.class = tipoAuditoriaButtonClassBuilder(informe.tipoAuditoria)
        if (informe.tipoAuditoria == null)
            informe.tipoAuditoria = "Informe de Auditoría";

        if (informe.auditoria == null)
            informe.auditoria = "sin código"

        var render = '';

        if ((informe.informeEscaneado && informe.visibleEnSitioWeb === true) || informe.tipoAuditoria === "*********") {
            render =
                <div key={informe.id} className={"btn-auditoria center " + informe.class}
                >
                    <div className="center">
                        <div className="btn-auditoria-tipo">Auditoría {informe.tipoAuditoria}</div>
                        <div className="btn-auditoria-codigo">{informe.auditoria}</div>
                    </div>
                    <div className="flex-container evenly">

                        <button style={{
                            display: informe.ejercicioId < 18 ? "block" : "none",
                            visibility: informe.informeTestado != null ? "visible" : "hidden",
                            textDecoration: "none",
                            color: informe.tipoAuditoria !== "*********" ? "#0366d6" : "gray",
                            border: "none"
                        }}
                            onClick={() => fetchFile(informe.informeTestado, `${informe.ente}-${informe.auditoria}`)}
                        >
                            <i className="x2 bi bi-file-earmark-word"></i>
                        </button>


                        <button style={{
                            textDecoration: "none",
                            visibility: informe.informeEscaneado != null ? "visible" : "hidden",
                            color: informe.tipoAuditoria !== "*********" ? "red" : "gray",
                            border: "none"
                        }}
                            onClick={() => fetchFile(informe.informeEscaneado, `${informe.ente}-${informe.auditoria}`)}
                        >
                            <i className="x2 bi bi-filetype-pdf"></i>
                        </button>

                    </div>
                </div>
        } else {
            render = '';
        }
        return render;

    });

    const loadOptions = (inputValue, callback) => {
        if (!inputValue) {
            setEntes([])
            callback([]);
            return;
        }
        clearTimeout(timeoutId);
        var timeout_id = setTimeout(() => {
            axios.get(hostSigas() + "/api/Entes/website/" + inputValue).then(res => {
                if (res.data.length > 0) {
                    var result = res.data.map(x => {
                        x.value = x.id;
                        x.label = x.nombreCortoCompleto;
                        return x;
                    });
                    setEntes(result)
                    callback(result);
                } else {
                    setEntes([])
                    callback([]);
                }
            }).catch(res => console.log(res));
        }, 1000);
        setTimeoutId(timeout_id)
    };

    const handleSelected = (newValue) => {
        setEnteSelected(newValue);

    }

    function updateInformes() {
        if (enteSelected.id !== undefined)
            setInformes(getInformesDummy());

        axios.post(
            hostSigas() + "/api/Informes/website/",
            {
                EnteId: enteSelected.id,
                Ejercicios: ejerciciosSelected,
                tiposAuditoria: tiposAuditoriaSelected
            }
        ).then(res => {
            setInformes(res.data)
        })

    }


    return (
        <div>
            <div className="flex-container">
                {/* <button className={'btn btn-default filters-button'}
                    onClick={() => setCollapse(!collapse)}
                    style={{ width: '100%'}}
                >
                    {!collapse ? 'ver filtros': 'ocultar filtros'}
                </button>

                <Collapse
                    isOpen={collapse}
                    className={smallScreen() ? '' : 'show'}
                    id='filtros'
                    style={{ maxWidth: 350 }}>
                    <Form className="filter-form">
                       
                        <h5 className="font-serif">Ejercicios</h5>
                        <CheckboxList List={ejercicios} 
                                KeyName={"id"} 
                                DisplayName={"año"}
                                ItemSelected={""}                                
                            defaultCheckedId={""}
                            onCheck={(result) => {
                                AddOrRemove(result.id)
                            }} />
                        <hr />
                        <h5 className="font-serif">Tipo de Auditoría</h5>
                        <CheckboxList List={tiposAuditoria}
                            KeyName={"id"}
                            DisplayName={"nombre"}
                            ItemSelected={''}
                            defaultCheckedId={paramTipoAuditoria}
                            onCheck={(result) => {
                                AddOrRemoveTipo(result.id)
                            }} />
                    </Form>                    
                </Collapse> */}

                <Col id="results" className="filter-content">
                    <Card className={''} body>
                        <AsyncSelect
                            className={'x2'}
                            cacheOptions
                            loadOptions={loadOptions}
                            defaultOptions
                            onChange={handleSelected}
                            placeholder="Buscar Ente..."
                            loadingMessage={({ inputValue }) => !inputValue ? "Escriba para comenzar a buscar" : "Cargando..."}
                            noOptionsMessage={({ inputValue }) => !inputValue ? "Escriba para comenzar a buscar" : "Sin coincidencias"}
                        />
                        <hr />


                        <div className="flex-container evenly" style={{ maxHeight: "80vh", overflowY: "auto" }}>
                            {InformesRender}
                        </div>
                    </Card>
                </Col>

            </div>

        </div>
    );

}
