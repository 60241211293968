import React, { useEffect } from "react";
import { useConstructor } from '../../Utils';
import Documentos from "../../components/Documentos";

export function PoliticasPublicas(props) {
  useConstructor(() => props.setTitle("Evaluación a Políticas Públicas"));

  useEffect(() => {}, []);

  
    return (
      <div>
        <p className="text-center  title-extracto">
               
            </p>
            
        <div className="flex-container">
          <Documentos number="FA-0278"></Documentos>
        </div>
      </div>
    );
  
}
