import React from "react";
import { useConstructor } from '../../Utils';
import Documentos from "../../components/Documentos";

export function NormatividadTransparencia(props) {
  useConstructor(() => props.setTitle("Normatividad en Transparencia"));

  

  
    return (
      <div>
        <p className="text-center text-bold title-extracto">
                Conjunto de disposiciones legales, reglamentarias y administrativas, que son de observancia obligatoria para el ISAF,
                mismas que rigen en materia de, transparencia, acceso a la información pública y protección de datos personales.
        </p>
        <div className="flex-container">
          <Documentos number="FA-0246"></Documentos>
        </div>
      </div>
    );
  
}
