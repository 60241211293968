import React from 'react';
import Documentos from '../../components/Documentos';
import { useConstructor } from '../../Utils';


export function PlaneacionInstitucional(props) {

    useConstructor(() => { props.setTitle("Planeación institucional") });

    return (
        <div>
            <div className="flex-container">
                <Documentos number="FA-0264" ></Documentos>
            </div>
        </div>
    );
}