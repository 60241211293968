import React, { useEffect } from 'react';
import { useConstructor } from '../../Utils';
import Documentos from "../../components/Documentos";

export function LGCCyLeyDeAuseteridad  (props) { 
    useConstructor(() => props.setTitle("Transparencia de la LGCC y de la Ley de Austeridad y Ahorro"));

    useEffect(() => {
        
    }, [])

    return (
      <div>
        <p className="text-center text-bold title-extracto">
          Corresponde a la información que debe estar publicada por disposición
          de la Ley General de Contabilidad Gubernamental y de la Ley de
          Austeridad y Ahorro del Estado de Sonora y sus Municipios.
        </p>
        <div className="flex-container">
          <Documentos number="FA-0184"></Documentos>
        </div>
      </div>
    );
  
}
