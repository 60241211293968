/// <reference path="../service-worker.js" />
import React from "react";
import { Container} from "reactstrap";
import { smallScreen, useConstructor } from "../Utils";

import ImgIntegral from "../images/iconos/tipoAuditorias/Integral.png";

import { SolicitudDeInformacionForm } from "./transparencia/SolicitudDeInformacion-form";
import ImgMision from "../images/iconos/institucion/mision.png";
import ImgVision from "../images/iconos/institucion/vision.png";
import ImgObjetivos from "../images/iconos/institucion/objetivo.png";
import ImgConvocatoria from "../images/convocatoria/logo_4ta_convocatoria.png";
import ImgConvocatoria5 from '../images/convocatoria/5taConvocatoria.png'

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import NotasHeader from './NotasHeader';
import NotasRecientes from "./NotasRecientes";
import AccesosFrecuentes from "./EnfoquesDeAuditoria"
import SitiosDeInteres from "./SitiosDeInteres"
import axios from 'axios';
import { hostSigas } from '../Utils';
import swal from "sweetalert2";

export function Home(props) {

    const cssIntegral = {
        cursor: 'pointer',
        borderRadius: "10px",
        height: smallScreen() ? '400px' : '200px',
        backgroundColor: "lightgray",
        display: "flex",
        alignItems: "center",
        flexDirection: smallScreen() ? "column" : "row"
    }

    useConstructor(() => {
        props.setTitle("home");        

        axios.get(hostSigas() + "/api/Anuncio/Website")
            .then(res => {
                if (res.data !== null) {
                    swal.fire({
                        html: res.data.contenido,
                       width: (window.screen.width >= 500 ? '600px' : '100%'),
                        showConfirmButton: false
                    })  
                }
            }).catch(x => console.log(x))

              
    });



    return (
        <div style={{ fontSize: "1.55rem" }}>            
            <div className="d-flex">
            <NotasHeader/>
            </div>
            <Container>

                {/* <div>
                    <h2 style={{ textAlign: "center", margin: 40, color: "#6a6a6a" }}>
                        <b className="font-title">AUDITORÍA INTEGRAL</b>
                    </h2>
                    <div onClick={() => { window.open('/auditorias/auditoria-integral', '_self') }}
                        style={cssIntegral}>
                        <div style={{ borderRadius: "10px", backgroundColor: "#832347" }} >
                            <img style={{ height: '200px' }} alt='' src={ImgIntegral} /></div>
                        <div style={{ textAlign: "justify", padding: "20px 30px 0px 40px", overflow: "auto", height: '200px', fontSize:'1.2rem' }}>
                            Modalidad que agrupa los distintos tipos de auditoría, al aplicar en su metodología las
                            herramientas y procedimientos de las mismas, comprendiendo una revisión sistemática y analítica
                            que posibilite evaluar todos los aspectos vinculados al proceso de administración de las
                            operaciones y recursos de los entes fiscalizados bajo los enfoques de auditoría Financiera, 
                            Presupuestal, De Desempeño, Técnica a la Obra Pública y Forense.<br/ >
                            <a href='/auditorias/auditoria-integral'>Más información...</a>
                            </div>
                        </div>                   
                </div> */}


                <AccesosFrecuentes />   

                <NotasRecientes />

                <div id="formulario" className="home-form">
                    <span className="home-form-title">
                        SOLICITA INFORMACIÓN
                    </span>
                    <span className="form-inputs">
                        <SolicitudDeInformacionForm />

                    </span>
                </div>

                <SitiosDeInteres />                
                
             {/* <a href="/institucion/participacion-ciudadana">   <img style={convo} src={ImgConvocatoria5} alt="Logo 4ta convocatoria" width="200px" /></a> */}
                
                    
                
            </Container>
        </div>
    );
}

let convo ={
    position:"fixed",
    bottom:0,
    left:0,
    zIndex:2
}