import React, { useEffect } from 'react';
import { useConstructor } from '../../Utils';
import HomeTransparencia from './ObligacionesTransparencia/HomeTransparencia';


export function OblicacionesDeTransparencia  (props) { 
    useConstructor(() => props.setTitle("Obligaciones de Transparencia"));

    

    useEffect(() => {
        
    }, [])

    

   
    return (
        <div>
            <div className="flex-container">
                <HomeTransparencia></HomeTransparencia>
            </div>

      </div>
    );
  
}
