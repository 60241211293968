import React from 'react';
import { Container} from 'reactstrap';
import Logo from '../images/LogoBlanco.png';

export function Footer(props) {
  return (
      <div id='footer' className='d-print-none'>
          <div style={{ borderTop: "solid 20px #c0ab50", backgroundColor: "#57152d", padding: "1px 0" }}>
              <Container>
          <div className='footer-content'>
            <div>
              <img src={Logo} alt="Logo" />
                      </div>

                     

            <div className="align-right text-white">
                    <p className="font-title x2">CONTACTO</p>
                    <p>
                        <i className="bi bi-geo-alt-fill"></i> Blvd. Paseo Rio Sonora Sur 189 <br />
                        Col. Proyecto Rio Sonora <br />
                        Hermosillo, Sonora, México, C.P. 83270
                    </p>
                          <p> <i className="bi bi-telephone-fill"></i> (662) 2366504 al 08</p>
                          <p> <i className="bi bi-envelope-fill"></i> atencion@isaf.gob.mx</p>
                          <p> <i className="bi bi-clock"></i> Lunes a Viernes de 8:00 a.m. a 3:00 p.m.</p>
                </div>
          </div>
        </Container>
      </div>
      
    </div>
  );
}
