import React, { useEffect, useState } from 'react';
import { useConstructor, host } from '../../Utils';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser'
import IFrame from 'react-iframe'

export function Mapa (props) { 


    const [entradaId, setEntradaId] = useState(0);
    const [entrada, setEntrada] = useState('');
    const [imagenDispaly, setImagenDisplay] = useState('');

    useConstructor(() => {         
        setEntradaId(props.id)
        

    });
   

      const Navegar =()=>{
        window.open('/institucion/analisis-de-coyuntura/share?entrada='+entradaId+'#')
      }
    useEffect(() => {
        if (entradaId !== 0) {

            axios.get(host() + "/api/EntradasBlog/EntradaBlog/" + entradaId)
                .then(res => {
                    setEntrada(res.data)
                    if (res.data.imagen) {
                        axios.get(host() +'/api/File/Download/' + res.data.imagen)
                            .then((x) => {
                                setImagenDisplay(x.data.blockBlob.uri + x.data.sharedPolicy)
                            }).catch(x=> console.log(x))
                    }
                }).catch(x => console.log(x))              
        } else {
           
        }        
    }, [entradaId])
   
    return (
        <div>
            <div style={{ display: entradaId? 'flex':"none", justifyContent:"center" }}>                
                <div onClick={Navegar}>                   
                    {imagenDispaly ?
                        <div className="center flex-column">
                            <img style={{maxWidth:'400px', cursor:'pointer'}} src={imagenDispaly} alt="img" />                         
                        </div> : ''}                    
                </div>
                
            </div>
        </div>
    );

}
