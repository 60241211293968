import React, { useEffect, useState } from "react";
import { useConstructor } from '../../Utils';
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import { host } from "../../Utils";

export function CodigoDeEtica(props) {
  useConstructor(() => props.setTitle("Código de Ética"));

  const [numPages, setNumpages] = useState(null);
  const [pageNumber, setPagesNumber] = useState(1);
  const [innerSize, setInnerSize] = useState(0);

  useEffect(() => {
    let widthSize = window.innerWidth;
    console.log(widthSize);
    setInnerSize(widthSize);
  }, [setInnerSize]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumpages(numPages);
  }

  return (
    <div>
      <p className="text-center text-bold title-extracto">
        Documento emitido por el Órgano Interno de Control (OCI) del ISAF en el
        que se constituyen los principios, directrices y valores que rigen el
        servicio público, así como las reglas de integridad, que deben cumplir
        los servidores públicos del Instituto.
      </p>
      <div className="flex-container justify-content-center">
        <h2>Documentación de Código de Ética</h2>
        <div>
          <div className="justify-content-center" style={{ margin: "30px" }}>
            {innerSize >= 480 ? (
              <>
                <Document
                  renderMode="svg"
                  className="p-3 border bg-light"
                  file={host() + "/Publico/Documento/5378"}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page fixed pageNumber={pageNumber}></Page>
                  <Page fixed pageNumber={pageNumber + 1}></Page>
                </Document>
                <div className="justify-content-center d-flex btn-pages">
                  <div style={{ marginTop: "10px" }}>
                    <button
                      disabled={pageNumber <= 1}
                      onClick={() => setPagesNumber(pageNumber - 2)}
                      className="btn-outlined btn-sm btn-page"
                    >
                      <i class="bi bi-arrow-left"></i>
                    </button>
                    {pageNumber} / {numPages}{" "}
                    <button
                      onClick={() => setPagesNumber(pageNumber + 2)}
                      className="btn-outlined btn-sm btn-page"
                    >
                      <i class="bi bi-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <Document
                  renderMode="svg"
                  className="p-3 border bg-light"
                  file={host() + "/Publico/Documento/5378"}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page fixed pageNumber={pageNumber}></Page>
                </Document>
                <div className="justify-content-center d-flex btn-pages">
                  <div style={{ marginTop: "10px" }}>
                    <button
                      disabled={pageNumber <= 1}
                      onClick={() => setPagesNumber(pageNumber - 1)}
                      className="btn-outlined btn-sm btn-page"
                    >
                      <i class="bi bi-arrow-left"></i>
                    </button>
                    {pageNumber} / {numPages}{" "}
                    <button
                      onClick={() => setPagesNumber(pageNumber + 1)}
                      className="btn-outlined btn-sm btn-page"
                    >
                      <i class="bi bi-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
