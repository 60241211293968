import React from 'react'

export const Loading = ({ isVisible }) => {
    if (!isVisible) return null;
  return (
    <div class="loading-overlay" id="loading-overlay">
    <div class="loader"></div>
    <h1 style={{color:'black', marginLeft:'10px'}}> Cargando ...</h1>
</div>
  )
}
