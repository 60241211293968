import React from 'react';
import { useConstructor } from '../../Utils';
//import PortadaIntegral from '../../images/transparencia/PortadaAvisoPrivacidadIntegral.png';
import PortadaIntegral from '../../images/transparencia/avisointegral.jpg';


export function AvisoPrivacidad(props) {
    useConstructor(() => {
        props.setTitle("Aviso de Privacidad")

    });

    return (
        <div><br/>
            <div className="center">
                <a href={'https://intranet.isaf.gob.mx/Publico/Documento/5616'}>
                    <img src={PortadaIntegral} width={500} title={'Descargar Documento'}/>
                </a>
            </div><br/>
            

        </div>
    );

}
