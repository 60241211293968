import React from 'react';
import './App.css';
import { Link } from 'react-router-dom';
import Plataforma from "../../../images/logoheader.png";

class Home extends React.Component {

  render() {
    return (<div>
      <div className='d-flex' style={{justifyContent:'center'}}>
      <h1>Acceso a la Información Pública</h1>
      </div>
      <div className="header-container">
        <p className="paragraph-home">
          <span className="capital">D</span>erivado de las reformas a la Constitución Política
          del Estado de Sonora, por medio de la ley número 102, publicada en el Boletín Oficial
          del Estado por medio de edición especial el día viernes 13 de enero de 2017,
          misma que reforma, deroga y adiciona diversas disposiciones de la Constitución Política
          del Estado de Sonora, por medio de la cual dota al Instituto Superior de Auditoría y
          Fiscalización de autonomía constitucional; es por tal situación que la información
          inherente en materia de transparencia previo a dicha fecha podrá ser consultada ante
          el Congreso del Estado de Sonora.
        </p>

      </div>
      <div className="contenedor">
        <div>
          <span className="title">Ley General de Transparencia</span>
          <Link className="no-underline" to="/transparencia/ObligacionesTransparencia/Fracciones/70">
            <div className="rectangle rect-70">
              <div className="centerx">
                <div className="square-name"> Artículo 70</div>
              </div>
            </div>
          </Link>
        </div>
        <div>
          <span className="title">Ley Estatal de Transparencia</span>
          <div className="double">
            <Link className="no-underline" to="/transparencia/ObligacionesTransparencia/Fracciones/81">
              <div className="rectangle">
                <div className="centerx">
                  <div className="square-name">Artículo 81</div>
                </div>
              </div>
            </Link>
            <Link className="no-underline" to="/transparencia/ObligacionesTransparencia/Fracciones/83bis">
              <div className="rectangle">
                <div className="centerx">
                  <div className="square-name">Artículo 83bis</div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <span className="pd-10" >
          <a target="_blank" rel="noopener noreferrer"
            href="http://consultapublicamx.inai.org.mx:8080/vut-web/?idSujetoObigadoParametro=4951&idEntidadParametro=26&idSectorParametro=24">
            <img className="image-pnt" alt="PNT" title="PNT" src={Plataforma} width="225px"></img>
          </a>
          <div className="bottom">
            <i></i>
          </div>
        </span>
        <a href="https://intranet.isaf.gob.mx/Publico/Documento/3504" target="_blank" rel="noopener noreferrer" className="btn btn-info">Consultar tabla de aplicabilidad</a>
      </div>
      <div className='d-flex mb-3' style={{justifyContent:'center'}}>
      <h1>
        Otras Obligaciones
      </h1>
      </div>
      <div className='d-flex' style={{justifyContent:'center',flexWrap:'wrap'}}>
      <div>
          <Link className="no-underline" to="/transparencia/lgcc-y-ley-de-austeridad">
            <div className="rectangleOtras rect-70">
              <div className="centerx">
                <div className="square-nameOtras">Transparencia de la LGCC y de la Ley de Austeridad y Ahorro</div>
              </div>
            </div>
          </Link>
        </div>
      <div>
          <Link className="no-underline" to="/transparencia/informe-mensual-de-transparencia">
            <div className="rectangleOtras rect-70">
              <div className="centerx">
                <div className="square-nameOtras">Informe Mensual de Transparencia</div>
              </div>
            </div>
          </Link>
        </div>
            <div>
                <Link className="no-underline" to="/transparencia/sevac">
                    <div className="rectangleOtras rect-70">
                        <div className="centerx">
                            <div className="square-nameOtras">SEVAC</div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>

    </div>)
  }
}
export default Home