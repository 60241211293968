import React, { useEffect } from "react";
import { useConstructor } from '../../Utils';
import Documentos from "../../components/Documentos";

export function InformesTematicos(props) {
  useConstructor(() => props.setTitle("Informes Temáticos"));

  useEffect(() => {}, []);

  
    return (
      <div>
        <p className="text-center  title-extracto">
                Esta sección contiene una serie de informes que permiten ver el actuar del gobierno en temas prioritarios. El propósito es presentar de forma resumida, clara, comparativa y con lenguaje ciudadano, los resultados de revisiones, estudios y evaluaciones practicadas por el ISAF a través del Centro de Evaluación de Programas y Políticas Públicas.      
            </p>
            
        <div className="flex-container">
          <Documentos number="FA-0277"></Documentos>
        </div>
      </div>
    );
  
}
