import React, { useState, useEffect } from 'react'
import { NormatividadInterna } from './NormatividadInterna'
import { Nav,NavItem,NavLink,TabContent,TabPane,Row,Col,Card,CardTitle,CardText,Button } from 'reactstrap'
import { CompendioLegislativo } from './CompendioLegislativo';
export const MarcoNormativo = () => {
    
    const [activeTab, setActiveTab] = useState('1');
    const [isChecked, setIsChecked] = useState(false);
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
          setActiveTab(tab);
          setIsChecked(false);
        }
      };
      
  return (
    <div>
  <Nav tabs>
    <NavItem>
      <NavLink
        className={activeTab === '1' ? 'active selected' : ''} onClick={() => toggleTab('1')}
      >
        Normatividad Institucional
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={activeTab === '2' ? 'active selected' : ''} onClick={() => toggleTab('2')}
      >
        Compendio legislativo
      </NavLink>
    </NavItem>
  </Nav>
  <TabContent  activeTab={activeTab}>
    <TabPane tabId="1">
    <div className='mt-3'>
    <NormatividadInterna setTitle={'Normativa Institucional'}/>
    </div>
    </TabPane>
    <TabPane tabId="2">
     <div className='mt-3'>
    <CompendioLegislativo/>
     </div>
    </TabPane>
  </TabContent>
</div>
  )
}
