import React, { useEffect, useState } from "react";
import { host, useConstructor } from "../../Utils";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

export function InformeSemestral(props) {
  useConstructor(() => props.setTitle("Primer Informe Semestral 2022"));

  const [numPages, setNumpages] = useState(null);
  const [pageNumber, setPagesNumber] = useState(2);

  const [innerSize, setInnerSize] = useState(0);

  useEffect(() => {
    let widthSize = window.innerWidth;
    console.log(widthSize);
    setInnerSize(widthSize);
  }, [setInnerSize]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumpages(numPages);
  }

  
    return (
      <div>
        <div className="flex-container">
          <div className="justify-content-center">
            {innerSize >= 480 ? (
              <>
                <Document
                  renderMode="svg"
                  className="p-3 border bg-light"
                                file={host() + "/Publico/Documento/5486"}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page pageIndex={1} fixed pageNumber={pageNumber}></Page>
                  <Page fixed pageNumber={pageNumber + 1}></Page>
                </Document>
                <div className="justify-content-center d-flex btn-pages">
                  <div style={{ marginTop: "10px" }}>
                    <button
                      onClick={() => setPagesNumber(pageNumber - 2)}
                      className="btn-outlined btn-sm btn-page"
                    >
                      <i className="bi bi-arrow-left"></i>
                    </button>
                    {pageNumber} / {numPages}{" "}
                    <button
                      onClick={() => setPagesNumber(pageNumber + 2)}
                      className="btn-outlined btn-sm btn-page"
                    >
                      <i className="bi bi-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <Document
                  renderMode="svg"
                  className="p-3 border bg-light"
                  file={host() + "/Publico/Documento/5486"}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page pageIndex={1} fixed pageNumber={pageNumber}></Page>
                </Document>
                <div className="justify-content-center d-flex btn-pages">
                  <div style={{ marginTop: "10px" }}>
                    <button
                      onClick={() => setPagesNumber(pageNumber - 1)}
                      className="btn-outlined btn-sm btn-page"
                    >
                      <i className="bi bi-arrow-left"></i>
                    </button>
                    {pageNumber} / {numPages}{" "}
                    <button
                      onClick={() => setPagesNumber(pageNumber + 1)}
                      className="btn-outlined btn-sm btn-page"
                    >
                      <i className="bi bi-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  
}
