import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { host, useConstructor } from '../../Utils';
import {Input} from 'reactstrap'
import { Mapa } from './Mapa';

export function MapasInteractivos  (props) { 
    
    const [EntradasBlog, setEntradasBlog] = useState([]);    
    const [busquedaVacia, setBusquedaVacia] = useState(false);  

    useConstructor(() =>{ 
        axios.get(host() + "/api/EntradasBlog/MapaInteractivo/100")
        .then(res => {
            if(res.data.length > 0){
                fillEntradasBlog(res.data)
                
            }
        }).catch(x => console.log(x))
        
        
        props.setTitle("Tableros Interactivos")
    });
    const fillEntradasBlog = (_EntradasBlog) => {        
        setEntradasBlog(_EntradasBlog);
    }
    useEffect(() => {
       
    }, [])

     
  const search=(x)=> {
    axios.post(
        host() + "/api/EntradasBlog/Search/",
    {
      Texto: x  
    }
    ).then(res => {        
            fillEntradasBlog(res.data)
            setBusquedaVacia(res.data.length===0)            
        
    }).catch(x => console.log(x))
   }
    return (
        <div>
            
            {EntradasBlog.map(e=>{
               return  <Mapa id={e.id} key = {e.id}/>
            })}

      </div>
    );
  
}
