import React from 'react';
import { useConstructor } from '../../Utils';
import Documentos from '../../components/Documentos'


export function InformesAnterioresEstatales(props) {
    useConstructor(() => {
        props.setTitle("Informes Estatales anteriores")

    });

    return (
        <div>
            <div className="container">
                <h3>Informes de Resultados Estatales anteriores al 2017</h3>
                <div className="container">
                    <Documentos number="FA-0019" detail="true"></Documentos>
                </div>
            </div>
        </div>
    );

}
