import React, { Component } from "react";
import axios from "axios";
import { hostSigas, smallScreen, host } from "../../Utils";
import TipoInformes from "../../components/informes/TipoInformes";
import Ejercicios from "../../components/informes/Ejercicios";
import Ambitos from "../../components/informes/Ambitos";
import TipoEntes from "../../components/informes/TipoEnte";
import Entes from "../../components/informes/Entes";
import FondosFederales from "../../components/informes/FondosFederales";
import Informes from "../../components/informes/Informes";
import { Link } from "react-router-dom";
import DownloadBtn from "../../images/buttons/recorte.png";
import InformesCiudadanos from "../../images/cuenta-publica/InformesCiudadanos.png";
import CuentasAnteriores from "../../images/cuenta-publica/CuentasAnteriores.png";
import InformesIndividuales from "../../images/cuenta-publica/InformesIndividuales.png";
import InformesResultados from "../../images/cuenta-publica/InformesResultados.png";
import CuentaBg from "../../images/cuenta-publica/Cuenta-bg.png";

class CuentaPublica extends Component {
    constructor(props) {
        props.setTitle("   ");
        super();

        var state = {
            hideTipoInforme: false,
            ejercicio: {},
            tipoEntes: [],
            entes: [],
            fondosFederales: [],
            enteName: "",
            informes: [],
            message: "",
            selectedTipoEnte: "",
            tipoInformesLabel: "",
            tipoInformesId: "",
        };

        if (props.tipoInforme !== "Cuenta") {
            axios
                .get(hostSigas() + `/api/TipoInformesNombre/${props.tipoInforme}`)
                .then((x) => {
                    //console.log(x.data);
                    this.setState({
                        hideTipoInforme: true,
                        tipoInformesLabel: x.data.nombre,
                        tipoInformesId: x.data.id,
                    });
                })
                .catch((x) => console.log(x));
        }

        this.state = state;
    }
    execute(data) {
        this.setState(data);
    }


    unselect() {
        this.setState({
            entes: this.state.entes.map((x) => {
                x.selected = false;
                return x;
            }),
        });
    }
    Download(ambito) {
        if (ambito == "estatal") {
            if (host() == "https://localhost:44365")
                return window.open(host() + '/Publico/Documento/6090')
            else
                return window.open(host() + "/Publico/Documento/6093")
        } else {
            if (host() == "https://localhost:44365")
                return window.open(host() + '/Publico/Documento/6091')
            else
                return window.open(host() + "/Publico/Documento/6094");
        }


    }
    DownloadPropuesta(ambito) {
        if (ambito == "estatal") {
            if (host() == "https://localhost:44365")
                return window.open(host() + '/Publico/Documento/6090')
            else
                return window.open(host() + "/Publico/Documento/6122")
        } else {
            if (host() == "https://localhost:44365")
                return window.open(host() + '/Publico/Documento/6091')
            else
                return window.open(host() + "/Publico/Documento/6121");
        }
    }
    DownloadIR() {

        if (host() == "https://localhost:44365")
            return window.open(host() + '/Publico/Documento/6090')
        else
            return window.open(host() + "/Publico/Documento/6123")

    }
    DownloadActualizacion() {

        if (host() == "https://localhost:44365")
            return window.open(host() + '/Publico/Documento/6090')
        else
            return window.open(host() + "/Publico/Documento/6140")

    }

    render() {
        return (
            <div className="container-fluid cuenta-publica-main">
                <div className="flex-container" style={{ justifyContent: 'center' }}>
                    <div style={{ marginBottom: "30px", textAlign: "center", backgroundColor: '#832347', borderRadius: '25px', padding: '5px 25px 5px 20px', fontSize: smallScreen() ? '24px' : '42px', fontWeight: 600, color: '#fff' }}>CUENTA PÚBLICA 2022</div>
                    <span>Con base en el artículo 22 de la Ley de Fiscalización Superior para el Estado de Sonora, los Informes de Resultados tendrán el carácter de información pública, una vez que sean sancionados por el H. Congreso del Estado de Sonora, a más tardar el 15 de octubre de 2023.</span>
                    <br />
                </div>
                <div className="flex-container cuenta-buttons" style={{ justifyContent: 'space-evenly' }}>
                    <a href="/auditorias/informes-individuales"><img src={InformesIndividuales} style={smallScreen() ? { width: "150px" } : {}} /></a>
                    <a href="/auditorias/informes-de-resultados"><img src={InformesResultados} style={smallScreen() ? { width: "150px" } : {}} /></a>

                    <a href="/auditorias/informes-ciudadanos"><img src={InformesCiudadanos} style={smallScreen() ? { width: "150px" } : {}} /></a>
                    <a href="/auditorias/cuentas-publicas-anteriores"><img src={CuentasAnteriores} style={smallScreen() ? { width: "150px" } : {}} /></a>
                </div>
                <div className="flex-container flex-center">
                    <div style={{ width: "590px", display: "flex", justifyContent: "space-evenly", flexWrap: "wrap" }}>
                        <div className="flex-container download-button" onClick={() => this.DownloadIR()}>
                            <span className="download-icon-container">
                                <span className="download-icon-inside"><i className="download-icon bi bi-download"></i></span>
                            </span>
                            <span style={{ marginLeft: "6px", color: "#fff", fontSize: "15px", fontWeight: "600" }}>Informe de Resultados del Gobierno del Estado</span>
                        </div>
                        <div className="flex-container download-button" onClick={() => this.Download('estatal')}>
                            <span >
                                <img src={DownloadBtn} width={50}></img>
                            </span>
                            <span style={{ marginLeft: "6px", color: "#fff", fontSize: "15px", fontWeight: "600" }}>Informe Ciudadano del Gobierno del Estado</span>
                        </div>
                        <div className="flex-container download-button" onClick={() => this.DownloadPropuesta('estatal')}>
                            <span className="download-icon-container">
                                <span className="download-icon-inside"><i className="download-icon bi bi-download"></i></span>
                            </span>
                            <span style={{ marginLeft: "6px", color: "#fff", fontSize: "14px", fontWeight: "600" }}>Propuesta de calificación del Gobierno del Estado</span>
                        </div>
                        <div className="flex-container download-button" onClick={() => this.Download('municipal')}>
                            <span >
                                <img src={DownloadBtn} width={50}></img>
                            </span>
                            <span style={{ marginLeft: "6px", color: "#fff", fontSize: "14px", fontWeight: "600" }}>Informe Ciudadano de Municipios</span>
                        </div>
                        <div className="flex-container download-button" onClick={() => this.DownloadPropuesta('municipal')}>
                            <span >
                                <img src={DownloadBtn} width={50}></img>
                            </span>
                            <span style={{ marginLeft: "6px", color: "#fff", fontSize: "14px", fontWeight: "600" }}>Propuesta de calificación de Municipios</span>
                        </div>
                        <div className="flex-container download-button" onClick={() => this.DownloadActualizacion('municipal')}>
                            <span >
                                <img src={DownloadBtn} width={50}></img>
                            </span>
                            <span style={{ marginLeft: "6px", color: "#fff", fontSize: "14px", fontWeight: "600" }}>Actualización de la Propuesta de calificación de Municipios</span>
                        </div>

                    </div>
                </div>



            </div>
        );
    }
}

export default CuentaPublica;
