import React, { useEffect } from "react";
import { useConstructor } from '../../Utils';

import {
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from "reactstrap";

export function PreguntasFrecuentes(props) {
  // useConstructor(() => props.setTitle("Preguntas Frecuentes"));

  useEffect(() => {}, []);

  
    return (
      <div>
        <div className="flex-container">
          <ListGroup>
            <ListGroupItem>
              <ListGroupItemHeading>1. ¿Qué es el ISAF? </ListGroupItemHeading>
              <ListGroupItemText>
                El ISAF es el órgano autónomo encargado de revisar y fiscalizar
                los estados financieros, cuentas públicas estatal y municipales,
                de fiscalizar los ingresos y egresos, así como el manejo,
                custodia y aplicación de los recursos públicos, de igual manera
                la deuda pública contraída con los particulares de los poderes
                del Estado de Sonora, es decir, vigila el origen y aplicación de
                los recursos públicos en el Estado.
              </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>
                {" "}
                2. ¿Qué audita el ISAF?
              </ListGroupItemHeading>
              <ListGroupItemText>
                El Instituto Superior de Auditoría y Fiscalización se constituye
                como un organismo público autónomo, encargado de revisar y
                fiscalizar los estados financieros y cuentas públicas estatal y
                municipales, de fiscalizar los ingresos y egresos, así como el
                manejo, custodia y aplicación de los recursos públicos, así como
                deuda pública contraída con los particulares de los entes
                fiscalizados.
              </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>
                3. ¿Cuál es el periodo de auditorías del ISAF y cuándo presenta
                sus resultados?{" "}
              </ListGroupItemHeading>
              <ListGroupItemText>
                El ejercicio fiscal anterior inmediato y de acuerdo con la Ley
                de Fiscalización Superior para el Estado de Sonora, el Informe
                de Resultados de la Revisión de la Cuenta Pública emitido por el
                Instituto deberá ser entregado a más tardar el día 30 de agosto
                y hará prueba plena en todos los procedimientos de
                responsabilidades que se deriven de las observaciones detectadas
                en los procesos de auditoría y fiscalización.
              </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>
                4. ¿Qué es la cuenta pública?{" "}
              </ListGroupItemHeading>
              <ListGroupItemText>
                Las Cuentas Públicas del Gobierno del Estado y gobiernos
                municipales a que se refieren los artículos 79, fracción VII y
                136, fracción XXIV de la Constitución Política del Estado de
                Sonora y cuyo contenido se establecen en los artículos 47 y 48
                de la Ley General de Contabilidad Gubernamental y los Acuerdos y
                lineamientos emitidos por el Consejo Nacional de Armonización
                Contable (CONAC).
              </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>
                5. ¿Qué es el informe de resultados?{" "}
              </ListGroupItemHeading>
              <ListGroupItemText>
                Es Un informe elaborado como resultado de la fiscalización
                superior de la revisión de las cuentas públicas estatal y
                municipales, según corresponda y que está integrado por todos
                aquellos informes individuales elaborados de las auditorías
                practicadas a los sujetos fiscalizados durante un ejercicio
                fiscal.
              </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>
                {" "}
                6. ¿Qué son los informes individuales?
              </ListGroupItemHeading>
              <ListGroupItemText>
                Son informes de cada una de las auditorías practicadas a los
                entes fiscalizados.
              </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>
                {" "}
                7. ¿Cuántos entes fiscaliza el ISAF?
              </ListGroupItemHeading>
              <ListGroupItemText>
                Para la revisión de la Cuenta Pública del Gobierno del Estado de
                Sonora del ejercicio 2020 y de la información trimestral se
                considera la fiscalización a 87 entes públicos, además de la
                auditoría de 249 asociaciones civiles y 12 sindicatos. Para la
                revisión, auditoría y fiscalización de las cuentas públicas
                municipales del ejercicio 2020 y de la información trimestral se
                considera la fiscalización a 72 municipios, 77 paramunicipales y
                24 sindicatos.
              </ListGroupItemText>
            </ListGroupItem>
          </ListGroup>
        </div>
      </div>
    );
  
}
