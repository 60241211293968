import React, { useEffect } from 'react';
import { useConstructor } from '../../Utils';
import Building from "../../images/transparencia/ut-building.svg"
import { isMobile } from 'react-device-detect';

export function UnidadDeTransparencia  (props) { 
    // useConstructor(() => props.setTitle("Unidad De Transparencia"));


    useEffect(() => {
        
    }, [])

   
    return (
      <div>
        {/* <p className="text-center text-bold title-extracto">
          Información de contacto con la Unidad de Transparencia del ISAF y su
          responsable.
        </p> */}
        <div className="flex-container">
          {" "}
          <p>
            <b className="x2">Titular de la Unidad de Transparencia</b>
            <br />
            Mtro. José Enrique Haro Valdés.
          </p>
        </div>
        <div
          className="flex-container"
          style={{
            justifyContent: "space-between",
            alignItems: "flex-end",
            backgroundImage: !isMobile? "url(" + Building + ")" : '',
            backgroundPosition: "right",
            backgroundRepeat: "no-repeat",
            backgroundSize: "400px",
          }}
        >
          <span>
            <p>
              <b className="x2">Dirección</b>
              <br />
              Boulevard Paseo Río Sonora Sur #189 entre California y Río
              Cocóspera
              <br />
              Colonia Proyecto Río Sonora Hermosillo, Sonora, México C.P. 83280.
            </p>
            <br />
            <p>
              <b className="x2">Teléfonos</b>
              <br />
              (662) 236 65 04 <i>al</i> 236 65 08
            </p>
            <br />
            <p>
              <b className="x2">
                Correo Electrónico
                <br />
              </b>{" "}
              transparencia@isaf.gob.mx
            </p>

            <a href="https://intranet.isaf.gob.mx/Publico/Documento/2978">
              {" "}
              Descarga esta información en formato excel de acuerdo a la ley
              estatal
            </a>
          </span>
        </div>
      </div>
    );  
}
