import React, { Component } from "react";
import axios from "axios";
import "./TipoEnte.css";
import Select from "react-select";
import { hostSigas } from "../../Utils";

class TipoEntes extends Component {
  tipoEnteSelected(selected) {
    axios
      .get(
        hostSigas() +
          `/api/Entes/TipoEnte/${selected.value}/${this.props.ejercicio.value}/${this.props.tipoInforme}`
      )
      .then((x) => {
        let ct = 0;
        this.props.callback({
          entes: x.data.map((e) => {
            return {
              index: (ct += 1),
              value: e.id,
              label: e.nombreCompleto,
              selected: false,
            };
          }),
          selectedTipoEnte: selected,
          informes: [],
          message: "",
        });
      })
      .catch((x) => console.log(x));
    //console.log(this.props.ejercicio.value);
  }

  render() {
    let result = (
      <div id="tipoEntes-contaienr">
        <label id="tipoEntes-label">Tipos de Ente</label>
        <Select
          placeholder="Seleccione Tipo de Ente"
          value={this.props.selectedTipoEnte}
          options={this.props.tipoEntes}
          onChange={this.tipoEnteSelected.bind(this)}
        />
        <br />
      </div>
    );

    if (
      this.props.tipoInforme === "Federal" ||
      this.props.tipoInforme === "General"
    )
      return <div></div>;

    return result;
  }
}

export default TipoEntes;
